import  React from "react";
import { NavLink, useNavigate } from "react-router-dom";

export type ResourcesType = {
  className?: string;
};

const Resources: React.FC<ResourcesType> = ({ className = "" }) => {
  const navigate = useNavigate()

  const handleCategoryClick = () => {
    navigate('/feedback');
  };
  return (
    <div
      className={`self-stretch flex flex-col items-start justify-start gap-6 w-full text-left text-5xl sm:text-base text-dimgray-300 font-kumbh-sans  sm:gap-2 ${className}`}
    >
      {/* <div className="flex flex-row items-start justify-start py-0 px-2 box-border w-full">
        <h3 className="m-0 flex-1 relative leading-[56px]  font-inherit w-full  ">
          <p className="m-0">
            <span className="text-[#6E6E73]">We have streamlined your information and  resource <br/>needs into</span>
            <span className="text-black"> two key categories</span>
            <span className="text-dimgray-300">{` `}</span>
          </p>
        </h3>
      </div> */}
      <div className="self-stretch flex flex-col items-center justify-start gap-[69px] w-full text-38xl text-white sm:gap-[34px] ">
        
        <div className="flex flex-col gap-2">
        <p className="m-0 relative text-5xl sm:text-base  leading-[36px] font-semibold font-inherit z-[1]  sm:leading-[22px] w-full">AI chatbot</p>
        <div className="self-stretch h-96 md:h-auto flex flex-row md:flex-col items-start justify-start  w-full">
          
          <div className="w-[47%] h-full md:w-full rounded-11xl bg-darkslategray-1200 flex flex-col items-center justify-center   box-border ">
           <div className="bg-[#323232] w-full h-80  text-white-A700 flex flex-col px-20 md:px-10 item-center justify-center rounded-l-3xl">
           <p className="m-0 relative text-5xl sm:text-base  leading-[36px] font-semibold font-inherit z-[1]  sm:leading-[22px]">{`Mon-Ami `}</p>
            <div className=" relative text-base md:text-sm leading-[36px] z-[1]">
              Our AI chat bot that is segmented into different categories to
              improve AI's responses and your overall experiences
            </div>
           </div>
            
          </div>
          <div className="self-stretch h-96  w-[53%] md:w-full flex flex-row items-start justify-end">
            <div className="w-full rounded-3xl bg-[#F4F4F4] flex items-start justify-start pt-10 px-11 pb-11 box-border  ">
              <img
                className="h-80 flex-1 relative rounded-2xl w-full overflow-hidden object-cover z-[2]"
                loading="lazy"
                alt=""
                src="/images/government-2@2x.png"
              />
            </div>
          </div>
        </div>
        </div>
       
        <div className="flex flex-col gap-2">
        <p className="m-0 relative text-5xl sm:text-base  leading-[36px] font-semibold font-inherit z-[1]  sm:leading-[22px] w-full">Guided search</p>
        <div className="self-stretch h-96 md:h-auto flex flex-row md:flex-col items-start justify-start  w-full">
          <div className="self-stretch h-96  w-[53%] md:w-full flex flex-row items-start justify-end">
            <div className="w-full rounded-3xl bg-[#F4F4F4] flex items-start justify-start pt-10 px-11 pb-11 box-border  ">
              <img
                className="h-80 flex-1 relative rounded-2xl w-full overflow-hidden object-cover z-[2]"
                loading="lazy"
                alt=""
                src="/images/government-2@2x.png"
              />
            </div>
          </div>
          <div className="w-[47%] h-full md:w-full rounded-11xl bg-darkslategray-1200 flex flex-col items-center justify-center   box-border ">
           <div className="bg-[#3864FF] w-full h-80  text-white-A700 flex flex-col px-20 md:px-10 item-center justify-center rounded-r-3xl">
           <p className="m-0 relative text-5xl sm:text-base  leading-[36px] font-semibold font-inherit z-[1]  sm:leading-[22px]">{`Search `}</p>
            <div className=" relative text-base md:text-sm leading-[36px] z-[1]">
            Guided search, where we fetch the information you need with a few clicks and selection parameters.
            </div>
           </div>
            
          </div>
          
        </div>
        </div>

      
        <div className="w-full flex flex-row items-start justify-start py-0 px-px box-border text-xl sm:text-base ">
            <div className="rounded-3xl bg-[#F5F5F7] flex flex-col items-start justify-center pt-[34px] px-[23px] pb-5 box-border w-full sm:pt-[22px] sm:box-border">
             
            <p className="m-0">
                  <span className="text-gray-800 ">{`Find what you need whenever you need. `}</span>
                  <span className="text-dimgra">{`As you continue to use our application, our system learns to prioritize what's important to you and all immigrants. `}</span>
                </p>
                <p className="m-0">
                  <span className="text-gray-800">&nbsp;</span>
                </p>
                <p className="m-0">
                  <span className="text-gray-800">{`We look forward to your feedback via our `}</span>
                  <span onClick={handleCategoryClick} className="[text-decoration:underline] text-blue-500 cursor-pointer">
                    feedback form
                  </span>
                  <span className="text-gray-800">{`. This is the MVP version, your feedback will help us improve our approach to your needs.`}</span>
                </p>
              
            </div>
          </div>
          <NavLink to="/dashboard/category">
        <div className="cursor-pointer relative justify-center px-5 py-4 mt-16 mb-11 text-base text-c  md:text-sm text-white-A700 bg-black-900 rounded-[34px] max-md:px-5 max-md:my-10 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-110 duration-300 self-center">
          Proceed
        </div>
     </NavLink>  
      </div>
    </div>
  );
};

export default Resources;
