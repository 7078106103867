import React, { useState } from 'react';
import axios from 'axios';
import { Heading } from 'components';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import { ReactTyped } from 'react-typed';
import CategoryDropdown from 'components/CategoryDropdown';
import { SmallYouTubeVideo, YouTubeVideo } from 'components/VideoPlayer';

interface Page1Props {
  togglePage: () => void;
}

interface ChatEntryProps {
  entry: { type: string; text: string };
  isUser: boolean;
}

const QuickLink: React.FC<{ text: string; onClick: () => void }> = ({ text, onClick }) => (
  <div
    onClick={onClick}
    className="w-full cursor-pointer z-0  flex flex-col justify-start p-5 text-sm rounded-xl bg-gray-200 mb-2 transition ease-in-out duration-200 hover:scale-105 animate__animated animate__fadeInUp"
  >
    {text}
  </div>
);



const convertTextToLinks = (text) => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, url => `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: blue;">${url}</a>`);
};

const ChatEntry = ({ entry, isUser }) => {
  const convertedText = convertTextToLinks(entry.text);

  return (
    <div className="mb-2 p-2 rounded-lg whitespace-pre-line">
      <div className="flex flex-row items-center gap-2 mt-5">
        <div className={`flex self-start justify-center items-center text-xs text-center font-medium leading-4 text-black whitespace-nowrap ${isUser ? 'bg-gray-400' : 'bg-yellow-300'} rounded-full h-8 w-8 md:hidden`}>
          {isUser ? 'Me' : 'M'}
        </div>
        <div className="flex flex-col">
          <h1 className={`font-bold text-base md:text-sm ${isUser ? '' : 'bg-gradient2 bg-clip-text !text-transparent'}`}>
            {isUser ? 'You' : 'Mon - Ami'}
          </h1>
          <ReactTyped 
            strings={[convertedText]} 
            typeSpeed={10} 
            loop={false} 
            showCursor={false} 
          />
        </div>
      </div>
    </div>
  );
};

const Page1: React.FC<Page1Props> = ({ togglePage }) => {
  const [message, setMessage] = useState<string>('');
  const [chat, setChat] = useState<Array<{ type: string, text: string }>>([]);
  const [loading, setLoading] = useState(false);

  const fetchResponse = async (query: string, subcategory: string) => {
    const newChat = [...chat, { type: 'user', text: query }];
    setChat(newChat);
    setMessage('');
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const apiUrl = 'https://linked-origin-server.vercel.app/api/v1/users/mon-ami/chat';
      const requestBody = {
        searchQuery: query,
        category: 'community',
        // subCategory: 'immigrant support groups'
      };

      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      const responseText = response.data?.response || 'No response from the server.';
      setChat(prevChat => [...newChat, { type: 'bot', text: responseText }]);
    } catch (error) {
      console.error('Error fetching response:', error);
      setChat(prevChat => [...newChat, { type: 'bot', text: 'High Request Volume. Due to our system experiencing a high volume of requests, we are unable to process your request.Please check back later. Thank you for your patience!' }]);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    if (message.trim() === '') return;
    fetchResponse(message, 'immigrant support groups');
  };

  const handleQuickLink = async (quicklink: string, subcategory: string) => {
    fetchResponse(quicklink, subcategory);
  };

  return (
    <div className="flex flex-col item-center w-full ">
      <Header />
    
      <div className="w-full h-full flex flex-col justify-center gap-4 items-center p-4 md:p-4 bg-white">
      <div className="flex gap-5 mb-10  justify-start md:flex-wrap w-full px-10 md:hidden animate__animated animate__fadeInUp">
          
          <CategoryDropdown
            name="Media"
            img= "/images/img_thumbs_up.svg"
           />
  
            
          </div>
          <p className="md:block hidden  md:text-2xl text-3xl text-left self-start bg-gradient2 bg-clip-text !text-transparent !font-kumbhsans ">
                Media
              </p>   

              
        <div className="flex flex-col gap-4  w-[70%] md:w-full">
        <p className="md:text-base text-xl text-left self-start !font-kumbhsans ">
        Our Videos
      </p>
          <div className='flex flex-row md:flex-col gap-5 justify-center items-center mb-10 w-full'>
        
          <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/6okTvd1dMuk" />
            <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/YyhgJajdDyw" />
             {/* <YouTubeVideo videoUrl="https://www.youtube.com/embed/1QFTSafMxBQ" />
             <YouTubeVideo videoUrl="https://www.youtube.com/embed/Ek1f2MIe34s" /> */}
             <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/zpXXvx1gCpU" />
          </div>
            
               
        </div>
        <div className="flex flex-col gap-4  w-[70%] md:w-full">
        <p className="md:text-base text-xl text-left self-start !font-kumbhsans ">
      Other Resources
      </p>
          <div className='flex flex-row md:flex-col gap-5 justify-center items-center mb-10 w-full'>
        
      <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/1QFTSafMxBQ" />
             <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/Ek1f2MIe34s" /> 
             <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/zpXXvx1gCpU" />
          </div>
            
               
        </div>
      </div>
    </div>
  );
};

export default Page1;
