import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Heading, Input, CheckBox, Img } from "../../components";
import { Link, NavLink, useNavigate } from "react-router-dom";

import StepComponent from "./component/sideicons";
import AuthService from "services/authService";
import { Alert, Box, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import { toast, Bounce, ToastContainer } from "react-toastify";
import { CgSpinner } from "react-icons/cg";
import DatePicker from "react-datepicker";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { scroller } from 'react-scroll';
interface TableRow {
  id: number;
  title: string;
  selectedOption: string;
}

export default function MacBookPro14SixPage() {

  const [tableRows, setTableRows] = useState<TableRow[]>([
    { id: 1, title: 'Listening', selectedOption: '' },
    { id: 2, title: 'Reading', selectedOption: '' },
    { id: 3, title: 'Speaking', selectedOption: '' },
    { id: 4, title: 'Writing', selectedOption: '' },
  ]);

const handleOptionChange = (rowId: number, option: string) => {
  setTableRows(prevState =>
    prevState.map(row =>
      row.id === rowId ? { ...row, selectedOption: option } : row
    )
  );
};
const [isChecked, setIsChecked] = useState(false);

const handleCheckboxChange = () => {
  setIsChecked(!isChecked);
};







  const [firstname, setFirstNmae] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [ gender, setgender] = useState('');
  const [ phone, setphone] = useState('');
  const [ countryOfOrigin, setcountryOfOrigin] = useState('');
  const [ currentImmigrationStatus, setcurrentImmigrationStatus] = useState('');
  const [ dateOfImmigration,setdateOfImmigration] = useState('');
  const [ visaType,setvisaType] = useState('');
  const [ typeOfStatus,settypeOfStatus]  = useState('');
  const [ nativeLanguage,setnativeLanguage]  = useState('');
  const [ highestLevelOfEducation,sethighestLevelOfEducation]  = useState('');
  const [ previousWorkExperience,setpreviousWorkExperience]  = useState('');
  const [ currentHousingSituation,setacurrentHousingSituation]  = useState('');
  const [ numOfFamilyMembers,setnumOfFamilyMembers]  = useState('');
  const [ interestsAndHobbies, setinterestsAndHobbies]  = useState('');
  const [ preferredSocialActivities, setpreferredSocialActivities]  = useState('');
  const [ ethos, setethos] = useState('');
  
  const [error, setError] = useState<string | null>(null);
 const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate()
  const [sector, setSector] = useState('');
  const [subSector, setSubSector] = useState('');
  const [category, setCategory] = useState('');
  const [role, setRole] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const [firstName, setFirstName] = useState('');

  const [dateOfBirth, setDateOfBirth] = useState<string>(getCurrentDate());
  const [immigrationDate, setimmigrationDate] = useState<string>(getCurrentDate());
  // Function to handle changes in dropdowns

  const handlefirsNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNmae(e.target.value);
  };
  const handlelastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); 

  // const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setdateOfBirth(e.target.value); // Update selected date state
  // };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  const handleGenderChange = (event) => {
    setgender(event.target.value);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setphone(e.target.value);
  };
  // stet2
  const handleCountryofOriginChange = (event) => {
    setcountryOfOrigin(event.target.value);
  };
  const handlelCurrentImigratStatusChange = (event) => {
    setcurrentImmigrationStatus(event.target.value);
  };

  const handleImigrationDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setdateOfImmigration(e.target.value);
  };
  
  const handleVisaTypeChange = (event) => {
    setvisaType(event.target.value);
  };

  // stet3
  const handleNativeLanguageChange = (event) => {
    setnativeLanguage(event.target.value);
  };

    // stet4
  const handlleHighetEducation = (event) => {
    sethighestLevelOfEducation(event.target.value);
  };

  const handlePreviousWorkExperienceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpreviousWorkExperience(e.target.value);
  };

  // const handleGoalAndAspirationhange = (e: ChangeEvent<HTMLInputElement>) => {
  //   setaspirations(e.target.value);
  // };

  const handleTypeOfStatusChange = (event) => {
    settypeOfStatus(event.target.value);
  };

  const handleRoleChange = (event) => {
    setRole(event.target.value);
  };

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };


  const isPasswordValid = (password: string) => {
    // Define regular expressions for each required character type
    const hasCapital = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasSpecial = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password);
  
    // Check if all required character types are present
    return hasCapital && hasLowercase && !hasSpecial && password.length >= 8;
    };

  
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };
  useEffect(() => {
    // Check local storage for user data
    const userData = localStorage.getItem('userData');
    if (userData) {
      // If user data is found, consider the user logged in
      setLoggedIn(true);
    }
  }, []);
  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };
  const [houseSituation, setHouseSituation] = useState<string>("");

  const handleHouseSituationChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setHouseSituation(event.target.value);
  };
 
  const [previousWorkIndustry, setPreviousWorkIndustry] = useState<string>("");

  const handleIndustryChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setPreviousWorkIndustry(event.target.value);
  };
  const [educationComplete, setEducationComplete] = useState<string>("");

  const handleEducationChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setEducationComplete(event.target.value);
  };

  const [housingPreference, setHousingPreference] = useState<string>("");

  const handleHousingPreferenceChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setHousingPreference(event.target.value);
  };
  const [familyNumber, setFamilyNumber] = useState<string>("");

  const handleFamilyNumberChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setFamilyNumber(event.target.value);
  };

  const [relationship, setRelationship] = useState<string>("");

  const handleRelationshipChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setRelationship(event.target.value);
  };

  const [selectedHobby, setSelectedHobby] = useState<string>("");

  const handleHobbyChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setSelectedHobby(event.target.value);
  };

  const [selectedActivity, setSelectedActivity] = useState<string>("");

  const handleActivityChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setSelectedActivity(event.target.value);
  };

  const [selectedOption, setSelectedOption] = useState<string>("");

  const handleReligiousOptionChange = (event: ChangeEvent<HTMLSelectElement>) => {
      setSelectedOption(event.target.value);
  };
  const [immigrationConcerns, setImmigrationConcerns] = useState<string>("");
  const [supportServicesNeeded, setSupportServicesNeeded] = useState<string>("");
  const [aspirations, setAspirations] = useState<string>("");

  const handleImmigrationConcernsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setImmigrationConcerns(event.target.value);
    };
 const handleSupportServicesChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setSupportServicesNeeded(event.target.value);
    };

  const handleAspirationsChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setAspirations(event.target.value);
  };
  
  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleNext = (): void => {
    if(currentStep===1){
      if (!firstname || !lastname || !email|| !gender || !phone  || !password || !confirmpassword ) {
        setError('Please fill in all required fields.');
        return;
      }
      if ( password !== confirmpassword ) {
        setError('Password does not match');
        return;
      }
    

      // Check password complexity
      if (!isPasswordValid(password) ||!isPasswordValid(confirmpassword)) {
        setError('Password must contain at least one capital letter, one lowercase letter,  and  must not be less than 8 characters in length');
        return;
      }
    }
    if(currentStep===2){
      if (!countryOfOrigin || !currentImmigrationStatus || !visaType || !typeOfStatus   ) {
        setError('Please fill in all required fields.');
        return;
      }
    }
    if(currentStep===3){
      if (! nativeLanguage  ) {
        setError('Please fill in all required fields.');
        return;
      }
      
    }
    if(currentStep===4){
      if (!educationComplete || !previousWorkIndustry || !aspirations  ) {
        setError('Please fill in all required fields.');
        return;
      }
    }
    if(currentStep===5){
      if (!houseSituation || !housingPreference || !familyNumber || !relationship   ) {
        setError('Please fill in all required fields.');
        return;
      }
    }
    if(currentStep===6){
      if (!selectedHobby || !selectedActivity || !selectedOption  ) {
        setError('Please fill in all required fields.');
        return;
      }
    }
    if(currentStep===7){
      if (!immigrationConcerns || !supportServicesNeeded ) {
        setError('Please fill in all required fields.');
        return;
      }
    }
    
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
  });
    setError('');
    setCurrentStep((prevStep) => prevStep + 1);

  };

  const handlePrev = (): void => {
   
  
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
  });

    setCurrentStep((prevStep) => prevStep - 1);
  };


  const renderProgress = (): JSX.Element => {
    return (
      <div className=" w-full flex flex-col items-center justify-center">
     
     { currentStep <= 7?( <div className="flex items-center">
        <span
          className={`w-8 h-8 sm:h-2 sm:w-2 sm:text-[1px] text-[10px]   rounded-full flex items-center justify-center ${
            currentStep >= 1 ? 'bg-[#4E5567]   text-white-A700' : 'bg-gray-400'
          }`}
        >
          1
        </span>
        <div className={`w-8 h-1 sm:h-1 sm:w-4  ${
            currentStep >= 1 ? 'bg-[#4E5567] ' : 'bg-gray-400'
          }`}></div>
        <span
          className={`w-8 h-8 sm:h-2 sm:w-2 sm:text-[1px] text-[10px] rounded-full flex items-center justify-center ${
            currentStep >= 2 ? 'bg-[#4E5567] text-white-A700' : 'bg-gray-400'
          }`}
        >
           2
        </span>
        <div className={`w-8 h-1 sm:h-1 sm:w-4 ${
            currentStep >= 2 ? 'bg-[#4E5567] ' : 'bg-gray-400'
          }`}></div>
        <span
          className={`w-8 h-8 sm:h-2 sm:w-2 sm:text-[1px] text-[10px] rounded-full flex items-center justify-center ${
            currentStep >= 3 ? 'bg-[#4E5567] text-white-A700' : 'bg-gray-400'
          }`}
        >
           3
        </span>
        <div className={`w-8 h-1 sm:h-1 sm:w-4 ${
            currentStep >= 3 ? 'bg-[#4E5567] ' : 'bg-gray-400'
          }`}></div>
          
          <span
          className={`w-8 h-8 sm:h-2 sm:w-2 sm:text-[1px] text-[10px] rounded-full flex items-center justify-center ${
            currentStep >=4 ? 'bg-[#4E5567] text-white-A700' : 'bg-gray-400'
          }`}
        >
           4
        </span>
        <div className={`w-8 h-1 sm:h-1 sm:w-4 ${
            currentStep >= 4 ? 'bg-[#4E5567] ' : 'bg-gray-400'
          }`}></div>

<span
          className={`w-8 h-8 sm:h-2 sm:w-2 sm:text-[1px] text-[10px] rounded-full flex items-center justify-center ${
            currentStep >= 5 ? 'bg-[#4E5567] text-white-A700' : 'bg-gray-400'
          }`}
        >
           5
        </span>
        <div className={`w-8 h-1 sm:h-1 sm:w-4 ${
            currentStep >= 5 ? 'bg-[#4E5567] ' : 'bg-gray-400'
          }`}></div>
            <span
          className={`w-8 h-8 sm:h-2 sm:w-2 sm:text-[1px] text-[10px] rounded-full flex items-center justify-center ${
            currentStep >= 6 ? 'bg-[#4E5567] text-white-A700' : 'bg-gray-400'
          }`}
        >
           6
        </span>
        <div className={`w-8 h-1  sm:h-1 sm:w-4 ${
            currentStep >= 6 ? 'bg-[#4E5567] ' : 'bg-gray-400'
          }`}></div>
            <span
          className={`w-8 h-8 sm:h-2 md:w-2 sm:text-[1px] text-[10px] rounded-full flex items-center justify-center ${
            currentStep === 7 ? 'bg-[#4E5567] text-white-A700' : 'bg-gray-400'
          }`}
        >
           7
        </span>
       
      </div>):(<></>) }
    
      </div>
    );
  };

  const userData = {
  firstName: firstname,
  lastName:  lastname,
  dateOfBirth: dateOfBirth,
  gender: gender,
  email: email,
  password: password,
  phone:phone,
  countryOfOrigin: countryOfOrigin,
  currentImmigrationStatus: countryOfOrigin,
  dateOfImmigration: immigrationDate,
  visaType: visaType,
  typeOfStatus: typeOfStatus,
  nativeLanguage: nativeLanguage,
  highestLevelOfEducation: educationComplete,
  previousWorkExperience:previousWorkIndustry,
  aspirations:aspirations,
  currentHousingSituation:houseSituation,
  housingPreference:housingPreference,
  numOfFamilyMembers: '2',
  relationship:relationship,
  interestsAndHobbies: selectedHobby,
  preferredSocialActivities:selectedActivity,
  ethos:immigrationConcerns

 }



  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // Check if any required fields are empty
    if (!firstname || !lastname || !email || !password) {
      setError('Please fill in all required fields.');
      return;
    }
 
  
  
  
    // Set loading to true when submitting the form
    setLoading(true);
    setError('');
  
    const { success, error } = await AuthService.register(userData);
  
    // Reset loading state after login attempt
    setLoading(false);
  
    if (success) {
      setError('');
      navigate(`/auth/login`);
      toast.success('Registered  Successfully', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    } else {
      if (error !== undefined) {
        // setError(error);
      }
    }
  };
  const navigateToAboutSection = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo('service', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }, 100);
  };
  const navigateToContactSection = () => {
    navigate('/contact-us');
  };
  return (
    <>
      <Helmet>
      <title>LinkedOrigin</title>
        <meta name="description" content="Simplify your settlement journey with AI and streamlined resources" />

      </Helmet>
      <div className="flex flex-col pb-20 bg-white">
      <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5 p-10 md:p-4  bg-[#fff] md:border-gray-300 md:border-b bg-opacity-20 right-3 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none" ref={navbarRef}>
        <div className="container mx-auto flex  justify-between items-center">
        <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-8 md:h-6 " />

          <div className="hidden md:block  ">
            {isMenuOpen ? (
              <button 
                onClick={handleCloseMenu}
                className="text-[#898989] focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button 
                onClick={handleMenuToggle}
                className="text-[#898989] focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            )}
          </div>
  
          {isMenuOpen && (
            <div className="hidden md:block m-auto items-center  justify-center  absolute top-14 right-0 left-0  bg-black-900 z-50 w-full h-auto shadow p-4">
             
          
          <div className="flex flex-col gap-5 text-white-A700" >
          <NavLink to="/">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">Home</div>
            </NavLink>
          <NavLink to="/about">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/about">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">Matching</div>
            </NavLink>
            {loggedIn ? (  
      <>
        <div  onClick={() => {
               AuthService.logout();
               navigate("/auth/login");
             }} >Logout</div>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div>Login</div>

        </NavLink>
      )}
             
  
          </div>
            </div>
          )}
  
          <div className="md:hidden  flex flex-row  items-center justify-center">
    
          <div className="flex gap-5 justify-between  md:flex-wrap">
            <div className="flex gap-5 justify-between self-start text-black md:flex-wrap md:w-full">
            <NavLink to="/">
            <div className="text-[#191919D1]  hover:text-orange-300">Home</div>
            </NavLink>
            <NavLink to="/about">
            <div className=" text-[#191919D1] ">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#191919D1] hover:text-orange-300">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#191919D1] hover:text-orange-300">Contact Us</div>
            <NavLink to="/matching/locals">
            <div className="cursor-pointer text-[#191919D1] hover:text-orange-300">Matching</div>
            </NavLink>
 
            
            </div>
            
           
          </div>
          </div>
          <div className="md:hidden  flex flex-row items-center gap-2">
  
    <div className="flex flex-row gap-4 items-center justify-center ">
                                
  <svg width="77" height="40" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.97 38.33C47.97 38.69 47.89 39.06 47.72 39.42C47.55 39.78 47.33 40.12 47.04 40.44C46.55 40.98 46.01 41.37 45.4 41.62C44.8 41.87 44.15 42 43.45 42C42.43 42 41.34 41.76 40.19 41.27C39.04 40.78 37.89 40.12 36.75 39.29C35.6 38.45 34.51 37.52 33.47 36.49C32.44 35.45 31.51 34.36 30.68 33.22C29.86 32.08 29.2 30.94 28.72 29.81C28.24 28.67 28 27.58 28 26.54C28 25.86 28.12 25.21 28.36 24.61C28.6 24 28.98 23.44 29.51 22.94C30.15 22.31 30.85 22 31.59 22C31.87 22 32.15 22.06 32.4 22.18C32.66 22.3 32.89 22.48 33.07 22.74L35.39 26.01C35.57 26.26 35.7 26.49 35.79 26.71C35.88 26.92 35.93 27.13 35.93 27.32C35.93 27.56 35.86 27.8 35.72 28.03C35.59 28.26 35.4 28.5 35.16 28.74L34.4 29.53C34.29 29.64 34.24 29.77 34.24 29.93C34.24 30.01 34.25 30.08 34.27 30.16C34.3 30.24 34.33 30.3 34.35 30.36C34.53 30.69 34.84 31.12 35.28 31.64C35.73 32.16 36.21 32.69 36.73 33.22C37.27 33.75 37.79 34.24 38.32 34.69C38.84 35.13 39.27 35.43 39.61 35.61C39.66 35.63 39.72 35.66 39.79 35.69C39.87 35.72 39.95 35.73 40.04 35.73C40.21 35.73 40.34 35.67 40.45 35.56L41.21 34.81C41.46 34.56 41.7 34.37 41.93 34.25C42.16 34.11 42.39 34.04 42.64 34.04C42.83 34.04 43.03 34.08 43.25 34.17C43.47 34.26 43.7 34.39 43.95 34.56L47.26 36.91C47.52 37.09 47.7 37.3 47.81 37.55C47.91 37.8 47.97 38.05 47.97 38.33Z" stroke="#191919D1" stroke-width="1.5" stroke-miterlimit="10"/>
  <rect x="1" y="1" width="75" height="61" rx="30.5" stroke="#191919D1" stroke-width="2"/>
  </svg>
    </div>
    {loggedIn ? (  
      <>
        <NavLink to="/auth/login">
            <div className="text-[#191919D1] border border-[#191919D1] rounded-full p-3 text-sm">Logout</div>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div className="text-[#191919D1] border border-[#191919D1] rounded-full p-3 text-sm">Login</div>

        </NavLink>
      )}
    </div>
        </div>
            </nav>
     <div className="w-full relative">
      <div className=" md:hidden absolute top-36 left-10">
      <p className="font-bold !text-blue_gray-700 text-5xl">
                  Sign Up
                </p>
      </div>
      <StepComponent currentStep={currentStep} />
     


     <div className="w-full absolute flex flex-col items-center justify-center md:px-5">
   
     <div className="mt-40 md:mt-20  md:w-full ">
      {renderProgress()}

      </div>
      <form onSubmit={handleSubmit} className='w-full flex flex-col justify-center items-center'>

      <div className=" w-[40%] md:w-full   ">
      <>

            {currentStep === 1 ? (
               <div className="self-end mt-10 w-full flex items-center justify-center animate__animated animate__fadeInUp">
                  <div className="flex flex-col w-full md:ml-0 md:w-full">
                  <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                             
                              <p className="font-bold !text-blue_gray-700 text-base">
                              Personal information 
                              </p>
                            </div>
                  <div className="flex sm:flex-col self-stretch gap-[9px]">
              
                          <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                          <div className="mb-4 w-full" >
                          <label className="mb-2.5 block font-medium  text-black ">
                            First Name
                          </label>
                          <TextField
      placeholder="First Name"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlefirsNameChange}
      value={firstname}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                          </div>
                         
                          <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                          <div className="mb-4 w-full" >
                          <label className="mb-2.5 block font-medium  text-black ">
                            Last Name
                          </label>
                          <TextField
      placeholder="Last Name"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlelastNameChange}
      value={lastname}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                          </div>
                        </div>
                     
                        <div className="mb-4 w-full" >
                          <label className="mb-2.5 block font-medium  text-black ">
                            Date Of Birth
                          </label>
                          <div className="relative w-full rounded border-[1.5px] border-[#E0E0E0] bg-[#E0E0E0] px-5 py-4">
          
           <Flatpickr
          value={dateOfBirth}
          onChange={(date) => setDateOfBirth(date[0].toISOString().split('T')[0])}
          options={{
            dateFormat: 'Y-m-d',
            enableTime: false,
            altInput: true,
            altFormat: 'Y-m-d',
            time_24hr: true,
            allowInput: true,
          }}
          className="w-full bg-transparent"
        />

        <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.0002 12.8249C8.83145 12.8249 8.69082 12.7687 8.5502 12.6562L2.08145 6.2999C1.82832 6.04678 1.82832 5.65303 2.08145 5.3999C2.33457 5.14678 2.72832 5.14678 2.98145 5.3999L9.0002 11.278L15.0189 5.34365C15.2721 5.09053 15.6658 5.09053 15.9189 5.34365C16.1721 5.59678 16.1721 5.99053 15.9189 6.24365L9.45019 12.5999C9.30957 12.7405 9.16895 12.8249 9.0002 12.8249Z"
              fill="#64748B"
            />
          </svg>
        </div>
      </div>
      
                        </div>
                    <div className="mb-4 w-full" >
                  <label className="mb-2.5 block font-medium  text-black ">
                    Email
                  </label>
                  <TextField
      placeholder="Email"
      type="email"
      className='w-full self-stretch flex'
      onChange={handleEmailChange}
      value={email}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />

                </div>
                <div className="mb-4 w-full" >
                <label className="mb-2.5 block font-medium  text-black ">
                 Gender
                </label>
                <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
    <select  value={gender} onChange={handleGenderChange} className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
      <option value="">Select gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
      <option value="x">X</option>
      <option value="Others">others</option>
    </select>
  </div>
              </div>
                        <div className="mb-6 w-full">
                          <label className="mb-2.5 block font-medium  text-black ">
                            Phone 
                          </label>
                          <TextField
      placeholder="Phone number"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlePhoneChange}
      value={phone}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                        <div className="mb-6 w-full">
                  <label className="mb-2.5 block font-medium  text-black ">
                    Password 
                  </label>
                  <TextField
            placeholder="Password must contain at least one capital letter, one lowercase letter,  and  must not be less than 8 characters in length"
            type={showPassword ? 'text' : 'password'} // Show/hide password based on state
            value={password}
            onChange={handlePasswordChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#E0E0E0', // Set background color
                '& fieldset': {
                  borderColor: '#E0E0E0', // Set border color
                },
                '&:hover fieldset': {
                  borderColor: '#E0E0E0', // Set border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#E0E0E0', // Set border color on focus
                },
              },
             
              '& .MuiInputBase-root': {
                marginTop: 0, // Remove top padding
                marginBottom: 0, // Remove bottom padding
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Remove outline by default
              },
            }}
            fullWidth
            margin="normal"
            variant="outlined"
            required

            InputProps={{
              // Show/hide password icon button
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
                  <label className="mb-1 block font-medium text-xs  text-gray-500 ">
                  Password must contain at least one capital letter, one lowercase letter,  and  must not be less than 8 characters in length 
                  </label>
          
                </div>
                <div className="mb-6 w-full">
                  <label className="mb-2.5 block font-medium  text-black ">
                   Confirm Password 
                  </label>
                  <TextField
            placeholder="Password must contain at least one capital letter, one lowercase letter, and  must not be less than 8 characters in length"
            type={showPassword ? 'text' : 'password'} // Show/hide password based on state
            value={confirmpassword}
            onChange={handleConfirmChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#E0E0E0', // Set background color
                '& fieldset': {
                  borderColor: '#E0E0E0', // Set border color
                },
                '&:hover fieldset': {
                  borderColor: '#E0E0E0', // Set border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#E0E0E0', // Set border color on focus
                },
              },
             
              '& .MuiInputBase-root': {
                marginTop: 0, // Remove top padding
                marginBottom: 0, // Remove bottom padding
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Remove outline by default
              },
            }}
            fullWidth
            margin="normal"
            variant="outlined"
            required

            InputProps={{
              // Show/hide password icon button
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
               
             
                </div>
                {error &&  <Alert severity="error">{error}</Alert>}
   
                  </div>
              </div>
            ) : currentStep === 2 ? (
              <div className="self-end mt-10 w-full flex items-center justify-center animate__animated animate__fadeInUp">
              <div className="flex flex-col w-full">
              <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                       
                          <p className="font-bold !text-blue_gray-700 text-3xl">
                          Basic Immigration Details
                              </p>
                        </div>
            
                 
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                        Country of origin
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select value={countryOfOrigin} onChange={handleCountryofOriginChange}  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
       
          <option value="">Select country</option>
<option value="Afghanistan">Afghanistan</option>
<option value="Albania">Albania</option>
<option value="Algeria">Algeria</option>
<option value="Andorra">Andorra</option>
<option value="Angola">Angola</option>
<option value="Antigua and Barbuda">Antigua and Barbuda</option>
<option value="Argentina">Argentina</option>
<option value="Armenia">Armenia</option>
<option value="Australia">Australia</option>
<option value="Austria">Austria</option>
<option value="Azerbaijan">Azerbaijan</option>
<option value="Bahamas">Bahamas</option>
<option value="Bahrain">Bahrain</option>
<option value="Bangladesh">Bangladesh</option>
<option value="Barbados">Barbados</option>
<option value="Belarus">Belarus</option>
<option value="Belgium">Belgium</option>
<option value="Belize">Belize</option>
<option value="Benin">Benin</option>
<option value="Bhutan">Bhutan</option>
<option value="Bolivia">Bolivia</option>
<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
<option value="Botswana">Botswana</option>
<option value="Brazil">Brazil</option>
<option value="Brunei">Brunei</option>
<option value="Bulgaria">Bulgaria</option>
<option value="Burkina Faso">Burkina Faso</option>
<option value="Burundi">Burundi</option>
<option value="Cabo Verde">Cabo Verde</option>
<option value="Cambodia">Cambodia</option>
<option value="Cameroon">Cameroon</option>
<option value="Canada">Canada</option>
<option value="Central African Republic">Central African Republic</option>
<option value="Chad">Chad</option>
<option value="Chile">Chile</option>
<option value="China">China</option>
<option value="Colombia">Colombia</option>
<option value="Comoros">Comoros</option>
<option value="Congo">Congo</option>
<option value="Costa Rica">Costa Rica</option>
<option value="Croatia">Croatia</option>
<option value="Cuba">Cuba</option>
<option value="Cyprus">Cyprus</option>
<option value="Czech Republic">Czech Republic</option>
<option value="Denmark">Denmark</option>
<option value="Djibouti">Djibouti</option>
<option value="Dominica">Dominica</option>
<option value="Dominican Republic">Dominican Republic</option>
<option value="East Timor">East Timor</option>
<option value="Ecuador">Ecuador</option>
<option value="Egypt">Egypt</option>
<option value="El Salvador">El Salvador</option>
<option value="Equatorial Guinea">Equatorial Guinea</option>
<option value="Eritrea">Eritrea</option>
<option value="Estonia">Estonia</option>
<option value="Eswatini">Eswatini</option>
<option value="Ethiopia">Ethiopia</option>
<option value="Fiji">Fiji</option>
<option value="Finland">Finland</option>
<option value="France">France</option>
<option value="Gabon">Gabon</option>
<option value="Gambia">Gambia</option>
<option value="Georgia">Georgia</option>
<option value="Germany">Germany</option>
<option value="Ghana">Ghana</option>
<option value="Greece">Greece</option>
<option value="Grenada">Grenada</option>
<option value="Guatemala">Guatemala</option>
<option value="Guinea">Guinea</option>
<option value="Guinea-Bissau">Guinea-Bissau</option>
<option value="Guyana">Guyana</option>
<option value="Haiti">Haiti</option>
<option value="Honduras">Honduras</option>
<option value="Hungary">Hungary</option>
<option value="Iceland">Iceland</option>
<option value="India">India</option>
<option value="Indonesia">Indonesia</option>
<option value="Iran">Iran</option>
<option value="Iraq">Iraq</option>
<option value="Ireland">Ireland</option>
<option value="Israel">Israel</option>
<option value="Italy">Italy</option>
<option value="Jamaica">Jamaica</option>
<option value="Japan">Japan</option>
<option value="Jordan">Jordan</option>
<option value="Kazakhstan">Kazakhstan</option>
<option value="Kenya">Kenya</option>
<option value="Kiribati">Kiribati</option>
<option value="Korea, North">Korea, North</option>
<option value="Korea, South">Korea, South</option>
<option value="Kosovo">Kosovo</option>
<option value="Kuwait">Kuwait</option>
<option value="Kyrgyzstan">Kyrgyzstan</option>
<option value="Laos">Laos</option>
<option value="Latvia">Latvia</option>
<option value="Lebanon">Lebanon</option>
<option value="Lesotho">Lesotho</option>
<option value="Liberia">Liberia</option>
<option value="Libya">Libya</option>
<option value="Liechtenstein">Liechtenstein</option>
<option value="Lithuania">Lithuania</option>
<option value="Luxembourg">Luxembourg</option>
<option value="Madagascar">Madagascar</option>
<option value="Malawi">Malawi</option>
<option value="Malaysia">Malaysia</option>
<option value="Maldives">Maldives</option>
<option value="Mali">Mali</option>
<option value="Malta">Malta</option>
<option value="Marshall Islands">Marshall Islands</option>
<option value="Mauritania">Mauritania</option>
<option value="Mauritius">Mauritius</option>
<option value="Mexico">Mexico</option>
<option value="Micronesia">Micronesia</option>
<option value="Moldova">Moldova</option>
<option value="Monaco">Monaco</option>
<option value="Mongolia">Mongolia</option>
<option value="Montenegro">Montenegro</option>
<option value="Morocco">Morocco</option>
<option value="Mozambique">Mozambique</option>
<option value="Myanmar">Myanmar</option>
<option value="Namibia">Namibia</option>
<option value="Nauru">Nauru</option>
<option value="Nepal">Nepal</option>
<option value="Netherlands">Netherlands</option>
<option value="New Zealand">New Zealand</option>
<option value="Nicaragua">Nicaragua</option>
<option value="Niger">Niger</option>
<option value="Nigeria">Nigeria</option>
<option value="North Macedonia">North Macedonia</option>
<option value="Norway">Norway</option>
<option value="Oman">Oman</option>
<option value="Pakistan">Pakistan</option>
<option value="Palau">Palau</option>
<option value="Palestine">Palestine</option>
<option value="Panama">Panama</option>
<option value="Papua New Guinea">Papua New Guinea</option>
<option value="Paraguay">Paraguay</option>
<option value="Peru">Peru</option>
<option value="Philippines">Philippines</option>
<option value="Poland">Poland</option>
<option value="Portugal">Portugal</option>
<option value="Qatar">Qatar</option>
<option value="Romania">Romania</option>
<option value="Russia">Russia</option>
<option value="Rwanda">Rwanda</option>
<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
<option value="Saint Lucia">Saint Lucia</option>
<option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
<option value="Samoa">Samoa</option>
<option value="San Marino">San Marino</option>
<option value="Sao Tome and Principe">Sao Tome and Principe</option>
<option value="Saudi Arabia">Saudi Arabia</option>
<option value="Senegal">Senegal</option>
<option value="Serbia">Serbia</option>
<option value="Seychelles">Seychelles</option>
<option value="Sierra Leone">Sierra Leone</option>
<option value="Singapore">Singapore</option>
<option value="Slovakia">Slovakia</option>
<option value="Slovenia">Slovenia</option>
<option value="Solomon Islands">Solomon Islands</option>
<option value="Somalia">Somalia</option>
<option value="South Africa">South Africa</option>
<option value="South Sudan">South Sudan</option>
<option value="Spain">Spain</option>
<option value="Sri Lanka">Sri Lanka</option>
<option value="Sudan">Sudan</option>
<option value="Suriname">Suriname</option>
<option value="Sweden">Sweden</option>
<option value="Switzerland">Switzerland</option>
<option value="Syria">Syria</option>
<option value="Taiwan">Taiwan</option>
<option value="Tajikistan">Tajikistan</option>
<option value="Tanzania">Tanzania</option>
<option value="Thailand">Thailand</option>
<option value="Togo">Togo</option>
<option value="Tonga">Tonga</option>
<option value="Trinidad and Tobago">Trinidad and Tobago</option>
<option value="Tunisia">Tunisia</option>
<option value="Turkey">Turkey</option>
<option value="Turkmenistan">Turkmenistan</option>
<option value="Tuvalu">Tuvalu</option>
<option value="Uganda">Uganda</option>
<option value="Ukraine">Ukraine</option>
<option value="United Arab Emirates">United Arab Emirates</option>
<option value="United Kingdom">United Kingdom</option>
<option value="United States">United States</option>
<option value="Uruguay">Uruguay</option>
<option value="Uzbekistan">Uzbekistan</option>
<option value="Vanuatu">Vanuatu</option>
<option value="Vatican City">Vatican City</option>
<option value="Venezuela">Venezuela</option>
<option value="Vietnam">Vietnam</option>
<option value="Yemen">Yemen</option>
<option value="Zambia">Zambia</option>
<option value="Zimbabwe">Zimbabwe</option>
</select>

        </div>
                    </div>
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                        Current immigaration status
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select value={currentImmigrationStatus} onChange={handlelCurrentImigratStatusChange}   className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
            <option value="">Select status</option>
            <option value="Permanent Resident">Permanent Resident</option>
            <option value="Temporary Resident holder">Temporary Resident Permit Holder</option>
            <option value="None">Prefer not to say</option>
          </select>
        </div>
                    </div>
                    <div className="mb-6 w-full">
                      <label className="mb-2.5 block font-medium  text-black ">
                        Date of immigration 
                      </label>
                      <div className="relative w-full rounded border-[1.5px] border-[#E0E0E0] bg-[#E0E0E0] px-5 py-4">
                      <Flatpickr
          value={immigrationDate}
          onChange={(date) => setimmigrationDate(date[0].toISOString().split('T')[0])}
          options={{
            dateFormat: 'Y-m-d',
            enableTime: false,
            altInput: true,
            altFormat: 'Y-m-d',
            time_24hr: true,
            allowInput: true,
          }}
          className="w-full bg-transparent"
        />

        <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.0002 12.8249C8.83145 12.8249 8.69082 12.7687 8.5502 12.6562L2.08145 6.2999C1.82832 6.04678 1.82832 5.65303 2.08145 5.3999C2.33457 5.14678 2.72832 5.14678 2.98145 5.3999L9.0002 11.278L15.0189 5.34365C15.2721 5.09053 15.6658 5.09053 15.9189 5.34365C16.1721 5.59678 16.1721 5.99053 15.9189 6.24365L9.45019 12.5999C9.30957 12.7405 9.16895 12.8249 9.0002 12.8249Z"
              fill="#64748B"
            />
          </svg>
        </div>
      </div>
             
                    </div>
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                       Visa type
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select  value={visaType} onChange={handleVisaTypeChange}  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
            <option value="">Select visa type</option>
            <option value="WorkPermit">Work Permit</option>
            <option value="StudyPermit">Study Permit</option>
            <option value="Other">others</option>
            <option value="None">Prefer not to say</option>
          </select>
        </div>
                    </div>
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                       Type of status
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select  value={typeOfStatus} onChange={handleTypeOfStatusChange}  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
            <option value="">Select Status</option>
            <option value="Skilled Worker">Skilled Worker</option>
            <option value="familySponsorship">Family Sponsorship</option>
            <option value="Other">others</option>
            <option value="None">Prefer not to say</option>
          </select>
        </div>
                    </div>
                    {error &&  <Alert severity="error">{error}</Alert>}
              </div>
            
          </div>
            ) : currentStep === 3 ? (
              <div className="self-end mt-10 w-full flex items-center justify-center animate__animated animate__fadeInUp">
              <div className="flex flex-col w-full md:ml-0 md:w-full">
              <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                        
                          <p className="font-bold !text-blue_gray-700 text-3xl">
                          Language Proficiency
                              </p>
                        </div>
            
                 
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                        Native Langauge
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select value={nativeLanguage} onChange={handleNativeLanguageChange}  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
            <option value="">Select Language</option>
            <option value="English">English</option>
            
          </select>
        </div>
                    </div>
               
                    <table className="mt-2 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2 rounded-md w-full">
        <thead>
          <tr>
            <th className="min-w-[20px] px-2 py-2 font-medium border border-gray-500"></th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">1</th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">2</th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">3</th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">4</th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">5</th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map(row => (
            <tr key={row.id}>
              <td className="border border-gray-500 text-base" style={{ textAlign: 'center' }}>{row.title}</td>
              {['option1', 'option2', 'option3', 'option4', 'option5'].map(option => (
                <td className="border border-gray-500 px-2 py-2" style={{ textAlign: 'center' }} key={option}>
                  <input
                    type="radio"
                    name={`row${row.id}`}
                    value={option}
                    checked={row.selectedOption === option}
                    onChange={() => handleOptionChange(row.id, option)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
              <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01">
                            <>
                            1 - Elementary Proficiency
                            </>
                          </p>
                          <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01">
                            <>
                            2 - Limited Working Proficiency
                            </>
                          </p>
                          <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01">
                            <>
                            3 - Professional Working Proficiency
                            </>
                          </p>
                          <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01">
                            <>
                            4 - Full Professional Proficiency
                            </>
                          </p>
                          <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01 mb-5">
                            <>
                            5 - Bilingual Proficiency
                            </>
                         </p>
                {error &&  <Alert severity="error">{error}</Alert>}

              </div>
          </div>
              
           ): currentStep === 4 ? (
            <div className="self-end mt-10 w-full flex items-center justify-center animate__animated animate__fadeInUp">
            <div className="flex flex-col w-full md:ml-0 md:w-full">
            <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                     
                        <p className="font-bold !text-blue_gray-700 text-3xl">
                        Education & Employment Background
                          </p>
                      </div>
          
               
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Highest level of education completed
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
            <select
                value={educationComplete}
                onChange={handleEducationChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select education complete</option>
                <option value="Noformaleducation">No formal education</option>
                <option value="SecondarySchoolDiploma">Secondary School Diploma (or equivalent) obtained outside of Canada</option>
                <option value="Post-SecondaryCertificate">Post-Secondary Certificate (non-university) obtained outside of Canada</option>
                <option value="Diploma">Diploma (University or College) obtained outside of Canada</option>
                <option value="Bachelor's">Bachelor's Degree obtained outside of Canada</option>
                <option value="Master">Master's Degree obtained outside of Canada</option>
                <option value="Doctorate">Doctorate (Ph.D.) obtained outside of Canada</option>
                <option value="Other">Other (specify their highest level of education, including education obtained outside of Canada)</option>
                <option value="PreferNotToSay">Prefer not to say</option>
            </select>
      </div>
                  </div>
             
              
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                     Work industry
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
            <select
                value={previousWorkIndustry}
                onChange={handleIndustryChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select Previous Work Industry</option>
                <option value="Agriculture">Agriculture, Forestry, Fishing & Hunting</option>
                <option value="Mining">Mining, Quarrying, and Oil & Gas Extraction</option>
                <option value="Utilities">Utilities (Electricity, Gas, Water)</option>
                <option value="construction">Construction</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Wholesale Trade">Wholesale Trade</option>
                <option value="Retail Trade">Retail Trade</option>
                <option value="Transportation & Warehousing">Transportation & Warehousing</option>
                <option value="ICT">Information & Communication Technology (ICT)</option>
                <option value="Finance & Insurance">Finance & Insurance</option>
                <option value="Real Estate & Rental & Leasing">Real Estate & Rental & Leasing</option>
                <option value="Professional, Scientific & Technical Services">Professional, Scientific & Technical Services</option>
                <option value="Administrative">Administrative & Support Services, Waste Management & Remediation Service</option>
                <option value="Educational Services">Educational Services</option>
                <option value="Healthcare & Social Assistance">Healthcare & Social Assistance</option>
                <option value="Arts, Entertainment & Recreation">Arts, Entertainment & Recreation</option>
                <option value="Accommodation & Food Service">Accommodation & Food Service</option>
                <option value="Other Services">Other Services (e.g., Personal & Laundry Services, Repair & Maintenance)</option>
            </select>
        </div>
                    </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                     Employment goal and aspiration
                    </label>
                    <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#E0E0E0] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                    <textarea
                    
                    onChange={handleAspirationsChange}
                    value={aspirations}
                                  placeholder="Type here..."
                                  className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#E0E0E0] font-hanken focus:outline-none text-[#858585] text-start"
                                />
                    </div>
                    
                  </div>
                  {error &&  <Alert severity="error">{error}</Alert>}
            </div>
        </div>
           ):currentStep === 5 ? (
            <div className="self-end mt-10 w-full flex items-center justify-center animate__animated animate__fadeInUp">
            <div className="flex flex-col w-full md:ml-0 md:w-full">
            <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                       
                        <p className="font-bold !text-blue_gray-700 text-3xl">
                        Current housing situatuion
                          </p>
                      </div>
          
               
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Highest housing situatuion
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={houseSituation}
                onChange={handleHouseSituationChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select house situation</option>
                <option value="renting">Renting an Apartment/Condo</option>
                <option value="family">Live with Family/Friends</option>
                <option value="student">Student Residence</option>
                <option value="other">Other (specify other house situation, including temporary housing options)</option>
                <option value="prefer_not_to_say">Prefer not to say</option>
            </select>
                  </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Housing Preference
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={housingPreference}
                onChange={handleHousingPreferenceChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select housing preference</option>
                <option value="renting">Renting an Apartment/Condo</option>
                <option value="shared">Shared Accommodation</option>
                <option value="student_residence">Student Residence</option>
                <option value="open_to_all">Open to all options</option>
                <option value="not_looking">Not looking to move</option>
                <option value="prefer_not_to_say">Prefer not to say</option>
            </select>
                  </div>
                  </div>
               
                  <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                       
                        <p className="font-bold !text-blue_gray-700 text-3xl">
                        Family Information
                          </p>
                      </div>
          
               
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Number of immediate family members currently with you 
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={familyNumber}
                onChange={handleFamilyNumberChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select family number</option>
                <option value="zero">0</option>
                <option value="one">1</option>
                <option value="two">2</option>
                <option value="three">3</option>
                <option value="four">4</option>
                <option value="five">5</option>
                <option value="six">6</option>
                <option value="seven">7</option>
                <option value="eight">8</option>
                <option value="nine">9</option>
                <option value="ten">10</option>
            </select>
                  </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                   Relationship to family
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={relationship}
                onChange={handleRelationshipChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select relationship</option>
                <option value="father">I am a Father</option>
                <option value="mother"> I am a Mother</option>
                <option value="son">I am Son</option>
                <option value="daughter">I am a Daughter</option>
                <option value="prefer_not_to_say">Prefer not to say</option>
            </select>
                  </div>
                  </div>
                  {error &&  <Alert severity="error">{error}</Alert>}
            </div>
        </div>
           ):currentStep === 6 ? (
            <div className="self-end mt-10 w-full flex items-center justify-center animate__animated animate__fadeInUp">
            <div className="flex flex-col w-full md:ml-0 md:w-full">
            <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                        
                        <p className="font-bold !text-blue_gray-700 text-3xl">
                        Social integration
                          </p>
                      </div>
          
               
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Favorite interest and hobbies
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={selectedHobby}
                onChange={handleHobbyChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select a hobby</option>
                <option value="Painting & Drawing">Painting & Drawing</option>
                <option value="Music">Music (playing an instrument, singing)</option>
                <option value="Photography">Photography</option>
                <option value="Writing & Poetry">Writing & Poetry</option>
                <option value="Crafts & DIY projects">Crafts & DIY projects</option>
                <option value="Film & Theatre">Film & Theatre</option>
                <option value="Dance">Dance</option>
                <option value="Hiking & Camping">Hiking & Camping</option>
                <option value="Cycling">Cycling</option>
                <option value="Running & Fitness">Running & Fitness</option>
                <option value="Team Sports">Team Sports (soccer, basketball, etc.)</option>
                <option value="Water Sports">Water Sports (swimming, kayaking, etc.)</option>
                <option value="Rock Climbing & Skiing">Rock Climbing & Skiing</option>
                <option value="Gardening">Gardening</option>
                <option value="Book Clubs & Reading Groups">Book Clubs & Reading Groups</option>
                <option value="Board Games & Game Nights">Board Games & Game Nights</option>
                <option value="Cooking Classes & Food Exploration">Cooking Classes & Food Exploration</option>
                <option value="Travel & Cultural Exchange">Travel & Cultural Exchange</option>
                <option value="Volunteering & Community Service">Volunteering & Community Service</option>
                <option value="Language Learning & Exchange">Language Learning & Exchange</option>
                <option value="Attending Events & Festivals">Attending Events & Festivals</option>
                <option value="Video Games & E-sports">Video Games & E-sports</option>
                <option value="Coding & Programming">Coding & Programming</option>
                <option value="Taking Online Courses & Workshops">Taking Online Courses & Workshops</option>
                <option value="Attending Lectures & Talks">Attending Lectures & Talks</option>
                <option value="Science & Technology Discussions">Science & Technology Discussions</option>
                <option value="Movie & TV Show Discussions">Movie & TV Show Discussions</option>
                <option value="Playing Chess & Strategy Games">Playing Chess & Strategy Games</option>
                <option value="Meditation & Mindfulness">Meditation & Mindfulness</option>
                <option value="Cooking & Baking">Cooking & Baking</option>
                <option value="Coffee & Tea Enthusiast">Coffee & Tea Enthusiast</option>
                <option value="Pets & Animal Lovers">Pets & Animal Lovers</option>
                <option value="Fashion & Style">Fashion & Style</option>
                <option value="Meditation & Yoga">Meditation & Yoga</option>
                <option value="Attending Concerts & Live Music">Attending Concerts & Live Music</option>
                <option value="Others">Others</option>
            </select>
                  </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                     Preferred activities for socializing and meeting new people
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={selectedActivity}
                onChange={handleActivityChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select a leisure activity</option>
                <option value="Hiking or biking trails">Hiking or biking trails</option>
                <option value="Group fitness classes">Group fitness classes (yoga, Zumba, HIIT)</option>
                <option value="Recreational sports leagues">Recreational sports leagues (volleyball, soccer, kickball)</option>
                <option value="Rock climbing gyms or bouldering centers">Rock climbing gyms or bouldering centers</option>
                <option value="Participating in outdoor adventure races or marathons">Participating in outdoor adventure races or marathons (with a "cheerleader" mentality)</option>
                <option value="Attending free fitness events">Attending free fitness events in parks or community centers</option>
                <option value="Taking a group art or pottery class">Taking a group art or pottery class</option>
                <option value="Joining a community choir or musical ensemble">Joining a community choir or musical ensemble</option>
                <option value="Attending local art walks or gallery openings">Attending local art walks or gallery openings</option>
                <option value="Participating in open mic nights or poetry slams">Participating in open mic nights or poetry slams</option>
                <option value="Volunteering at a community theatre or arts organization">Volunteering at a community theatre or arts organization</option>
                <option value="Taking a cooking class with friends">Taking a cooking class with friends or enrolling in a cooking club</option>
                <option value="Board game cafes or nights">Board game cafes or nights</option>
                <option value="Trivia nights at bars or restaurants">Trivia nights at bars or restaurants</option>
                <option value="Attending comedy shows or improv nights">Attending comedy shows or improv nights</option>
                <option value="Joining a local sports bar">Joining a local sports bar for game nights and cheering on teams</option>
                <option value="Volunteering at a music festival or cultural event">Volunteering at a music festival or cultural event</option>
                <option value="Participating in pub crawls or brewery tours">Participating in pub crawls or brewery tours</option>
                <option value="Attending food truck festivals or farmers markets">Attending food truck festivals or farmers markets</option>
                <option value="Joining a wine tasting club or brewery tour group">Joining a wine tasting club or brewery tour group</option>
                <option value="Participating in potlucks or themed dinner parties">Participating in potlucks or themed dinner parties</option>
                <option value="Taking a cooking class together">Taking a cooking class together and then enjoying the meal</option>
                <option value="Exploring new restaurants">Exploring new restaurants with friends and trying different cuisines</option>
                <option value="Attending happy hours">Attending happy hours or social gatherings at local bars</option>
                <option value="Book clubs with specific genre or theme focus">Book clubs with specific genre or theme focus</option>
                <option value="Taking a language learning course together">Taking a language learning course together</option>
                <option value="Attending lectures, workshops, or talks">Attending lectures, workshops, or talks on topics of interest</option>
                <option value="Joining a mastermind group or business networking event">Joining a mastermind group or business networking event</option>
                <option value="Participating in online courses or discussions">Participating in online courses or discussions with a social aspect</option>
                <option value="Attending museum or science center events">Attending museum or science center events with interactive exhibits</option>
                <option value="Joining a coffee club">Joining a coffee club or meeting friends at cafes</option>
                <option value="Attending outdoor movie screenings">Attending outdoor movie screenings or concerts in the park</option>
                <option value="Volunteering at an animal shelter">Volunteering at an animal shelter or dog park (great for dog lovers!)</option>
                <option value="Going for walks or bike rides with friends">Going for walks or bike rides with friends and exploring new areas</option>
                <option value="Hosting game nights">Hosting game nights or movie nights at your place</option>
                <option value="Joining a local club">Joining a local club dedicated to a specific hobby (chess, gardening, etc.)</option>
                <option value="Others">Others</option>
            </select>
                  </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Any specific cultural or religious considerations
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={selectedOption}
                onChange={handleReligiousOptionChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select specific cultural or religious considerations</option>
                <option value="Vegetarian">Food preferences (Select if Vegetarian or Vegan)</option>
                <option value="Yes">Open to trying new cuisines? (Select if Yes)</option>
                <option value="Important">Importance of religion in your life (Select if Important)</option>
                <option value="Yes">Do you celebrate religious holidays? (Select if Yes)</option>
                <option value="Direct">Preferred communication style (Select if you prefer Direct)</option>
            </select>
                  </div>
                  </div>
                  {error &&  <Alert severity="error">{error}</Alert>}
            </div>
        </div>
           ):currentStep === 7 ? (
            <div className="self-end mt-10 w-full flex items-center justify-center animate__animated animate__fadeInUp">
            <div className="flex flex-col w-full md:ml-0 md:w-full">
            <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                       
                        <p className="font-bold !text-blue_gray-700 text-3xl">
                        Social needs
                          </p>
                      </div>
          
               
                      <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Specific challenges or concerns related to immigration and settlement
                    </label>
                    <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#E0E0E0] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                    <textarea
                        value={immigrationConcerns}
                        onChange={handleImmigrationConcernsChange}
                                  placeholder="Type here..."
                                  className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#E0E0E0] font-hanken focus:outline-none text-[#858585] text-start"
                                />
                    </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Do you need any support services or resources?
                    </label>
                    <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#E0E0E0] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                    <textarea
                     value={supportServicesNeeded}
                     onChange={handleSupportServicesChange}
                                  placeholder="Type here..."
                                  className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#E0E0E0] font-hanken focus:outline-none text-[#858585] text-start"
                                />
                    </div>
                  </div>
                  {error &&  <Alert severity="error">{error}</Alert>}
            </div>
        </div>
           ) :currentStep === 8 ? (
            <div className="self-end mt-10 w-full flex flex-col items-center justify-center animate__animated animate__fadeInUp">
             
                 <div className="flex flex-col w-full md:ml-0 md:w-full">
                            
                              <p className="font-bold !text-blue_gray-700 text-3xl">
                              Review your information
                          </p>
                            </div>         
                   <div className="flex flex-col w-full md:ml-0 md:w-full">
                  <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                              
                              <p className="font-bold !text-blue_gray-700 text-base">
                              Personal information
                          </p>
                            </div>
                  <div className="flex sm:flex-col self-stretch gap-[9px]">
              
                          <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                          <div className="mb-4 w-full" >
                          <label className="mb-2.5 block font-medium  text-black ">
                            First Name
                          </label>
                          <TextField
      placeholder="First Name"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlefirsNameChange}
      value={firstname}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                          </div>
                         
                          <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                          <div className="mb-4 w-full" >
                          <label className="mb-2.5 block font-medium  text-black ">
                            Last Name
                          </label>
                          <TextField
      placeholder="Last Name"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlelastNameChange}
      value={lastname}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                          </div>
                        </div>
                     
                        <div className="mb-4 w-full" >
                          <label className="mb-2.5 block font-medium  text-black ">
                            Date Of Birth
                          </label>
                          <div className="relative w-full rounded border-[1.5px] border-[#E0E0E0] bg-[#E0E0E0] px-5 py-4">
                          <Flatpickr
          value={dateOfBirth}
          onChange={(date) => setDateOfBirth(date[0].toISOString().split('T')[0])}
          options={{
            dateFormat: 'Y-m-d',
            enableTime: false,
            altInput: true,
            altFormat: 'Y-m-d',
            time_24hr: true,
            allowInput: true,
          }}
          className="w-full bg-transparent"
        />


        <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.0002 12.8249C8.83145 12.8249 8.69082 12.7687 8.5502 12.6562L2.08145 6.2999C1.82832 6.04678 1.82832 5.65303 2.08145 5.3999C2.33457 5.14678 2.72832 5.14678 2.98145 5.3999L9.0002 11.278L15.0189 5.34365C15.2721 5.09053 15.6658 5.09053 15.9189 5.34365C16.1721 5.59678 16.1721 5.99053 15.9189 6.24365L9.45019 12.5999C9.30957 12.7405 9.16895 12.8249 9.0002 12.8249Z"
              fill="#64748B"
            />
          </svg>
        </div>
      </div>
                        </div>
                    <div className="mb-4 w-full" >
                  <label className="mb-2.5 block font-medium  text-black ">
                    Email
                  </label>
                  <TextField
      placeholder="Email"
      type="email"
      className='w-full self-stretch flex'
      onChange={handleEmailChange}
      value={email}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />

                </div>
                <div className="mb-4 w-full" >
                <label className="mb-2.5 block font-medium  text-black ">
                 Gender
                </label>
                <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
    <select  value={gender} onChange={handleGenderChange} className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
      <option value="">Select gender</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
      <option value="x">X</option>
      <option value="Others">others</option>
    </select>
  </div>
              </div>
                        <div className="mb-6 w-full">
                          <label className="mb-2.5 block font-medium  text-black ">
                            Phone 
                          </label>
                          <TextField
      placeholder="Phone number"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlePhoneChange}
      value={phone}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#E0E0E0', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                <div className="mb-6 w-full">
                  <label className="mb-2.5 block font-medium  text-black ">
                    Password 
                  </label>
                  <TextField
            placeholder="Password"
            type={showPassword ? 'text' : 'password'} // Show/hide password based on state
            value={password}
            onChange={handlePasswordChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#E0E0E0', // Set background color
                '& fieldset': {
                  borderColor: '#E0E0E0', // Set border color
                },
                '&:hover fieldset': {
                  borderColor: '#E0E0E0', // Set border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#E0E0E0', // Set border color on focus
                },
              },
             
              '& .MuiInputBase-root': {
                marginTop: 0, // Remove top padding
                marginBottom: 0, // Remove bottom padding
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Remove outline by default
              },
            }}
            fullWidth
            margin="normal"
            variant="outlined"
            required

            InputProps={{
              // Show/hide password icon button
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
               
             
                </div>
                <div className="mb-6 w-full">
                  <label className="mb-2.5 block font-medium  text-black ">
                   Confirm Password 
                  </label>
                  <TextField
            placeholder="Confirm Password"
            type={showPassword ? 'text' : 'password'} // Show/hide password based on state
            value={confirmpassword}
            onChange={handleConfirmChange}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: '#E0E0E0', // Set background color
                '& fieldset': {
                  borderColor: '#E0E0E0', // Set border color
                },
                '&:hover fieldset': {
                  borderColor: '#E0E0E0', // Set border color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#E0E0E0', // Set border color on focus
                },
              },
             
              '& .MuiInputBase-root': {
                marginTop: 0, // Remove top padding
                marginBottom: 0, // Remove bottom padding
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: 'transparent', // Remove outline by default
              },
            }}
            fullWidth
            margin="normal"
            variant="outlined"
            required

            InputProps={{
              // Show/hide password icon button
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
               
             
                </div>
                {error &&  <Alert severity="error">{error}</Alert>}
   
                   </div>
                  <div className="flex flex-col w-full">
                  <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                          
                          <p className="font-bold !text-blue_gray-700 text-base">
                          Basic Immigration Details
                          </p>
                        </div>
            
                 
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                        Country of origin
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select value={countryOfOrigin} onChange={handleCountryofOriginChange}  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
       
          <option value="">Select country</option>
<option value="Afghanistan">Afghanistan</option>
<option value="Albania">Albania</option>
<option value="Algeria">Algeria</option>
<option value="Andorra">Andorra</option>
<option value="Angola">Angola</option>
<option value="Antigua and Barbuda">Antigua and Barbuda</option>
<option value="Argentina">Argentina</option>
<option value="Armenia">Armenia</option>
<option value="Australia">Australia</option>
<option value="Austria">Austria</option>
<option value="Azerbaijan">Azerbaijan</option>
<option value="Bahamas">Bahamas</option>
<option value="Bahrain">Bahrain</option>
<option value="Bangladesh">Bangladesh</option>
<option value="Barbados">Barbados</option>
<option value="Belarus">Belarus</option>
<option value="Belgium">Belgium</option>
<option value="Belize">Belize</option>
<option value="Benin">Benin</option>
<option value="Bhutan">Bhutan</option>
<option value="Bolivia">Bolivia</option>
<option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
<option value="Botswana">Botswana</option>
<option value="Brazil">Brazil</option>
<option value="Brunei">Brunei</option>
<option value="Bulgaria">Bulgaria</option>
<option value="Burkina Faso">Burkina Faso</option>
<option value="Burundi">Burundi</option>
<option value="Cabo Verde">Cabo Verde</option>
<option value="Cambodia">Cambodia</option>
<option value="Cameroon">Cameroon</option>
<option value="Canada">Canada</option>
<option value="Central African Republic">Central African Republic</option>
<option value="Chad">Chad</option>
<option value="Chile">Chile</option>
<option value="China">China</option>
<option value="Colombia">Colombia</option>
<option value="Comoros">Comoros</option>
<option value="Congo">Congo</option>
<option value="Costa Rica">Costa Rica</option>
<option value="Croatia">Croatia</option>
<option value="Cuba">Cuba</option>
<option value="Cyprus">Cyprus</option>
<option value="Czech Republic">Czech Republic</option>
<option value="Denmark">Denmark</option>
<option value="Djibouti">Djibouti</option>
<option value="Dominica">Dominica</option>
<option value="Dominican Republic">Dominican Republic</option>
<option value="East Timor">East Timor</option>
<option value="Ecuador">Ecuador</option>
<option value="Egypt">Egypt</option>
<option value="El Salvador">El Salvador</option>
<option value="Equatorial Guinea">Equatorial Guinea</option>
<option value="Eritrea">Eritrea</option>
<option value="Estonia">Estonia</option>
<option value="Eswatini">Eswatini</option>
<option value="Ethiopia">Ethiopia</option>
<option value="Fiji">Fiji</option>
<option value="Finland">Finland</option>
<option value="France">France</option>
<option value="Gabon">Gabon</option>
<option value="Gambia">Gambia</option>
<option value="Georgia">Georgia</option>
<option value="Germany">Germany</option>
<option value="Ghana">Ghana</option>
<option value="Greece">Greece</option>
<option value="Grenada">Grenada</option>
<option value="Guatemala">Guatemala</option>
<option value="Guinea">Guinea</option>
<option value="Guinea-Bissau">Guinea-Bissau</option>
<option value="Guyana">Guyana</option>
<option value="Haiti">Haiti</option>
<option value="Honduras">Honduras</option>
<option value="Hungary">Hungary</option>
<option value="Iceland">Iceland</option>
<option value="India">India</option>
<option value="Indonesia">Indonesia</option>
<option value="Iran">Iran</option>
<option value="Iraq">Iraq</option>
<option value="Ireland">Ireland</option>
<option value="Israel">Israel</option>
<option value="Italy">Italy</option>
<option value="Jamaica">Jamaica</option>
<option value="Japan">Japan</option>
<option value="Jordan">Jordan</option>
<option value="Kazakhstan">Kazakhstan</option>
<option value="Kenya">Kenya</option>
<option value="Kiribati">Kiribati</option>
<option value="Korea, North">Korea, North</option>
<option value="Korea, South">Korea, South</option>
<option value="Kosovo">Kosovo</option>
<option value="Kuwait">Kuwait</option>
<option value="Kyrgyzstan">Kyrgyzstan</option>
<option value="Laos">Laos</option>
<option value="Latvia">Latvia</option>
<option value="Lebanon">Lebanon</option>
<option value="Lesotho">Lesotho</option>
<option value="Liberia">Liberia</option>
<option value="Libya">Libya</option>
<option value="Liechtenstein">Liechtenstein</option>
<option value="Lithuania">Lithuania</option>
<option value="Luxembourg">Luxembourg</option>
<option value="Madagascar">Madagascar</option>
<option value="Malawi">Malawi</option>
<option value="Malaysia">Malaysia</option>
<option value="Maldives">Maldives</option>
<option value="Mali">Mali</option>
<option value="Malta">Malta</option>
<option value="Marshall Islands">Marshall Islands</option>
<option value="Mauritania">Mauritania</option>
<option value="Mauritius">Mauritius</option>
<option value="Mexico">Mexico</option>
<option value="Micronesia">Micronesia</option>
<option value="Moldova">Moldova</option>
<option value="Monaco">Monaco</option>
<option value="Mongolia">Mongolia</option>
<option value="Montenegro">Montenegro</option>
<option value="Morocco">Morocco</option>
<option value="Mozambique">Mozambique</option>
<option value="Myanmar">Myanmar</option>
<option value="Namibia">Namibia</option>
<option value="Nauru">Nauru</option>
<option value="Nepal">Nepal</option>
<option value="Netherlands">Netherlands</option>
<option value="New Zealand">New Zealand</option>
<option value="Nicaragua">Nicaragua</option>
<option value="Niger">Niger</option>
<option value="Nigeria">Nigeria</option>
<option value="North Macedonia">North Macedonia</option>
<option value="Norway">Norway</option>
<option value="Oman">Oman</option>
<option value="Pakistan">Pakistan</option>
<option value="Palau">Palau</option>
<option value="Palestine">Palestine</option>
<option value="Panama">Panama</option>
<option value="Papua New Guinea">Papua New Guinea</option>
<option value="Paraguay">Paraguay</option>
<option value="Peru">Peru</option>
<option value="Philippines">Philippines</option>
<option value="Poland">Poland</option>
<option value="Portugal">Portugal</option>
<option value="Qatar">Qatar</option>
<option value="Romania">Romania</option>
<option value="Russia">Russia</option>
<option value="Rwanda">Rwanda</option>
<option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
<option value="Saint Lucia">Saint Lucia</option>
<option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
<option value="Samoa">Samoa</option>
<option value="San Marino">San Marino</option>
<option value="Sao Tome and Principe">Sao Tome and Principe</option>
<option value="Saudi Arabia">Saudi Arabia</option>
<option value="Senegal">Senegal</option>
<option value="Serbia">Serbia</option>
<option value="Seychelles">Seychelles</option>
<option value="Sierra Leone">Sierra Leone</option>
<option value="Singapore">Singapore</option>
<option value="Slovakia">Slovakia</option>
<option value="Slovenia">Slovenia</option>
<option value="Solomon Islands">Solomon Islands</option>
<option value="Somalia">Somalia</option>
<option value="South Africa">South Africa</option>
<option value="South Sudan">South Sudan</option>
<option value="Spain">Spain</option>
<option value="Sri Lanka">Sri Lanka</option>
<option value="Sudan">Sudan</option>
<option value="Suriname">Suriname</option>
<option value="Sweden">Sweden</option>
<option value="Switzerland">Switzerland</option>
<option value="Syria">Syria</option>
<option value="Taiwan">Taiwan</option>
<option value="Tajikistan">Tajikistan</option>
<option value="Tanzania">Tanzania</option>
<option value="Thailand">Thailand</option>
<option value="Togo">Togo</option>
<option value="Tonga">Tonga</option>
<option value="Trinidad and Tobago">Trinidad and Tobago</option>
<option value="Tunisia">Tunisia</option>
<option value="Turkey">Turkey</option>
<option value="Turkmenistan">Turkmenistan</option>
<option value="Tuvalu">Tuvalu</option>
<option value="Uganda">Uganda</option>
<option value="Ukraine">Ukraine</option>
<option value="United Arab Emirates">United Arab Emirates</option>
<option value="United Kingdom">United Kingdom</option>
<option value="United States">United States</option>
<option value="Uruguay">Uruguay</option>
<option value="Uzbekistan">Uzbekistan</option>
<option value="Vanuatu">Vanuatu</option>
<option value="Vatican City">Vatican City</option>
<option value="Venezuela">Venezuela</option>
<option value="Vietnam">Vietnam</option>
<option value="Yemen">Yemen</option>
<option value="Zambia">Zambia</option>
<option value="Zimbabwe">Zimbabwe</option>
</select>

        </div>
                    </div>
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                        Current immigaration status
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select value={currentImmigrationStatus} onChange={handlelCurrentImigratStatusChange}   className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
            <option value="">Select status</option>
            <option value="Permanent Resident">Permanent Resident</option>
            <option value="Temporary Resident holder">Temporary Resident Permit Holder</option>
            <option value="None">Prefer not to say</option>
          </select>
        </div>
                    </div>
                    <div className="mb-6 w-full">
                      <label className="mb-2.5 block font-medium  text-black ">
                        Date of immigration 
                      </label>
                      <div className="relative w-full rounded border-[1.5px] border-[#E0E0E0] bg-[#E0E0E0] px-5 py-4">
                      <Flatpickr
          value={immigrationDate}
          onChange={(date) => setimmigrationDate(date[0].toISOString().split('T')[0])}
          options={{
            dateFormat: 'Y-m-d',
            enableTime: false,
            altInput: true,
            altFormat: 'Y-m-d',
            time_24hr: true,
            allowInput: true,
          }}
          className="w-full bg-transparent"
        />

        <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center">
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.0002 12.8249C8.83145 12.8249 8.69082 12.7687 8.5502 12.6562L2.08145 6.2999C1.82832 6.04678 1.82832 5.65303 2.08145 5.3999C2.33457 5.14678 2.72832 5.14678 2.98145 5.3999L9.0002 11.278L15.0189 5.34365C15.2721 5.09053 15.6658 5.09053 15.9189 5.34365C16.1721 5.59678 16.1721 5.99053 15.9189 6.24365L9.45019 12.5999C9.30957 12.7405 9.16895 12.8249 9.0002 12.8249Z"
              fill="#64748B"
            />
          </svg>
        </div>
      </div>
             
                    </div>
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                       Visa type
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select  value={visaType} onChange={handleVisaTypeChange}  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
            <option value="">Select visa type</option>
            <option value="WorkPermit">Work Permit</option>
            <option value="StudyPermit">Study Permit</option>
            <option value="Other">others</option>
            <option value="None">Prefer not to say</option>
          </select>
        </div>
                    </div>
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                       Type of status
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select  value={typeOfStatus} onChange={handleTypeOfStatusChange}  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
            <option value="">Select Status</option>
            <option value="Skilled Worker">Skilled Worker</option>
            <option value="familySponsorship">Family Sponsorship</option>
            <option value="Other">others</option>
            <option value="None">Prefer not to say</option>
          </select>
        </div>
                    </div>
                    {error &&  <Alert severity="error">{error}</Alert>}
                  </div>
                  <div className="flex flex-col w-full md:ml-0 md:w-full">
              <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                        
                          <p className="font-bold !text-blue_gray-700 text-base">
                          Language Proficiency
                          </p>
                        </div>
            
                 
                    <div className="mb-4 w-full" >
                      <label className="mb-2.5 block font-medium  text-black ">
                        Native Langauge
                      </label>
                      <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
          <select value={nativeLanguage} onChange={handleNativeLanguageChange}  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
            <option value="">Select Language</option>
            <option value="English">English</option>
            
          </select>
        </div>
                    </div>
               
                    <table className="mt-2 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2 rounded-md w-full">
        <thead>
          <tr>
            <th className="min-w-[20px] px-2 py-2 font-medium border border-gray-500"></th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">1</th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">2</th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">3</th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">4</th>
            <th className="min-w-[10px] px-2 py-2 font-medium border border-gray-500 text-base">5</th>
          </tr>
        </thead>
        <tbody>
          {tableRows.map(row => (
            <tr key={row.id}>
              <td className="border border-gray-500 text-base" style={{ textAlign: 'center' }}>{row.title}</td>
              {['option1', 'option2', 'option3', 'option4', 'option5'].map(option => (
                <td className="border border-gray-500 px-2 py-2" style={{ textAlign: 'center' }} key={option}>
                  <input
                    type="radio"
                    name={`row${row.id}`}
                    value={option}
                    checked={row.selectedOption === option}
                    onChange={() => handleOptionChange(row.id, option)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
                    <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01">
                            <>
                            1 - Elementary Proficiency
                            </>
                          </p>
                          <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01">
                            <>
                            2 - Limited Working Proficiency
                            </>
                          </p>
                          <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01">
                            <>
                            3 - Professional Working Proficiency
                            </>
                          </p>
                          <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01">
                            <>
                            4 - Full Professional Proficiency
                            </>
                          </p>
                          <p   className="w-full !font-kumbhsans font-normal text-base !text-blue_gray-700_01 mb-5">
                            <>
                            5 - Bilingual Proficiency
                            </>
                         </p>
                {error &&  <Alert severity="error">{error}</Alert>}

                  </div>
                  <div className="flex flex-col w-full md:ml-0 md:w-full">
            <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                        
                        <p className="font-bold !text-blue_gray-700 text-base">
                        Education & Employment Background
                          </p>
                      </div>
          
               
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Highest level of education completed
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
            <select
                value={educationComplete}
                onChange={handleEducationChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select education complete</option>
                <option value="Noformaleducation">No formal education</option>
                <option value="SecondarySchoolDiploma">Secondary School Diploma (or equivalent) obtained outside of Canada</option>
                <option value="Post-SecondaryCertificate">Post-Secondary Certificate (non-university) obtained outside of Canada</option>
                <option value="Diploma">Diploma (University or College) obtained outside of Canada</option>
                <option value="Bachelor's">Bachelor's Degree obtained outside of Canada</option>
                <option value="Master">Master's Degree obtained outside of Canada</option>
                <option value="Doctorate">Doctorate (Ph.D.) obtained outside of Canada</option>
                <option value="Other">Other (specify their highest level of education, including education obtained outside of Canada)</option>
                <option value="PreferNotToSay">Prefer not to say</option>
            </select>
      </div>
                  </div>
             
              
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                     Work industry
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
            <select
                value={previousWorkIndustry}
                onChange={handleIndustryChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select Previous Work Industry</option>
                <option value="Agriculture">Agriculture, Forestry, Fishing & Hunting</option>
                <option value="Mining">Mining, Quarrying, and Oil & Gas Extraction</option>
                <option value="Utilities">Utilities (Electricity, Gas, Water)</option>
                <option value="construction">Construction</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Wholesale Trade">Wholesale Trade</option>
                <option value="Retail Trade">Retail Trade</option>
                <option value="Transportation & Warehousing">Transportation & Warehousing</option>
                <option value="ICT">Information & Communication Technology (ICT)</option>
                <option value="Finance & Insurance">Finance & Insurance</option>
                <option value="Real Estate & Rental & Leasing">Real Estate & Rental & Leasing</option>
                <option value="Professional, Scientific & Technical Services">Professional, Scientific & Technical Services</option>
                <option value="Administrative">Administrative & Support Services, Waste Management & Remediation Service</option>
                <option value="Educational Services">Educational Services</option>
                <option value="Healthcare & Social Assistance">Healthcare & Social Assistance</option>
                <option value="Arts, Entertainment & Recreation">Arts, Entertainment & Recreation</option>
                <option value="Accommodation & Food Service">Accommodation & Food Service</option>
                <option value="Other Services">Other Services (e.g., Personal & Laundry Services, Repair & Maintenance)</option>
            </select>
        </div>
                    </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                     Employment goal and aspiration
                    </label>
                    <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#E0E0E0] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                    <textarea
                      onChange={handleAspirationsChange}
                      value={aspirations}
                                  placeholder="Type here..."
                                  className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#E0E0E0] font-hanken focus:outline-none text-[#858585] text-start"
                                />
                    </div>
                    
                  </div>
                  </div>
                  <div className="flex flex-col w-full md:ml-0 md:w-full">
            <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                      
                        <p className="font-bold !text-blue_gray-700 text-base">
                        Current housing situatuion
                          </p>
                      </div>
          
               
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Highest housing situatuion
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={houseSituation}
                onChange={handleHouseSituationChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select house situation</option>
                <option value="renting">Renting an Apartment/Condo</option>
                <option value="family">Live with Family/Friends</option>
                <option value="student">Student Residence</option>
                <option value="other">Other (specify other house situation, including temporary housing options)</option>
                <option value="prefer_not_to_say">Prefer not to say</option>
            </select>
                  </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Housing Preference
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={housingPreference}
                onChange={handleHousingPreferenceChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select housing preference</option>
                <option value="renting">Renting an Apartment/Condo</option>
                <option value="shared">Shared Accommodation</option>
                <option value="student_residence">Student Residence</option>
                <option value="open_to_all">Open to all options</option>
                <option value="not_looking">Not looking to move</option>
                <option value="prefer_not_to_say">Prefer not to say</option>
            </select>
                  </div>
                  </div>
               
                  <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                       
                        <p className="font-bold !text-blue_gray-700 text-base">
                        Family Information
                          </p>
                      </div>
          
               
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                     Number of immediate family members currently with you
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={familyNumber}
                onChange={handleFamilyNumberChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select family number</option>
                <option value="zero">0</option>
                <option value="one">1</option>
                <option value="two">2</option>
                <option value="three">3</option>
                <option value="four">4</option>
                <option value="five">5</option>
                <option value="six">6</option>
                <option value="seven">7</option>
                <option value="eight">8</option>
                <option value="nine">9</option>
                <option value="ten">10</option>
            </select>
                  </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                   Relationship to family
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={relationship}
                onChange={handleRelationshipChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                   <option value="">Select relationship</option>
                <option value="father">I am a Father</option>
                <option value="mother"> I am a Mother</option>
                <option value="son">I am Son</option>
                <option value="daughter">I am a Daughter</option>
                <option value="prefer_not_to_say">Prefer not to say</option>
            </select>
                  </div>
                  </div>
                  </div>
                  <div className="flex flex-col w-full md:ml-0 md:w-full">
            <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                       
                        <p className="font-bold !text-blue_gray-700 text-base">
                        Social integration
                          </p>
                      </div>
          
               
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Favorite interest and hobbies
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={selectedHobby}
                onChange={handleHobbyChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select a hobby</option>
                <option value="Painting & Drawing">Painting & Drawing</option>
                <option value="Music">Music (playing an instrument, singing)</option>
                <option value="Photography">Photography</option>
                <option value="Writing & Poetry">Writing & Poetry</option>
                <option value="Crafts & DIY projects">Crafts & DIY projects</option>
                <option value="Film & Theatre">Film & Theatre</option>
                <option value="Dance">Dance</option>
                <option value="Hiking & Camping">Hiking & Camping</option>
                <option value="Cycling">Cycling</option>
                <option value="Running & Fitness">Running & Fitness</option>
                <option value="Team Sports">Team Sports (soccer, basketball, etc.)</option>
                <option value="Water Sports">Water Sports (swimming, kayaking, etc.)</option>
                <option value="Rock Climbing & Skiing">Rock Climbing & Skiing</option>
                <option value="Gardening">Gardening</option>
                <option value="Book Clubs & Reading Groups">Book Clubs & Reading Groups</option>
                <option value="Board Games & Game Nights">Board Games & Game Nights</option>
                <option value="Cooking Classes & Food Exploration">Cooking Classes & Food Exploration</option>
                <option value="Travel & Cultural Exchange">Travel & Cultural Exchange</option>
                <option value="Volunteering & Community Service">Volunteering & Community Service</option>
                <option value="Language Learning & Exchange">Language Learning & Exchange</option>
                <option value="Attending Events & Festivals">Attending Events & Festivals</option>
                <option value="Video Games & E-sports">Video Games & E-sports</option>
                <option value="Coding & Programming">Coding & Programming</option>
                <option value="Taking Online Courses & Workshops">Taking Online Courses & Workshops</option>
                <option value="Attending Lectures & Talks">Attending Lectures & Talks</option>
                <option value="Science & Technology Discussions">Science & Technology Discussions</option>
                <option value="Movie & TV Show Discussions">Movie & TV Show Discussions</option>
                <option value="Playing Chess & Strategy Games">Playing Chess & Strategy Games</option>
                <option value="Meditation & Mindfulness">Meditation & Mindfulness</option>
                <option value="Cooking & Baking">Cooking & Baking</option>
                <option value="Coffee & Tea Enthusiast">Coffee & Tea Enthusiast</option>
                <option value="Pets & Animal Lovers">Pets & Animal Lovers</option>
                <option value="Fashion & Style">Fashion & Style</option>
                <option value="Meditation & Yoga">Meditation & Yoga</option>
                <option value="Attending Concerts & Live Music">Attending Concerts & Live Music</option>
                <option value="Others">Others</option>
            </select>
                  </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                     Preferred activities for socializing and meeting new people
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={selectedActivity}
                onChange={handleActivityChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select a leisure activity</option>
                <option value="Hiking or biking trails">Hiking or biking trails</option>
                <option value="Group fitness classes">Group fitness classes (yoga, Zumba, HIIT)</option>
                <option value="Recreational sports leagues">Recreational sports leagues (volleyball, soccer, kickball)</option>
                <option value="Rock climbing gyms or bouldering centers">Rock climbing gyms or bouldering centers</option>
                <option value="Participating in outdoor adventure races or marathons">Participating in outdoor adventure races or marathons (with a "cheerleader" mentality)</option>
                <option value="Attending free fitness events">Attending free fitness events in parks or community centers</option>
                <option value="Taking a group art or pottery class">Taking a group art or pottery class</option>
                <option value="Joining a community choir or musical ensemble">Joining a community choir or musical ensemble</option>
                <option value="Attending local art walks or gallery openings">Attending local art walks or gallery openings</option>
                <option value="Participating in open mic nights or poetry slams">Participating in open mic nights or poetry slams</option>
                <option value="Volunteering at a community theatre or arts organization">Volunteering at a community theatre or arts organization</option>
                <option value="Taking a cooking class with friends">Taking a cooking class with friends or enrolling in a cooking club</option>
                <option value="Board game cafes or nights">Board game cafes or nights</option>
                <option value="Trivia nights at bars or restaurants">Trivia nights at bars or restaurants</option>
                <option value="Attending comedy shows or improv nights">Attending comedy shows or improv nights</option>
                <option value="Joining a local sports bar">Joining a local sports bar for game nights and cheering on teams</option>
                <option value="Volunteering at a music festival or cultural event">Volunteering at a music festival or cultural event</option>
                <option value="Participating in pub crawls or brewery tours">Participating in pub crawls or brewery tours</option>
                <option value="Attending food truck festivals or farmers markets">Attending food truck festivals or farmers markets</option>
                <option value="Joining a wine tasting club or brewery tour group">Joining a wine tasting club or brewery tour group</option>
                <option value="Participating in potlucks or themed dinner parties">Participating in potlucks or themed dinner parties</option>
                <option value="Taking a cooking class together">Taking a cooking class together and then enjoying the meal</option>
                <option value="Exploring new restaurants">Exploring new restaurants with friends and trying different cuisines</option>
                <option value="Attending happy hours">Attending happy hours or social gatherings at local bars</option>
                <option value="Book clubs with specific genre or theme focus">Book clubs with specific genre or theme focus</option>
                <option value="Taking a language learning course together">Taking a language learning course together</option>
                <option value="Attending lectures, workshops, or talks">Attending lectures, workshops, or talks on topics of interest</option>
                <option value="Joining a mastermind group or business networking event">Joining a mastermind group or business networking event</option>
                <option value="Participating in online courses or discussions">Participating in online courses or discussions with a social aspect</option>
                <option value="Attending museum or science center events">Attending museum or science center events with interactive exhibits</option>
                <option value="Joining a coffee club">Joining a coffee club or meeting friends at cafes</option>
                <option value="Attending outdoor movie screenings">Attending outdoor movie screenings or concerts in the park</option>
                <option value="Volunteering at an animal shelter">Volunteering at an animal shelter or dog park (great for dog lovers!)</option>
                <option value="Going for walks or bike rides with friends">Going for walks or bike rides with friends and exploring new areas</option>
                <option value="Hosting game nights">Hosting game nights or movie nights at your place</option>
                <option value="Joining a local club">Joining a local club dedicated to a specific hobby (chess, gardening, etc.)</option>
                <option value="Others">Others</option>
            </select>
                  </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Any specific cultural or religious considerations
                    </label>
                    <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#E0E0E0] mb-5 gap-2  rounded-md w-full">
                    <select
                value={selectedOption}
                onChange={handleReligiousOptionChange}
                className="h-8 text-gray-600 text-base font-hankengrotesk w-full"
            >
                <option value="">Select specific cultural or religious considerations</option>
                <option value="Vegetarian">Food preferences (Select if Vegetarian or Vegan)</option>
                <option value="Yes">Open to trying new cuisines? (Select if Yes)</option>
                <option value="Important">Importance of religion in your life (Select if Important)</option>
                <option value="Yes">Do you celebrate religious holidays? (Select if Yes)</option>
                <option value="Direct">Preferred communication style (Select if you prefer Direct)</option>
            </select>
                  </div>
                  </div>
             
                  </div>
                 <div className="flex flex-col w-full md:ml-0 md:w-full">
            <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                       
                        <p className="font-bold !text-blue_gray-700 text-base">
                        Social needs
                          </p>
                      </div>
          
               
                      <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Specific challenges or concerns related to immigration and settlement
                    </label>
                    <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#E0E0E0] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                    <textarea
                        value={immigrationConcerns}
                        onChange={handleImmigrationConcernsChange}
                                  placeholder="Type here..."
                                  className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#E0E0E0] font-hanken focus:outline-none text-[#858585] text-start"
                                />
                    </div>
                  </div>
                  <div className="mb-4 w-full" >
                    <label className="mb-2.5 block font-medium  text-black ">
                    Do you need any support services or resources?
                    </label>
                    <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#E0E0E0] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                    <textarea
                     value={supportServicesNeeded}
                     onChange={handleSupportServicesChange}
                                  placeholder="Type here..."
                                  className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#E0E0E0] font-hanken focus:outline-none text-[#858585] text-start"
                                />
                    </div>
                  </div>
                </div>
        </div>
           ) : (
                <></>
            )}
        </>
      </div>
       {currentStep  === 1 &&  <div className="flex flex-col items-center w-full">
      <div className="flex items-center  w-[40%] md:w-full">
        <input
          type="checkbox"
          id="agree"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="w-4 h-4 mr-2"
        />
       <label htmlFor="agree" className="text-sm md:text-xs text-gray-700 flex flex-wrap gap-2">

  <NavLink to="/privacy-policy">
  <label htmlFor="agree" className="text-sm md:text-xs text-gray-700 flex flex-wrap gap-2">
  {"I agree to the "}

  {"  Privacy Policy  "}
  {" and give my permission to process "}
  {" my personal data for the purpose "}
  {" specified in this Privacy Policy."}
</label>
  </NavLink> 
  
</label>

      </div>
     
    </div>}
      <div className="my-10 w-[40%] md:w-full flex justify-between ">
      {currentStep  === 1 && <button className="" ></button>}
      {currentStep !== 1 && <button   onClick={(e) => { e.preventDefault(); handlePrev();}} >Previous</button>}
      {currentStep !== 8 ? (
        <>
         {isChecked && (
         <button  onClick={(e) => { e.preventDefault(); handleNext();}}  className="justify-center self-end px-11 py-2 mt-10 text-base font-semibold text-black whitespace-nowrap border-2 border-solid border-neutral-500 rounded-[31.5px] md:px-5">
                Next
              </button>
      )}
        </>
         
         
      ) : (
        <>
        {loading ? (
          <button   className="justify-center self-end px-11 py-2 mt-10 text-base font-semibold text-black whitespace-nowrap border-2 border-solid border-neutral-500 rounded-[31.5px] md:px-5" disabled={loading}>
         <CgSpinner className=" animate-spin text-lg items-center " />
          </button>
         ) : (
           <button type="submit"   className="justify-center self-end px-11 py-2 mt-10 text-base font-semibold text-black whitespace-nowrap border-2 border-solid border-neutral-500 rounded-[31.5px] md:px-5" disabled={loading}>
                Finish
                 </button>
         )}
     
       </>
      )}
     
      </div>
      </form>


     </div>
     
     </div>
<ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </div>
   
    </>
  );
}


