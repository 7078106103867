import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./styles/tailwind.css";
import "./styles/index.css";
import "./styles/font.css";
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'flatpickr/dist/flatpickr.min.css';
import "react-datepicker/dist/react-datepicker.css";
import 'animate.css/animate.min.css';




const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App />);
