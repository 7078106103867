// App.tsx
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./Routes";
import ScrollToTop from "components/ScrollToTop";
import { FeedbackProvider } from "services/FeedbackContext";


function App() {
  return (
    <FeedbackProvider>
      <Router>
        <ScrollToTop />
        <Routes />
      </Router>
    </FeedbackProvider>
  );
}

export default App;
