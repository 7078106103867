// FeedbackDialog.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface FeedbackDialogProps {
  onClose: () => void;
}

const FeedbackDialog: React.FC<FeedbackDialogProps> = ({ onClose }) => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    onClose();
    navigate('/feedback'); // Adjust this path if your feedback page is located elsewhere
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black-900 bg-opacity-50 px-10 z-50">
      
      <div className="bg-white-A700 p-4 rounded-md shadow-md w-8/12 md:w-full">
        <div className='flex flex-row md:flex-col gap-4'>
        <div className='w-[40%] md:w-full'>
        <img
                loading="lazy"
                src="/images/img-machings.svg"
                className="mt-7 w-full object-cover"
              />
        </div>
        <div className='w-[60%] md:w-full flex  flex-col items-start justify-center'>
        <p className="text-base  md:text-sm font-bold mb-1">Our goal is to help you settle in Canada quicker and faster leveraging AI as well as other resources and partnerships</p>
        <p className="mb-1 md:text-sm"> Your feedback helps us sharpen our processes for a more efficient system.</p>

        </div>
        </div>
     
        
        <div className="mt-4 flex justify-end md:justify-center">
          <button
            className="mr-2 px-4 py-2 bg-gray-300 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-blue-500 text-white-A700 rounded-md"
            onClick={handleRedirect}
          >
            Give Feedback
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeedbackDialog;
