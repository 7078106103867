import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Input, Img, Text, Heading, Button } from "../../../components";
import { NavLink, useNavigate } from "react-router-dom";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import ImageSlider from "components/ImageSlider";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { toast, Bounce, ToastContainer } from "react-toastify";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import axios from "axios";
import { SmallYouTubeVideo, YouTubeVideo } from "components/VideoPlayer";
import AuthService from "services/authService";

interface Page1Props {
  togglePage: () => void;
}

function Page1({ togglePage }: Page1Props){
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const response = await axios.post('https://linked-origin-server.vercel.app/api/v1/subscription/newsletter/subscribe', {
          email,
        });
    
        if (response.status === 200 || response.status === 201) {
          setMessage('Subscription successful!');
          toast.success('Email subscription sent successfully', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else if (response.status === 409) {
          setMessage('Email already registered');
          toast.warn('Email already registered', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          setMessage('Subscription failed. Please try again.');
          toast.error('Subscription failed. Please try again.', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } catch (error) {
        if (error.response && error.response.status === 409) {
          setMessage('Email already registered');
          toast.warn('Email already registered', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } else {
          console.error('There was an error subscribing to the newsletter!', error);
          setMessage('Subscription failed. Please try again.');
          toast.error('There was an error subscribing to the newsletter!', {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      } finally {
        setLoading(false);
      }
    };
    
    useEffect(() => {
      // Check local storage for user data
      const userData = localStorage.getItem('userData');
      if (userData) {
        // If user data is found, consider the user logged in
        setLoggedIn(true);
      }
    }, []);
    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const handleCloseMenu = () => {
      setMenuOpen(false);
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Display two items at a time
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768, // Adjust breakpoint as needed
          settings: {
            slidesToShow: 1, // Show one item at a time on smaller screens
          },
        },
      ],
      
    };
    const items = [
    
        { icon: "/images/img_home.svg", name: "Government", route: 'government',images: "/images/img-govt.svg", },
        { icon: "/images/img_settings.svg", name: "Community" ,route: 'community' ,images: "/images/img-cat-2.svg"},
        { icon: "/images/img_thumbs_up.svg", name: "Educational" ,route: 'educational',images: "/images/img-cat-3.svg"},
        { icon: "/images/img_group_55.svg", name: "Healthcare" ,route: 'healthcare',images: "/images/img-cat-4.svg"},
        { icon: "/images/img_group_52.svg", name: "Legal & Immigration service",route: 'immigration',images: "/images/img-cat-5.svg" },
        {
          icon: "/images/img_thumbs_up_white_a700.svg",
          name: "Cultural & Recreational Activities"
          ,route: 'cultural',images: "/images/img-cat-6.svg"
        },
        { icon: "/images/img_settings_white_a700.svg", name: "Finance",route: 'finance' ,images: "/images/img-cat-7.svg"},
        { icon: "/images/img_group_59.svg", name: "Social" ,route: 'social',images: "/images/img-cat-8.svg"},
        { icon: "/images/img_group_57.svg", name: "Local Resource" ,route: 'investment',images: "/images/img-locals.svg"},
      ];

      const images = [
        'images/img_slider_1.png',
        'images/img_slider_2.png',
        'images/img_slider_3.png',
        'images/img_slider_4.png',
        // 'images/img_slider_4.png',
        // 'images/img_slider_3.png',
        // 'images/img_slider_2.png',
        // 'images/img_slider_1.png',

      ];
     


    const handlecategory = () => {
      toast.warn('Sign up to get AI powered assistance in this category', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      
    };
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const servicesRef = useRef(null);
    const portfolioRef = useRef(null);
    const contactRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate__animated', 'animate__fadeInUp');
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the element is visible
      );
  
      const sections = [homeRef.current, aboutRef.current, servicesRef.current, portfolioRef.current, contactRef.current];
      sections.forEach((section) => {
        if (section) {
          observer.observe(section);
        }
      });
  
      return () => {
        sections.forEach((section) => {
          if (section) {
            observer.unobserve(section);
          }
        });
      };
    }, []);
    const [ref, inView] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refabout, inViewAbout] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refLeft, inViewLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refRight, inViewRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThing, inViewEveryThing] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refEveryThingRight, inViewEveryThingRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refDownloadBottom, inViewDownloadBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refDownloadLeft, inViewDownloadLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refDownloadRight, inViewDownloadRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refBottom, inViewBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refAboutLeft, inViewAboutLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refAboutRight, inViewAboutRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refAboutBottom, inViewAboutBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const animation = useSpring({
      opacity: 1,
      transform: 'translateY(0)',
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 1000 },
    });
    const animationLeft = useSpring({
      opacity: inViewLeft ? 1 : 0,
      transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationRight = useSpring({
      opacity: inViewRight ? 1 : 0,
      transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationEveryThing = useSpring({
      opacity: inViewEveryThing ? 1 : 0,
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 700 },
    });
    const animationEveryThingLeft = useSpring({
      opacity: inViewEveryThingLeft ? 1 : 0,
      transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationEveryThingRight = useSpring({
      opacity: inViewEveryThingRight ? 1 : 0,
      transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationEveryThingBottom = useSpring({
      opacity: inViewEveryThingBottom ? 1 : 0,
      transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationAbout = useSpring({
      opacity: 1,
      transform: 'translateY(0)',
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 700 },
    });
  
  
    const bottomAnimation = useSpring({
      opacity: inViewBottom ? 1 : 0,
      transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
      config: { duration: 700 }, // Adjust animation duration
    });
    const animationAboutLeft = useSpring({
      opacity: inViewAboutLeft ? 1 : 0,
      transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationAboutRight = useSpring({
      opacity: inViewAboutRight ? 1 : 0,
      transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const bottomAboutAnimation = useSpring({
      opacity: inViewAboutBottom ? 1 : 0,
      transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
      config: { duration: 700 }, // Adjust animation duration
    });
    return (
      <>
        <Helmet>
          <title>LinkedOrigins</title>
          <meta name="description" content="Let’s help you figure things out in canada" />
        </Helmet>
        <div className="w-full bg-white-A700 overflow-hidden">
          <div className="flex flex-col items-center">
            <div className="flex flex-col items-center self-stretch">
            <div className="h-screen   relative  bg-[url(/public/images/img_mask_group_829x1512.png)] md:bg-center bg-cover bg-no-repeat w-full md:pb-5 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none">
              <div className="absolute h-full w-full  bg-black-900 opacity-30 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none"></div>
                      <div className="flex absolute h-full w-full flex-col items-center">
                      <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5 p-10 md:p-4  bg-[#191919D1] bg-opacity-20 right-3 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none" ref={navbarRef}>
        <div className="container mx-auto flex  justify-between items-center">
        <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-8 md:h-6 " />

          <div className="hidden md:block  ">
            {isMenuOpen ? (
              <button 
                onClick={handleCloseMenu}
                className="text-white-A700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button 
                onClick={handleMenuToggle}
                className="text-white-A700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            )}
          </div>
  
          {isMenuOpen && (
            <div className="hidden md:block m-auto items-center  justify-center  absolute top-14 right-0 left-0  bg-black-900 bg-opacity-80 w-full h-auto shadow p-4">
             
          
          <div className="flex flex-col gap-5 text-[#898989]" >
          <NavLink to="/">
          <div className="text-white-A700">Home</div>
            </NavLink>
          <NavLink to="/about">
          <div>About Us</div>
            </NavLink>
            <Link
              activeClass="active"
              to="service"
              spy={true}
              smooth={true}
              // offset={-70}
              duration={500}
            >
              <div className="cursor-pointer">Service</div>
            </Link>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              // offset={-70}
              duration={500}
            >
              <div className="cursor-pointer">Contact Us</div>
            </Link>
            <NavLink to="/matching/locals">
          <div>Matching</div>
            </NavLink>
            {loggedIn ? (  
      <>
        <div  onClick={() => {
               AuthService.logout();
               navigate("/auth/login");
             }} >Logout</div>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div>Login</div>

        </NavLink>
      )}
     
             
  
          </div>
            </div>
          )}
  
          <div className="md:hidden  flex flex-row  items-center justify-center">
    
          <div className="flex gap-5 justify-between  md:flex-wrap">
            <div className="flex gap-5 justify-between self-start text-black md:flex-wrap md:w-full">
            <NavLink to="/">
            <div className="font-bold text-white-A700">Home</div>
            </NavLink>
            <NavLink to="/about">
            <div className="text-[#898989] hover:text-white-A700">About Us</div>
            </NavLink>
            <Link
              activeClass="active"
              to="service"
              spy={true}
              smooth={true}
              // offset={-70}
              duration={500}
            >
              <div className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            </Link>
            <Link
              activeClass="active"
              to="contact"
              spy={true}
              smooth={true}
              // offset={-70}
              duration={500}
            >
              <div className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            </Link>
        
            <NavLink to="/matching/locals">
            <div className="cursor-pointer text-[#898989] hover:text-white-A700">Matching</div>

            </NavLink>
            
            </div>
            
           
          </div>
          </div>
          <div className="md:hidden  flex flex-row items-center gap-2">
  
    <div className="flex flex-row gap-4 items-center justify-center ">
                                
                                     <svg width="77" height="40" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.97 38.33C47.97 38.69 47.89 39.06 47.72 39.42C47.55 39.78 47.33 40.12 47.04 40.44C46.55 40.98 46.01 41.37 45.4 41.62C44.8 41.87 44.15 42 43.45 42C42.43 42 41.34 41.76 40.19 41.27C39.04 40.78 37.89 40.12 36.75 39.29C35.6 38.45 34.51 37.52 33.47 36.49C32.44 35.45 31.51 34.36 30.68 33.22C29.86 32.08 29.2 30.94 28.72 29.81C28.24 28.67 28 27.58 28 26.54C28 25.86 28.12 25.21 28.36 24.61C28.6 24 28.98 23.44 29.51 22.94C30.15 22.31 30.85 22 31.59 22C31.87 22 32.15 22.06 32.4 22.18C32.66 22.3 32.89 22.48 33.07 22.74L35.39 26.01C35.57 26.26 35.7 26.49 35.79 26.71C35.88 26.92 35.93 27.13 35.93 27.32C35.93 27.56 35.86 27.8 35.72 28.03C35.59 28.26 35.4 28.5 35.16 28.74L34.4 29.53C34.29 29.64 34.24 29.77 34.24 29.93C34.24 30.01 34.25 30.08 34.27 30.16C34.3 30.24 34.33 30.3 34.35 30.36C34.53 30.69 34.84 31.12 35.28 31.64C35.73 32.16 36.21 32.69 36.73 33.22C37.27 33.75 37.79 34.24 38.32 34.69C38.84 35.13 39.27 35.43 39.61 35.61C39.66 35.63 39.72 35.66 39.79 35.69C39.87 35.72 39.95 35.73 40.04 35.73C40.21 35.73 40.34 35.67 40.45 35.56L41.21 34.81C41.46 34.56 41.7 34.37 41.93 34.25C42.16 34.11 42.39 34.04 42.64 34.04C42.83 34.04 43.03 34.08 43.25 34.17C43.47 34.26 43.7 34.39 43.95 34.56L47.26 36.91C47.52 37.09 47.7 37.3 47.81 37.55C47.91 37.8 47.97 38.05 47.97 38.33Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
  <rect x="1" y="1" width="75" height="61" rx="30.5" stroke="white" stroke-width="2"/>
                                  </svg>
    </div>
    {loggedIn ? (  
      <>
        <div  onClick={() => {
               AuthService.logout();
               navigate("/auth/login");
             }} className="text-white-A700 border border-white-A700 rounded-full p-3 text-sm">Logout</div>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div className="text-white-A700 border border-white-A700 rounded-full p-3 text-sm">Login</div>

        </NavLink>
      )}
    </div>
        </div>
                       </nav>
                      
                        <div className="mt-20 flex w-80  md:w-60    items-center justify-between rounded-[15px] bg-gray-100_89 p-[5px] animate__animated animate__fadeInLeft">
                          <Button
                            color="blue_gray_800"
                            size="lg"
                            variant="fill"
                            className=" rounded-[10px] font-inter font-semibold  hover:-translate-y-1 hover:scale-110 duration-300 text-base md:text-sm" 
                          >
                            Tour
                          </Button>
                          <Button
                            size="lg"
                            variant="fill"
                            className=" rounded-[10px] font-inter font-semibold hover:-translate-y-1 hover:scale-110 duration-300 "
                            onClick={togglePage}
                          >
                            <Heading
                              size="md"
                              as="h1"
                              className="text-center !font-inter !font-semibold !text-white-A700 text-base md:text-sm"
                            >
                              Mon-Ami
                          </Heading>
                          </Button>
                          
                        </div>
                        <Heading  className="mt-16 text-7xl md:text-5xl sm:text-3xl text-center !text-white-A700 animate__animated animate__fadeInRight ">
                          New in Canada?
                        </Heading>
                        <Text  as="p" className="my-10 text-center text-4xl md:text-xl sm:text-2xl !text-white-A700 md:p-5 animate__animated animate__fadeInUp">
                        Let LinkedOrigins leveraging AI resources to help you figure things out
                        </Text>
                        {loggedIn ? (  
      <>
        <NavLink to="/dashboard/welcome">
        <Button
                            color="white_A700"
                            className="rounded-[38px] font-inter font-semibold sm:px-5 hover:-translate-y-1 hover:scale-110 duration-300 animate__animated animate__fadeInUp"
                          >
                            Continue
                          </Button>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/register">
      <Button
                            color="white_A700"
                            className="rounded-[38px] font-inter font-semibold sm:px-5 hover:-translate-y-1 hover:scale-110 duration-300 animate__animated animate__fadeInUp"
                          >
                            Sign up
                          </Button>
        </NavLink>
      )}
         
                       
                      </div>
                    </div>
                    <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              // offset={-70}
              duration={500}
            >
    <Img
                src="images/img-hero-arrow.svg"
                alt="polygonone_one"
                className="md:hidden relative z-[1] mt-[-84px] h-[148px] w-[148px] rounded-[32px] cursor-pointer"
              />            </Link>
             
            </div >
            <Element name="about" className="w-full ">
        <div className="w-full h-full flex flex-col items-center justify-center" >
        <Heading
                ref={aboutRef}
                className="text-7xl md:text-5xl w-[50%] md:w-full sm:text-3xl mt-10 m-auto h-max   text-center !text-black-900 md:p-5 animate__animated animate__fadeInUp"
              >
                <>
                  Finding all you need in
                  one place!
                </>
              </Heading>
              
              {/* <div className="mt-1 mb-10 h-4  w-32 rounded-[10px] bg-orange-200 md:ml-0 " /> */}
<div className="w-full  sm:bg-orange-200 flex items-center justify-center">             <div className="w-[80%] relative  xl:bg-[url(/public/images/img_bg_slider.svg)] md:bg-orange-200 bg-center bg-contain bg-no-repeat py-8 animate__animated animate__fadeInUp ">  <ImageSlider images={images} /></div>
</div>
          
           
            <Button  onClick={togglePage} color="blue_gray_400"  className="my-14 text-base md:text-sm px-4 rounded-[45px] font-medium  hover:-translate-y-1 hover:scale-110 duration-300">
           
              Say hello to Mon - Ami
            </Button>
            <Text  className=" w-[40%] text-center !font-inter md:w-full md:p-5 animate__animated animate__fadeInUp">
              <span className="font-poppins font-normal text-gray_800_06 text-base md:text-sm">Sign up and get access to</span>
              <span className="font-poppins font-semibold text-gray_800_06 text-base md:text-sm ">&nbsp;</span>
              <span className="font-poppins font-semibold text-gray-900_02 text-base md:text-sm">Mon - Ami</span>
              <span className="font-poppins font-semibold text-gray_800_06 text-base md:text-sm">
                <>
                  , <br />
                </>
              </span>
              <span className="font-poppins font-normal text-gray_800_06 text-base md:text-sm">
              our AI powered help and guide to easy settlement in Canada
              </span>
            </Text>
        </div>
      </Element>
            
            <div className="mt-10 h-[1px] w-full  bg-black-900 " />

            <Element name="service" className="w-full ">
            <div className="md:p-5 w-full flex flex-col justify-center  items-center" >
            <div className="ml-20 flex  items-start gap-[23px] self-start md:ml-0 md:w-full  mt-5">
              {/* <Img src="images/img_star_1.svg" alt="image_two" className="h-[38px] w-[38px]" /> */}
              <Text size="6xl" as="p" className="self-end ml-12 md:ml-0">
                What we do
              </Text>
            </div>
            <Heading  className="text-6xl md:text-4xl sm:text-2xl ml-32 mt-20  mb-2 self-start !text-blue_gray-900 md:ml-0">
              Sign up to access our services
            </Heading>
            <Text
              size="5xl"
              as="p"
              className="text-2xl md:text-xl sm:text-sm ml-32  md:ml-0 self-start leading-[41px] !text-gray-800_01  md:w-full animate__animated animate__fadeInLeft"
            >
              <>
                we are dedicated to helping individuals and families achieve their settlement <br />
                goals in Canada.
              </>
            </Text>
            {loggedIn ? (  
      <>
       <div className="my-4 w-full">
       <NavLink to="/dashboard/welcome">
            <Button
                color="blue_gray_400"
                shape="round"
                className="ml-32 md:ml-0  my-5 font-inter font-semibold sm:px-5"
              >
               Continue
              </Button>
              </NavLink>
            </div>
  </>
      ) : (

        <div className="my-4 w-full">
            <NavLink to="/auth/register">
            <Button
                color="blue_gray_400"
                shape="round"
                className="ml-32 md:ml-0  my-5 font-inter font-semibold sm:px-5"
              >
                Sign up
              </Button>
              </NavLink>
            </div>
      )}
            
        
            <animated.div ref={ref} style={animation} className='w-full flex items-center justify-center'>
      <div className=" w-full flex items-center justify-center"
          
          >
             <div  className=" md:hidden grid  grid-cols-3 md:grid-cols-1 bg-[url(/public/images/img_bg.svg)] bg-contain bg-no-repeat   w-[70%] md:w-full">
             {items.map((item, index) => (
        <>
           {loggedIn ? (  
              <>
             <div className="my-4 mx-auto w-full">
             <NavLink to={`/dashboard/category/${item.route}`}>
 
             <div
                                    className="w-[266px] h-[300px] cursor-pointer bg-fixed bg-no-repeat mb-4 flex flex-col items-center justify-end gap-[11px] py-10 px-4 hover:-translate-y-1 hover:scale-110 duration-300"
                                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL + item.images})` }}
                                >
        <div className="flex flex-row relative items-center justify-between gap-2 h-12 px-2  bg-white-A700 w-full rounded-full">
          <div className="w-10 h-10 rounded-full bg-[#373737] p-2 absolute left-2" >
          <img loading="lazy" src={item.icon} alt="government_one"  />
          </div>
        <div className={ `text-sm text-center absolute ${index===4||index===5?"left-12":"left-4"} right-0 `}>{item.name}</div>
      </div>
            {/* <Img src={item.icon} alt="government_one" className="h-4" />
                        <Heading  as="h2" className="text-2xl md:text-xl sm:text-base !font-inter !text-white-A700">
                          {item.name}
                        </Heading> */}
        </div>
                      </NavLink>
                    </div>
          </>
              ) : (        
                <div  onClick={handlecategory} className="my-4 mx-auto w-full">
              
    
                <div
                                       className="w-[266px] h-[300px] cursor-pointer bg-fixed bg-no-repeat mb-4 flex flex-col items-center justify-end gap-[11px] py-10 px-4 hover:-translate-y-1 hover:scale-110 duration-300"
                                       style={{ backgroundImage: `url(${process.env.PUBLIC_URL + item.images})` }}
                                   >
           <div className="flex flex-row relative items-center justify-between gap-2 h-12 px-2  bg-white-A700 w-full rounded-full">
             <div className="w-10 h-10 rounded-full bg-[#373737] p-2 absolute left-2" >
             <img loading="lazy" src={item.icon} alt="government_one"  />
             </div>
           <div className={ `text-sm text-center absolute ${index===4||index===5?"left-12":"left-4"} right-0 `}>{item.name}</div>
         </div>
               {/* <Img src={item.icon} alt="government_one" className="h-4" />
                           <Heading  as="h2" className="text-2xl md:text-xl sm:text-base !font-inter !text-white-A700">
                             {item.name}
                           </Heading> */}
           </div>
                         
                       </div>
              )}
        </>
         
       
      ))}
     </div>
     <div className="hidden md:block  w-full my-6 mx-auto  items-center justify-center ">
     <Slider {...settings}>
     {items.map((item, index) => (
        <div onClick={handlecategory}  className=" w-full cursor-pointer  mb-10  flex  flex-col items-center justify-center gap-[11px] h-44" >
                <div
                                    className="w-[266px] h-[300px] mx-auto cursor-pointer bg-fixed bg-no-repeat mb-4 flex flex-col items-center justify-end gap-[11px] py-10 px-4 hover:-translate-y-1 hover:scale-110 duration-300"
                                    style={{ backgroundImage: `url(${process.env.PUBLIC_URL + item.images})` }}
                                >
        <div className="flex flex-row relative items-center justify-between gap-2 h-12 px-2  bg-white-A700 w-full rounded-full">
          <div className="w-10 h-10 rounded-full bg-[#373737] p-2 absolute left-2" >
          <img loading="lazy" src={item.icon} alt="government_one"  />
          </div>
        <div className={ `text-sm text-center absolute ${index===4||index===5?"left-12":"left-4"} right-0 `}>{item.name}</div>
      </div>
            {/* <Img src={item.icon} alt="government_one" className="h-4" />
                        <Heading  as="h2" className="text-2xl md:text-xl sm:text-base !font-inter !text-white-A700">
                          {item.name}
                        </Heading> */}
        </div>
           
        </div>
      ))}
    </Slider>
     </div>
          </div>
      </animated.div>     
  
            </div>
      </Element>
            
            <div className="mt-10 h-[1px] w-full  bg-black-900 " />
            <div className="md:p-5 w-full flex flex-col justify-center  items-center" >
            <div className="ml-20 flex  items-center gap-4 self-start md:ml-0 md:w-full  mt-5">
              {/* <Img src="images/img_star_1.svg" alt="image_two" className="h-[38px] w-[38px]" /> */}
              <svg xmlns="http://www.w3.org/2000/svg" className="self-end ml-12 md:ml-0" width="25" height="25" viewBox="0 0 192.756 192.756"><g fill-rule="evenodd" clip-rule="evenodd"><path fill="#fff" d="M0 0h192.756v192.756H0V0z"/><path fill="#cf4044" d="M45.248 140.84V51.917H2.834v88.923h42.414zM189.922 140.84V51.917H147.65v88.923h42.272zM95.973 136.934h-3.572v-17.075l-19.171 1.786 3.572-6.834-20.814-15.266 5.525-3.096-5.358-14.598 11.384 2.763 4.214-5.859 14.48 14.456-7.645-27.792 9.098 5.192 8.287-14.956 8.287 14.956 9.263-5.192-7.81 27.792 14.621-14.456 4.24 5.859 11.217-2.763-5.359 14.598 5.693 3.096-20.98 15.266 3.738 6.834-19.195-1.786v17.075h-3.715z"/></g></svg>
              <Text size="6xl" as="p" className="self-end ">
              Find Locals
              </Text>
            </div>
           
            
          <div className="md:pr-5 md:w-full mx-12">
          <div className="flex gap-5 md:flex-col mt-10 md:gap-4 px-20 md:px-4 ">
            <div className="flex flex-col w-[50%] md:ml-0 md:w-full">
              <div className="flex flex-col  md:mt-10 md:w-full">
                <div className="text-2xl font-semibold  text-neutral-700 md:w-full md:text-xl ">
                Level Up Your Canadian Life
                </div>
                <div className="mt-1 text-base md:text-sm text-stone-500 md:w-full mr-5 md:mr-0">
                  Don't navigate Canada solo! Use our matching system to connect with awesome locals who can be your BFFs, culture guides, and all-around Canadian crew. 
                  Skip the awkward "new in town" phase and unlock the insider tips, hidden gems, and friendly faces that make Canada home.
                  <br />
                </div>
            <div className="my-4 flex items-start justify-start w-full">
            <NavLink to="/matching/locals">
            <Button
                color="blue_gray_400"
                shape="round"
                className=" md:ml-0  my-5 font-inter font-semibold sm:px-5"
              >
                matching
              </Button>
              </NavLink>
            </div>
              </div>
            </div>
            <div className="flex flex-col  w-[50%] md:ml-0 md:w-full">
              <img
                loading="lazy"
                src="images/mask-group-17@2x.png"
               className="w-full md:mt-10 md:w-full md:object-cover"
              />
            </div>
          </div>
          </div>
            <div className="mt-10 h-[1px] w-full  bg-black-900 " />
           <div className=" h-auto mb-10 w-full py-10">
           <Text size="6xl" as="p" className="ml-32  md:ml-0  self-start !text-gray-800_03 ">
              Our Videos
            </Text>
            <div className="  mx-auto mt-[25px] flex  items-center justify-center w-[80%] md:w-full gap-[54px] md:flex-col ">
                <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/6okTvd1dMuk" />
                <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/YyhgJajdDyw" />
                <SmallYouTubeVideo videoUrl="https://www.youtube.com/embed/j_V2sFI8wPM" />
            </div>

           
            </div>

           </div>
          

            
          
            <div className=" flex flex-col items-start gap-[63px] self-stretch  bg-blue_gray-50_01  py-10 sm:gap-[31px] w-full overflow-hidden ">
              <Heading  className= " text-6xl md:text-4xl sm:text-2xl ml-32 mt-[21px] md:ml-4 md:mr-4 !text-blue_gray-900 ">
                What Our Partners Say
              </Heading>
              <div className="mb-24 sm:hidden flex w-full gap-[30px] md:flex-col ml-32 md:ml-0  overflow-scroll ">
                <div className="flex w-full flex-col items-start justify-center rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="mt-1.5 flex w-[57%] items-center gap-3 md:w-full">
                    <Img src="images/img_ellipse_4.png" alt="segun_ayo_one" className="h-[40px] w-[40px] rounded-[50%]" />
                    <Heading size="xs" as="h3" className="mb-1.5 self-end !text-black-900">
                      Segun Ayo
                    </Heading>
                  </div>
                  <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                    <>
                      I love how seamless the website is 
                      and how informative the AI system
                   
                      is.<br /> This helped me get more insight 
                      about canada.
                    </>
                  </Text>
                  <Text size="md" as="p" className="mb-1.5 mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                    24/02/2024
                  </Text>
                </div>
                <div className="flex w-full flex-col items-start justify-center rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="mt-1.5 flex w-[57%] items-center gap-3 md:w-full">
                    <Img
                      src="images/img_ellipse_4_40x40.png"
                      alt="circleimage"
                      className="h-[40px] w-[40px] rounded-[50%]"
                    />
                    <Heading size="xs" as="h4" className="mb-1.5 self-end !text-black-900">
                      Segun Ayo
                    </Heading>
                  </div>
                  <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                    <>
                    I love how seamless the website is 
                      and how informative the AI system
                   
                      is.<br /> This helped me get more insight 
                      about canada.
                    </>
                  </Text>
                  <Text size="md" as="p" className="mb-1.5 mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                    24/02/2024
                  </Text>
                </div>
                <div className="flex w-full flex-col items-start justify-center rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="mt-1.5 flex w-[57%] items-center gap-3 md:w-full">
                    <Img src="images/img_ellipse_4_1.png" alt="circleimage" className="h-[40px] w-[40px] rounded-[50%]" />
                    <Heading size="xs" as="h5" className="mb-1.5 self-end !text-black-900">
                      Segun Ayo
                    </Heading>
                  </div>
                  <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                    <>
                    I love how seamless the website is 
                      and how informative the AI system
                   
                      is.<br /> This helped me get more insight 
                      about canada.
                    </>
                  </Text>
                  <Text size="md" as="p" className="mb-1.5 mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                    24/02/2024
                  </Text>
                </div>
                <div className="flex w-full flex-col items-start justify-center rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="mt-1.5 flex w-[57%] items-center gap-3 md:w-full">
                    <Img src="images/img_ellipse_4_2.png" alt="circleimage" className="h-[40px] w-[40px] rounded-[50%]" />
                    <Heading size="xs" as="h6" className="mb-1.5 self-end !text-black-900">
                      Segun Ayo
                    </Heading>
                  </div>
                  <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                    <>
                    I love how seamless the website is 
                      and how informative the AI system
                   
                      is.<br /> This helped me get more insight 
                      about canada.
                    </>
                  </Text>
                  <Text size="md" as="p" className="mb-1.5 mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                    24/02/2024
                  </Text>
                </div>
                <div className="flex w-full rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="my-1.5 flex w-full flex-col items-start">
                    <div className="flex w-[57%] items-center gap-3 md:w-full">
                      <Img
                        src="images/img_ellipse_4_3.png"
                        alt="circleimage"
                        className="h-[40px] w-[40px] rounded-[50%]"
                      />
                      <Heading size="xs" as="p" className="mb-1.5 self-end !text-black-900">
                        Segun Ayo
                      </Heading>
                    </div>
                    <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                      <>
                      I love how seamless the website is 
                      and how informative the AI system
                   
                      is.<br /> This helped me get more insight 
                      about canada.
                      </>
                    </Text>
                    <Text size="md" as="p" className="mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                      24/02/2024
                    </Text>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block  w-full my-10 items-center justify-center ml-32 md:ml-4 md:mr-4 ">
            <Slider {...settings}>
            <div className="flex w-full flex-col items-start justify-center rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="mt-1.5 flex w-[57%] items-center gap-3 md:w-full">
                    <Img src="images/img_ellipse_4.png" alt="segun_ayo_one" className="h-[40px] w-[40px] rounded-[50%]" />
                    <Heading size="xs" as="h3" className="mb-1.5 self-end !text-black-900">
                      Segun Ayo
                    </Heading>
                  </div>
                  <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                    <>
                      I love how seamless the website is <br />
                      and how informative the AI system
                      <br />
                      is. This helped me get more insight <br />
                      about canada.
                    </>
                  </Text>
                  <Text size="md" as="p" className="mb-1.5 mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                    24/02/2024
                  </Text>
                </div>
                <div className="flex w-full flex-col items-start justify-center rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="mt-1.5 flex w-[57%] items-center gap-3 md:w-full">
                    <Img
                      src="images/img_ellipse_4_40x40.png"
                      alt="circleimage"
                      className="h-[40px] w-[40px] rounded-[50%]"
                    />
                    <Heading size="xs" as="h4" className="mb-1.5 self-end !text-black-900">
                      Segun Ayo
                    </Heading>
                  </div>
                  <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                    <>
                      I love how seamless the website is <br />
                      and how informative the AI system
                      <br />
                      is. This helped me get more insight <br />
                      about canada.
                    </>
                  </Text>
                  <Text size="md" as="p" className="mb-1.5 mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                    24/02/2024
                  </Text>
                </div>
                <div className="flex w-full flex-col items-start justify-center rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="mt-1.5 flex w-[57%] items-center gap-3 md:w-full">
                    <Img src="images/img_ellipse_4_1.png" alt="circleimage" className="h-[40px] w-[40px] rounded-[50%]" />
                    <Heading size="xs" as="h5" className="mb-1.5 self-end !text-black-900">
                      Segun Ayo
                    </Heading>
                  </div>
                  <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                    <>
                      I love how seamless the website is <br />
                      and how informative the AI system
                      <br />
                      is. This helped me get more insight <br />
                      about canada.
                    </>
                  </Text>
                  <Text size="md" as="p" className="mb-1.5 mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                    24/02/2024
                  </Text>
                </div>
                <div className="flex w-full flex-col items-start justify-center rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="mt-1.5 flex w-[57%] items-center gap-3 md:w-full">
                    <Img src="images/img_ellipse_4_2.png" alt="circleimage" className="h-[40px] w-[40px] rounded-[50%]" />
                    <Heading size="xs" as="h6" className="mb-1.5 self-end !text-black-900">
                      Segun Ayo
                    </Heading>
                  </div>
                  <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                    <>
                      I love how seamless the website is <br />
                      and how informative the AI system
                      <br />
                      is. This helped me get more insight <br />
                      about canada.
                    </>
                  </Text>
                  <Text size="md" as="p" className="mb-1.5 mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                    24/02/2024
                  </Text>
                </div>
                <div className="flex w-full rounded-[10px] bg-white-A700 p-[25px] md:p-5">
                  <div className="my-1.5 flex w-full flex-col items-start">
                    <div className="flex w-[57%] items-center gap-3 md:w-full">
                      <Img
                        src="images/img_ellipse_4_3.png"
                        alt="circleimage"
                        className="h-[40px] w-[40px] rounded-[50%]"
                      />
                      <Heading size="xs" as="p" className="mb-1.5 self-end !text-black-900">
                        Segun Ayo
                      </Heading>
                    </div>
                    <Text size="s" as="p" className="mt-3.5 !text-gray-700">
                      <>
                        I love how seamless the website is <br />
                        and how informative the AI system
                        <br />
                        is. This helped me get more insight <br />
                        about canada.
                      </>
                    </Text>
                    <Text size="md" as="p" className="mr-[3px] mt-9 self-end !text-gray-900 md:mr-0">
                      24/02/2024
                    </Text>
                  </div>
                </div>
    </Slider>
     </div>
            </div>
           
            <Element name="contact" className="w-full ">
            <div className="mt-20 mb-20 flex flex-col items-center">

<Heading  className="text-6xl md:text-4xl sm:text-2xl  mt-20  !text-blue_gray-900 md:ml-0">
Ready to Start?
</Heading>
<Text
  size="5xl"
  as="p"
  className="text-2xl md:text-xl sm:text-sm  my-5 md:ml-0 text-center  leading-[41px] !text-gray-800_01  md:w-full md:p-5"
>
  <>
  Powered by AI, Our goal is to create the best settlement experience for you
  </>
</Text>
<NavLink to="/auth/register">
 <Button color="gray_600_04" shape="round" className="font-inter font-semibold">
    Get Started
 </Button> 
</NavLink>
   
</div>
<div className=" mt-10 md:mt-0 flex w-full  flex-col justify-center items-center md:px-1 md:pt-5">
  <Img
    src="images/img_header_logo.svg"
    alt="image_six"
    className="relative  md:h-32  h-48 md:w-[70%] w-[35%] md:ml-0"
  />
  <div className=" -mt-20 flex md:flex-col justify-center items-center w-[80%] h-[500px]  md:h-auto md:w-full  rounded-t-[285px] md:rounded-t-[50px] bg-red-400 px-14 pt-32 md:px-5 ">
    <div className="mb-10 flex w-[40%] flex-col items-start justify-start gap-[23px] md:w-full h-full">
   
      <p className="">
        <Heading className="text-4xl md:text-2xl text-left sm:text-2x !text-white-A700">
        Join our Mailing List
        </Heading>
      </p>
      <div className="self-stretch">
        <p  className="text-sm !text-white-A700 w-full">
          <>
            Subscribe to our newsletter for immigration 
            insights and travel news!
          </>
        </p>
       
        <form onSubmit={handleSubmit}>
<div  className="md:ml-0  my-5 font-inter font-semibold p-2 border border-gray-400  rounded-md"> <input
type="email"
id="email"
value={email}

onChange={(e) => setEmail(e.target.value)}
required
/></div>
{loading?(<button
 
    disabled
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Loading
  </button>):(<button
 type="submit"
 
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Subscribe
  </button>)}

</form>
{message && <p className="text-white-A700"> {message}</p>}
        <HomeScreenRowcloseOne className="mt-10 flex items-center justify-between gap-5 md:hidden " />

      </div>
    </div>
    <div className="mb-10 flex w-[40%] flex-row items-start justify-between px-10 md:px-4 gap-[23px] md:w-full  h-full">
    <div className="flex flex-col items-start justify-start">
      <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
       Company
        </Heading>
   <NavLink to="/about">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">About us</div>
</NavLink>
<NavLink to="/feedback">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Feedback</div>
</NavLink>
<NavLink to="/vendor">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Vendors Form</div>
</NavLink>
<NavLink to="/contact-us">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Contact us</div>
</NavLink>
<NavLink to="/matching/locals">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Matching</div>
</NavLink>
      </div>
      <div className="flex flex-col items-start justify-start">
      <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
       Legal
        </Heading>
   <NavLink to="/privacy-policy">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Privacy Policy</div>
</NavLink>
<NavLink to="/terms-service">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Terms and conditions</div>
</NavLink>

      </div>

 </div>
 <HomeScreenRowcloseOne className="my-10 md:flex items-center justify-between gap-5  hidden " />

  </div>
</div>
      </Element>
          </div>
        </div>
        <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      </>
    );
  }

export default Page1;



