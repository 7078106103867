import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { toast, Bounce, ToastContainer } from "react-toastify";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { NavLink, useNavigate } from "react-router-dom";
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { Img, Heading, Button,Text } from "components";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import { scroller } from 'react-scroll';
import StarRating from "./component/StarRating";

interface FormData {
  generalExperience: number;
  specificFeatures: number;
  mostHelpfulFeature: string;
  leastHelpfulFeature: string;
  mostImportantCategory: string;
  suggestions: string;
  overallImpression: string;
  bugsDescription: string;
  suggestedFeatures: string;
  recommendLinkedOriginsFeedback: string;
  additionalFeedBack: string;
}
export default  function Feeback() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [email, setEmail] = useState<string>('');
   const [message, setMessage] = useState<string>('');


    useEffect(() => {
      // Check local storage for user data
      const userData = localStorage.getItem('userData');
      if (userData) {
        // If user data is found, consider the user logged in
        setLoggedIn(true);
      }
    }, []);
    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const handleCloseMenu = () => {
      setMenuOpen(false);
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Display two items at a time
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768, // Adjust breakpoint as needed
          settings: {
            slidesToShow: 1, // Show one item at a time on smaller screens
          },
        },
      ],
      
    };
    const items = [
        // { id: 1, name: 'Government',icon:'images/img_home.svg' },
        // { id: 2, name: 'Community',icon:'images/img_settings.svg'  },
        // { id: 3, name: 'Educational' ,icon:'images/img_thumbs_up.svg' },
        // { id: 4, name: 'Healthcare',icon:'images/img_group_55.svg'  },
        // { id: 5, name: 'Legal & Immigration service' ,icon:'images/img_group_52.svg' },
        // { id: 6, name: 'Cultural & Recreational Activities',icon:'images/img_thumbs_up_white_a700.svg'  },
        // { id: 7, name: 'Finance',icon:'images/img_settings_white_a700.svg'  },
        // { id: 8, name: 'Social',icon:'images/img_group_59.svg'  },
        // { id: 9, name: 'Investment' ,icon:'images/img_group_57.svg' },
        { icon: "/images/img_home.svg", name: "Government", route: 'government' },
        { icon: "/images/img_settings.svg", name: "Community" ,route: 'community' },
        { icon: "/images/img_thumbs_up.svg", name: "Educational" ,route: 'educational'},
        { icon: "/images/img_group_55.svg", name: "Healthcare" ,route: 'healthcare'},
        { icon: "/images/img_group_52.svg", name: "Legal & Immigration service",route: 'immigration' },
        {
          icon: "/images/img_thumbs_up_white_a700.svg",
          name: "Cultural & Recreational Activities"
          ,route: 'cultural'
        },
        { icon: "/images/img_settings_white_a700.svg", name: "Finance",route: 'finance' },
        { icon: "/images/img_group_59.svg", name: "Social" ,route: 'social'},
        { icon: "/images/img_group_57.svg", name: "Local Resource" ,route: 'investment'},
      ];

      const images = [
        'images/img_slider_1.png',
        'images/img_slider_2.png',
        'images/img_slider_3.png',
        'images/img_slider_4.png',
        // 'images/img_slider_4.png',
        // 'images/img_slider_3.png',
        // 'images/img_slider_2.png',
        // 'images/img_slider_1.png',

      ];
     


    const handlecategory = () => {
      toast.warn('Sign up to get AI powered assistance in this category', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      
    };
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const servicesRef = useRef(null);
    const portfolioRef = useRef(null);
    const contactRef = useRef(null);
 
    const navigate = useNavigate();

  const navigateToAboutSection = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo('service', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }, 100);
  };
  const navigateToContactSection = () => {
   navigate('/contact-us');
 
  };
  
  const [formData, setFormData] = useState<FormData>({
    generalExperience: 0,
    specificFeatures: 0,
    mostHelpfulFeature: '',
    leastHelpfulFeature: '',
    mostImportantCategory: '',
    suggestions: '',
    overallImpression: '',
    bugsDescription: '',
    suggestedFeatures: '',
    recommendLinkedOriginsFeedback: '',
    additionalFeedBack: ''
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleRatingChange = (name: string, value: number) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    fetch('https://linked-origin-server.vercel.app/api/v1/feedback/postfeedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        toast.success('Feeback sent successfully', {
          position:"top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setLoading(false);
      })
      .catch(error => {
        console.error('Error:', error);
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col items-center bg-white">
      <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5 p-10 md:p-4  bg-[#191919D1] bg-opacity-20 right-3 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none" ref={navbarRef}>
        <div className="container mx-auto flex  justify-between items-center">
        <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-8 md:h-6 " />

          <div className="hidden md:block  ">
            {isMenuOpen ? (
              <button 
                onClick={handleCloseMenu}
                className="text-white-A700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button 
                onClick={handleMenuToggle}
                className="text-white-A700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            )}
          </div>
  
          {isMenuOpen && (
            <div className="hidden md:block m-auto items-center  justify-center  absolute top-14 right-0 left-0  bg-black-900 bg-opacity-80 w-full h-auto shadow p-4">
             
          
          <div className="flex flex-col gap-5 text-white-A700" >
          <NavLink to="/">
          <div>Home</div>
            </NavLink>
          <NavLink to="/about">
          <div>About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/about">
          <div>Matching</div>
            </NavLink>
              {/* {loggedIn ? (  
      <>
        <NavLink to="/dashboard/welcome">
            <div className="text-white-A700">Continue</div>
            </NavLink>

  </>
      ) : (

        <NavLink to="/auth/login">
        <div className="text-white-A700">Login</div>
        </NavLink>
      )} */}
             
  
          </div>
            </div>
          )}
  
          <div className="md:hidden  flex flex-row  items-center justify-center">
    
          <div className="flex gap-5 justify-between  md:flex-wrap">
            <div className="flex gap-5 justify-between self-start text-black md:flex-wrap md:w-full">
            <NavLink to="/">
            <div className="text-[#898989]  hover:text-white-A700">Home</div>
            </NavLink>
            <NavLink to="/about">
            <div className="text-[#898989]  hover:text-white-A700 ">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/matching/locals">
            <div className="cursor-pointer text-[#898989] hover:text-white-A700">Matching</div>
            </NavLink>
 
            
            </div>
            
           
          </div>
          </div>
          <div className="md:hidden  flex flex-row items-center gap-2">
  
    <div className="flex flex-row gap-4 items-center justify-center ">
                                
                                     <svg width="77" height="40" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.97 38.33C47.97 38.69 47.89 39.06 47.72 39.42C47.55 39.78 47.33 40.12 47.04 40.44C46.55 40.98 46.01 41.37 45.4 41.62C44.8 41.87 44.15 42 43.45 42C42.43 42 41.34 41.76 40.19 41.27C39.04 40.78 37.89 40.12 36.75 39.29C35.6 38.45 34.51 37.52 33.47 36.49C32.44 35.45 31.51 34.36 30.68 33.22C29.86 32.08 29.2 30.94 28.72 29.81C28.24 28.67 28 27.58 28 26.54C28 25.86 28.12 25.21 28.36 24.61C28.6 24 28.98 23.44 29.51 22.94C30.15 22.31 30.85 22 31.59 22C31.87 22 32.15 22.06 32.4 22.18C32.66 22.3 32.89 22.48 33.07 22.74L35.39 26.01C35.57 26.26 35.7 26.49 35.79 26.71C35.88 26.92 35.93 27.13 35.93 27.32C35.93 27.56 35.86 27.8 35.72 28.03C35.59 28.26 35.4 28.5 35.16 28.74L34.4 29.53C34.29 29.64 34.24 29.77 34.24 29.93C34.24 30.01 34.25 30.08 34.27 30.16C34.3 30.24 34.33 30.3 34.35 30.36C34.53 30.69 34.84 31.12 35.28 31.64C35.73 32.16 36.21 32.69 36.73 33.22C37.27 33.75 37.79 34.24 38.32 34.69C38.84 35.13 39.27 35.43 39.61 35.61C39.66 35.63 39.72 35.66 39.79 35.69C39.87 35.72 39.95 35.73 40.04 35.73C40.21 35.73 40.34 35.67 40.45 35.56L41.21 34.81C41.46 34.56 41.7 34.37 41.93 34.25C42.16 34.11 42.39 34.04 42.64 34.04C42.83 34.04 43.03 34.08 43.25 34.17C43.47 34.26 43.7 34.39 43.95 34.56L47.26 36.91C47.52 37.09 47.7 37.3 47.81 37.55C47.91 37.8 47.97 38.05 47.97 38.33Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
  <rect x="1" y="1" width="75" height="61" rx="30.5" stroke="white" stroke-width="2"/>
                                  </svg>
    </div>
    {loggedIn ? (  
      <>
        <NavLink to="/auth/login">
            <div className="text-white-A700 border border-white-A700 rounded-full p-3 text-sm">Logout</div>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div className="text-white-A700 border border-white-A700 rounded-full p-3 text-sm">Login</div>

        </NavLink>
      )}
    </div>
        </div>
            </nav>

            <form onSubmit={handleSubmit} className="w-[80%] md:w-[95%] mx-auto  border border-gray-600 my-10 p-10 md:p-2">
     <div className="w-full flex flex-col items-center text-lg text-black mb-10">
      <div className="text-xl font-medium ">
        LinkedOrigins Beta Testing Feedback Form
      </div>
      <div className="mt-12 text-neutral-900 text-sm">
        <span className="font-medium">Hello there</span>, thank you for being a
        part of the LinkedOrigins Beta Test! 
      </div>
      <div className="mt-4  text-sm">
        Your feedback is crucial in helping us improve the platform before its
        official launch.
      </div>
    </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-base">General Experience</label>
        <StarRating name="generalExperience" value={formData.generalExperience} onChange={handleRatingChange} />
      </div>

      <div className="mb-4">
      <label className="block text-gray-700 text-base">Special Features</label>
        <StarRating name="specificFeatures" value={formData.specificFeatures} onChange={handleRatingChange} />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 mb-2 text-base">Which feature(s) did you find most helpful?</label>
       <div className="border rounded-md p-2">   
         <input type="text" name="mostHelpfulFeature" value={formData.mostHelpfulFeature} onChange={handleChange} className="w-full p-2  rounded-md" />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 mb-2 text-base">Which feature(s) did you find least helpful?</label>
        <div className="border rounded-md p-2">   
        <input type="text" name="leastHelpfulFeature" value={formData.leastHelpfulFeature} onChange={handleChange} className="w-full p-2   rounded-md" />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 mb-2 text-base">Which category is most important to you?</label>
        <div className="border rounded-md p-2">   
        <input type="text" name="mostImportantCategory" value={formData.mostImportantCategory} onChange={handleChange} className="w-full p-2 b rounded-md" />
        </div>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-base">Do you have any suggestions for improving specific features or categories?</label>
        <textarea name="suggestions" value={formData.suggestions} onChange={handleChange} className="w-full p-2 border  rounded"></textarea>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">What was your overall impression of LinkedOrigins?</label>
        <textarea name="overallImpression" value={formData.overallImpression} onChange={handleChange} className="w-full p-2 border rounded"></textarea>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-base">Did you encounter any bugs or technical issues while using the app? If so, please describe them.</label>
        <textarea name="bugsDescription" value={formData.bugsDescription} onChange={handleChange} className="w-full p-2 border rounded"></textarea>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-base">Are there any additional features you would like to see on LinkedOrigins?</label>
        <textarea name="suggestedFeatures" value={formData.suggestedFeatures} onChange={handleChange} className="w-full p-2 border rounded"></textarea>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-base">Would you recommend LinkedOrigins to others? (Yes/No) Why or why not?</label>
        <textarea name="recommendLinkedOriginsFeedback" value={formData.recommendLinkedOriginsFeedback} onChange={handleChange} className="w-full p-2 border rounded"></textarea>
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-base">Additional Feedback</label>
        <textarea name="additionalFeedBack" value={formData.additionalFeedBack} onChange={handleChange} className="w-full p-2 border rounded"></textarea>
      </div>

      <button type="submit" className={`bg-red-400 text-white-A700 md:w-full px-4 py-2 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={loading}>
        {loading ? 'Submitting...' : 'Submit'}
      </button>
    </form>
    <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<div className="w-full flex flex-col  justify-center items-center">
     {/* <Heading  className="text-6xl md:text-4xl sm:text-2xl  mt-20  !text-blue_gray-900 md:ml-0">
Ready to Start?
</Heading>
<Text
  size="5xl"
  as="p"
  className="text-2xl md:text-xl sm:text-sm  my-5 md:ml-0 text-center  leading-[41px] !text-gray-800_01  md:w-full md:p-5"
>
  <>
  Powered by AI, Our goal is to create the best settlement experience for you
  </>
</Text>
<NavLink to="/auth/register">
 <Button color="gray_600_04" shape="round" className="font-inter font-semibold">
    Get Started
 </Button>' 
</NavLink> */}
<div className=" mt-20 md:mt-0 flex w-full  flex-col justify-center items-center md:px-1 md:pt-5">
  <Img
    src="/images/img_header_logo.svg"
    alt="image_six"
    className="relative  md:h-32  h-48 md:w-[70%] w-[35%] md:ml-0 z-0"
  />
  <div className=" -mt-20 flex md:flex-col justify-center items-center w-[80%] h-[500px]  md:h-auto md:w-full  rounded-t-[285px] md:rounded-t-[50px] bg-red-400 px-14 pt-32 md:px-5 ">
    <div className="mb-10 flex w-[40%] flex-col items-start justify-start gap-[23px] md:w-full h-full">
   
      <p className="">
        <Heading className="text-4xl md:text-2xl text-left sm:text-2x !text-white-A700">
        Join our Mailing List
        </Heading>
      </p>
      <div className="self-stretch">
        <p  className="text-sm !text-white-A700 w-full">
          <>
            Subscribe to our newsletter for immigration 
            insights and travel news!
          </>
        </p>
       
        <form onSubmit={handleSubmit}>
<div  className="md:ml-0  my-5 font-inter font-semibold p-2 border border-gray-400  rounded-md"> <input
type="email"
id="email"
value={email}

onChange={(e) => setEmail(e.target.value)}
required
/></div>
{loading?(<button
 
    disabled
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Loading
  </button>):(<button
 type="submit"
 
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Subscribe
  </button>)}

</form>
{message && <p className="text-white-A700"> {message}</p>}
        <HomeScreenRowcloseOne className="mt-10 flex items-center justify-between gap-5 md:hidden " />

      </div>
    </div>
    <div className="mb-10 flex w-[40%] flex-row items-start justify-between px-10 md:px-4 gap-[23px] md:w-full  h-full">
    <div className="flex flex-col items-start justify-start">
      <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
       Company
        </Heading>
   <NavLink to="/about">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">About us</div>
</NavLink>
<NavLink to="/feedback">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Feedback</div>
</NavLink>
<NavLink to="/vendor">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Vendors Form</div>
</NavLink>
<NavLink to="/contact-us">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Contact us</div>
</NavLink>
<NavLink to="/matching/locals">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Matching</div>
</NavLink>
      </div>
      <div className="flex flex-col items-start justify-start">
      <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
       Legal
        </Heading>
   <NavLink to="/privacy-policy">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Privacy Policy</div>
</NavLink>
<NavLink to="/terms-service">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Terms and conditions</div>
</NavLink>

      </div>

 </div>
 <HomeScreenRowcloseOne className="my-10 md:flex items-center justify-between gap-5  hidden " />

  </div>
</div>
      </div>
    </div>
  );
}


