import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Img } from './Img';

type Category = 'Government' | 'Cultural' | 'Community' | 'Finance' | 'Local Resources' | 'Socials'| 'Education' | 'Legal & Immigration' | 'Healthcare'| 'Matching'|'Media';

const categories: { name: Category, route: string }[] = [
  { name: 'Government', route: '/dashboard/category/government' },
  { name: 'Community', route: '/dashboard/category/community' },
  { name: 'Education', route: '/dashboard/category/educational' },
  { name: 'Healthcare', route: '/dashboard/category/healthcare' },
  { name: 'Legal & Immigration', route: '/dashboard/category/immigration' },
  { name: 'Cultural', route: '/dashboard/category/cultural' },
  { name: 'Finance', route: '/dashboard/category/finance' },
  { name: 'Socials', route: '/dashboard/category/social' },
  { name: 'Local Resources', route: '/dashboard/category/investment' },
  { name: 'Matching', route: '/matching/locals' },
  { name: 'Media', route: '/dashboard/category/media' },

];



interface CategoryDropdownProps {
  name: Category;
  img: string;
}

const CategoryDropdown: React.FC<CategoryDropdownProps> = ({ name, img }) => {
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
    setIsDropdownOpen(false);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    <div className="relative w-56 z-50" ref={dropdownRef} >
    <button onClick={toggleDropdown} className="flex flex-col self-start pt-2 text-white rounded-lg bg-zinc-500 w-56">
      <div className="md:hidden text-xs font-medium text-center text-white-A700 px-2">
        Click the dropdown to access other categories
      </div>
      <div className="flex gap-4 items-center justify-center py-2 mt-1.5 text-base font-semibold whitespace-nowrap bg-[#506CFF] rounded-b-md md:pr-5 w-full">
        <div className="flex gap-2 items-center justify-center">
          <img src={img} alt="government_one" className="shrink-0 h-6" />
          <div className="my-auto text-white-A700 text-xs">{name}</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/638602d87b5b93baef726d2e212ad2388a1428c3bcfb7407cac98eba03a21890?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
            className="w-3.5"
          />
        </div>
      </div>
    </button>
  
    {isDropdownOpen && (
      <div className="absolute w-full mt-1 bg-white-A700 rounded-md shadow-lg z-50" >
        {categories.map((category) => (
          <NavLink
            key={category.name}
            to={`${category.route}`}
            className={`block py-2 px-4 cursor-pointer hover:bg-[#506CFF] hover:text-white-A700 ${
              category.name === name ? 'hidden' : ''
            }`}
            onClick={() => handleCategoryClick(category.name)}
          >
            {category.name}
          </NavLink>
        ))}
      </div>
    )}
  </div>
  
  
  );
};

export default CategoryDropdown;
