// FeedbackContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';

interface FeedbackContextProps {
  showFeedback: boolean;
  setShowFeedback: (show: boolean) => void;
}

const FeedbackContext = createContext<FeedbackContextProps | undefined>(undefined);

export const FeedbackProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFeedback(true);
    }, 180000); // 2 minutes in milliseconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <FeedbackContext.Provider value={{ showFeedback, setShowFeedback }}>
      {children}
    </FeedbackContext.Provider>
  );
};

export const useFeedback = (): FeedbackContextProps => {
  const context = useContext(FeedbackContext);
  if (context === undefined) {
    throw new Error('useFeedback must be used within a FeedbackProvider');
  }
  return context;
};
