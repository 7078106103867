import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Input, Img, Text, Heading, Button } from "../../../../components";
import { NavLink } from "react-router-dom";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import ImageSlider from "components/ImageSlider";
import Typed, { ReactTyped } from 'react-typed'; // Import react-typed
import Header from "components/Header";
import Settings from "components/Settings";
import AuthService from "services/authService";
import axiosInstance from '../../../../axiosSetup';

import axios from "axios";
import CategoryDropdown from "components/CategoryDropdown";

interface Page1Props {
  togglePage: () => void;
}
interface Job {
  title: string;
  description: string;
  company: string;
  location: string;
  redirectUrl: string;
  created: string;
}

interface ApiResponse {
  count: number;
  jobs: Job[];
}
interface Province {
  province: string;
  latitude: any;
  longitude: any;
}
const provinces: Province[] = [
  { province: "Alberta", latitude: 53.9333, longitude: -116.5765 },
  { province: "British Columbia", latitude: 53.7267, longitude: -127.6476 },
  { province: "Manitoba", latitude: 49.8951, longitude: -97.1384 },
  { province: "New Brunswick", latitude: 46.5653, longitude: -66.4619 },
  { province: "Newfoundland and Labrador", latitude: 53.1355, longitude: -57.6604 },
  { province: "Nova Scotia", latitude: 44.6820, longitude: -63.7443 },
  { province: "Ontario", latitude: 51.2538, longitude: -85.3232 },
  { province: "Prince Edward Island", latitude: 46.5107, longitude: -63.4168 },
  { province: "Quebec", latitude: 52.9399, longitude: -73.5491 },
  { province: "Saskatchewan", latitude: 52.9399, longitude: -106.4509 }
];
const suggestions = [
  'Software Engineer',
  'Data Scientist',
  'Mechanical Engineer',
  'Civil Engineer',
  'Project Manager',
  'Graphic Designer',
  'Financial Analyst',
  'Marketing Manager',
  'Human Resources Specialist',
  'Sales Representative',
  'Electrical Engineer',
  'Web Developer',
  'Product Manager',
  'Business Analyst',
  'Cybersecurity Specialist',
  'UX/UI Designer',
  'Network Administrator',
  'Database Administrator',
  'System Architect',
  'Quality Assurance Engineer',
  'Content Writer',
  'Social Media Manager',
  'SEO Specialist',
  'IT Support Specialist',
  'Operations Manager',
  'Management Consultant',
  'Legal Advisor',
  'Pharmacist',
  'Nurse Practitioner',
  'Biomedical Engineer',
];

function Page2({ togglePage}: Page1Props){
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isSearched, setisSearched] = useState(false);
    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const handleCloseMenu = () => {
      setMenuOpen(false);
    };
    const data = [
      { image: "/images/img_istockphoto_113.png", wherecani: "Where can i find healthcare facilities?" },
      { image: "/images/img_istockphoto_171.png", wherecani: "What are government policies in healthcare?" },
      { image: "/images/img_istockphoto_143.png", wherecani: "What are government policies in healthcare?" },
      { image: "/images/img_istockphoto_148.png", wherecani: "What are government policies in healthcare?" },
    ];

    const datas = [
      { 
       title: "Front End Developer",
       comapany: "The Max Company",
       country: "Canada",
       salary: "100k -200k",
       jobType: "Remote",
       description: "Help back-end developer with codeing and troubleshooting",
       description2: "Collaborate with back-end and designers to improve usability",
       time: "2 day",

     },
     { 
      title: "Back End Developer",
      comapany: "The Max Company",
      country: "Canada",
      salary: "150k -200k",
      jobType: "Remote",
      description: "Help back-end developer with codeing and troubleshooting",
      description2: "Collaborate with back-end and designers to improve usability",
      time: "3 day",

    },
     
    ];

    
    const [jobs, setJobs] = useState<Job[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [query, setQuery] = useState<string>('software developer');
    const [location, setLocation] = useState<string>('ontario');
    const [selectedProvince, setSelectedProvince] = useState<Province | null>(null);
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
  
    const handleAutoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInputValue(value);
      setisSearched(false);
      if (value) {
        const filtered = suggestions.filter(suggestion =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSuggestions(filtered);
        setShowSuggestions(true);
      } else {
        setShowSuggestions(false);
      }
    };
  
    const handleClick = (suggestion: string) => {
      setInputValue(suggestion);
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    };
  
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setInputValue(filteredSuggestions[activeSuggestion]);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
       
      } else if (e.key === 'ArrowUp') {
        if (activeSuggestion === 0) {
          return;
        }
        setActiveSuggestion(activeSuggestion - 1);
      } else if (e.key === 'ArrowDown') {
        if (activeSuggestion === filteredSuggestions.length - 1) {
          return;
        }
        setActiveSuggestion(activeSuggestion + 1);
      }
      
    };
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
     const province = provinces.find(p => p.province === event.target.value) || null;
     setSelectedProvince(province);
     setLocation(province.province);
   
    };
    const fetchJobListings = async () => {
      setLoading(true);
      setError(null);
     
      try {
       const token = localStorage.getItem("token");
       const response = await axiosInstance.post('https://linked-origin-server.vercel.app/api/v1/search/job-listings', 
       {"what":inputValue,
       "what_and":"",
       "what_phrase":"",
       "where":location,
       "page":1
       },
       {
         headers: {
           Authorization: `Bearer ${token}`,
         }
       });

  
        if (response.data && response.data.jobs) {
          setJobs(response.data.jobs);
          setisSearched(true);
        } else {
          setJobs([]);
          setisSearched(true);
        }
      } catch (err) {
        setError('Failed to fetch job listings');
        setisSearched(true);
      } finally {
        setLoading(false);
        setisSearched(true);
      }
    };
  
    const handleSearch = (e: React.FormEvent) => {
      e.preventDefault();
      fetchJobListings();
      
    };
  
    // useEffect(() => {
    //   fetchJobListings();
    // }, []);



 

  
      return (
        <>
          <Helmet>
            <title>Government Services - Assistance with Immigration and More</title>
            <meta
              name="description"
              content="Get help with immigration, employment, healthcare, and legal matters. Access government services and quick links to facilitate your settlement process."
            />
          </Helmet>
    
          <div className="  bg-center bg-cover bg-no-repeat my-auto  flex flex-col items-center">
              {/* header section */}
              <Header/>
              <div className="relative mx-auto flex w-full items-start justify-center  p-4 md:p-4   md:h-auto md:flex-col ">
              <Settings/>
              <div className="flex flex-col w-full h-full items-center">
              <div className="flex gap-5 justify-between md:flex-wrap w-full px-10 md:hidden animate__animated animate__fadeInUp">
              <CategoryDropdown
          name="Government"
          img= "/images/img_home.svg"
         />
      <div className="flex flex-col px-5 text-center">
        <div className="flex flex-col px-1.5 pt-2 pb-1.5 text-base font-semibold rounded-2xl bg-zinc-100">
          <div onClick={togglePage}  className="text-black text-xs cursor-pointer">Ask Mon-Ami</div>
          <div className="justify-center text-xs text-white-A700 px-9 py-2 mt-2 text-white whitespace-nowrap bg-gray-700 rounded-xl md:px-5 cursor-pointer">
            Search
          </div>
        </div>
        <div className="mt-2.5 text-xs leading-4 text-stone-900">
          Toggle to switch between <br />
          Mon-Ami & search
        </div>
      </div>
               </div>
            
             <p className="md:block hidden  md:text-2xl text-3xl text-left self-start bg-gradient2 bg-clip-text !text-transparent !font-kumbhsans ">
             Government
     </p>
                {/* introductory section */}
                <div className="mb-7    h-full flex w-[80%] flex-col items-start gap-10 md:w-full md:gap-5 sm:gap-10e">
                  {/* questions list section */}
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col   md:my-0">
      <div className="ml-20 md:ml-0  md:flex-col flex flex-row w-full items-end justify-end gap-2   ">
        
        <div className="pt-10 md:pt-0 flex flex-col items-end gap-[3px]  w-full">
                        <div className="mr-7 flex w-[27%] flex-wrap justify-end gap-5 md:mr-0 md:w-full">
                          <Heading onClick={togglePage}  className="cursor-pointer !text-gray-800 text-base md:text-sm ">
                            Ask Mon-Ami
                          </Heading>
                          <Heading   className="cursor-pointer !text-gray-800 text-base md:text-sm border-b-2 border-red-500 ">
                              Search
                          </Heading>
                        </div>
                        <div className="self-stretch rounded-md  bg-white-A700 p-[9px]md:p-0 md:pt-2 border border-gray-400 md:border-none">
                        <form onSubmit={handleSearch}>
                            <div className="flex flex-col gap-[27px]">
                            <select
        onChange={handleChange}
        defaultValue=""
        className="p-2 border border-gray-300 text-base md:text-sm rounded w-full"
      >
        <option value="" disabled>Select a province</option>
        {provinces.map((province, index) => (
          <option key={index} value={province.province}>{province.province}</option>
        ))}
   
      </select>
                              <div className="flex items-center justify-between gap-5 h-12  border border-gray-200 p-2 rounded-md">

                                <div  className="flex items-center w-full text-base h-full md:text-sm outline-none border-none focus:outline-none border border-black-900_2d ">
                             
                                  {/* <input
                                      type="text"
                                      placeholder="Search job title,keywords or company"
                                      value={query}
                                      onChange={(e) => setQuery(e.target.value)}
                                  /> */}
                          <div className="relative w-full" style={{ zIndex: 10 }}>
                          <input
                            type="text"
                            // className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={inputValue}
                            onChange={handleAutoChange}
                            onKeyDown={handleKeyDown}
                            className="z-0"
                            placeholder="Type to search job title, keywords or company"
                            
                            />
                          {showSuggestions && inputValue && (
                            <ul className="absolute z-10 w-full bg-white  rounded-md mt-1  overflow-auto">
                              {filteredSuggestions.length ? (
                                filteredSuggestions.map((suggestion, index) => (
                                  <li
                                    key={suggestion}
                                    className={`px-4 py-2 cursor-pointer bg-white-A700 hover:bg-red-400 hover:text-white-A700 border border-gray-300 ${
                                      index === activeSuggestion ? 'text-white' : ''
                                    }`}
                                    onMouseDown={() => handleClick(suggestion)}
                                    onMouseEnter={() => setActiveSuggestion(index)}
                                  >
                                    {suggestion}
                                  </li>
                                ))
                              ) : (
                                <li className="px-4 py-2"></li>
                              )}
                            </ul>
                           )}
                          </div>
              
          
                               
  
                               </div>
                               <div className= "flex flex-row h-full items-center justify-between gap-2 pl-2 w-[20%]   border-l border-blue_gray-100_01 md:hidden">
                              
                              
                                <button  type="submit" className= " justify-center self-end  w-32 text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-8  rounded-[23px]" > search
                                </button>
                               </div>
                            
                              </div>
                           
                            </div>
                             <button className= "hidden md:block justify-center md:w-full  mt-4 w-[15%] text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-12  rounded-[23px]" > Find a job
                            </button>
                            </form>
                          </div>
        </div>
        {/* <button  className= "hidden md:block justify-center md:w-full  w-[15%] text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-14  rounded-[23px]" > Find a job
        </button> */}
        <button className= "invisible md:hidden  justify-center md:w-full  w-[15%] text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-14  rounded-[23px]" > Post your resume
        </button>
        </div>
      
      </div>
   
    
                    {/* healthcare questions section */}
                    <div className="ml-20 md:ml-0 mr-10 md:mr-0 flex flex-col my-10 md:my-5 ">

                  
                    <div>
         
                    {loading && 
            <div className="flex flex-col gap-4 mt-2 w-full overflow-hidden">  
            <div className="flex  w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
            <div className="flex  w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
            <div className="flex w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>

            </div>}
            {error && <div>{error}</div>}
            
            {!loading && !error && jobs.length > 0 && inputValue!==""&&(
       <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
          {jobs.map((job, index) => (
                

<div key={"listwherecani" + index} className="flex flex-col w-full bg-gray-100 border border-gray-200 p-5 items-center my-2 gap-2 animate__animated animate__fadeInUp">
                          <p  className="text-xl font-bold self-start">
                           {job.title}
                            </p>
                            <p  className="text-base font-normal self-start">
                           {job.company}
                            </p>
                          <p  className="text-xs self-start">
                          {job.location}
                          </p>
                          <div className="flex flex-row md:flex-col w-full gap-5 md:gap-2 items-start my-2 ">
                         
                          </div>
                          <p  className="text-xs self-start my-2">
                          * {job.description}
                          </p>
                         

                          <p  className="text-xs self-start my-2"><strong >Posted on:</strong> {new Date(job.created).toLocaleDateString()}</p>

                          <a  className="text-xs self-start my-2" href={job.redirectUrl} target="_blank" rel="noopener noreferrer">View More</a>
                        </div>
                       
                    
                ))}
        </div>
      )}
      
      {!loading && !error && jobs.length === 0 && inputValue===""&& isSearched===false&& 
      <div className="flex flex-col"> 
      
      <p className=" md:text-xl text-xl self-start !font-kumbhsans ">
      Explore opportunities
     </p>
      <div className="mt-2  text-sm mdL text-gray-600 w-full">
      Navigate visas, citizenship, & find government support.</div>
      <div className="mt-2 text-sm  text-gray-600 w-full">
      Access social services, job search and assistance.</div>
      <div className="mt-2  text-sm  text-gray-600 w-full">
      Understand your rights and find resources for legal help with immigration, work & other needs.</div>
     
      </div>
      
      }
      {!loading && !error && jobs.length === 0 && isSearched===true&& <div className="flex flex-col"> 
      
      <div className="mt-5  text-sm text-black w-full font-bold">
      Oppss!!!, no job available
       </div></div>}
      </div>
    
                  
                    </div>
                   
                    </div>
    
            
                </div>
    

              </div>
           
              </div>
          
    
    
            </div>
    
       
        </>
      );
  }

export default Page2;
