import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { toast, Bounce, ToastContainer } from "react-toastify";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { NavLink, useNavigate } from "react-router-dom";
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { Img, Heading, Button,Text } from "components";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import { scroller } from 'react-scroll';

export default  function AboutPage() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>('');
   const [message, setMessage] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {

      const response = await axios.post('https://linked-origin-server.vercel.app/api/v1/subscription/newsletter/subscribe', {
        email,
      });
      if (response.status === 201||response.status === 200) {
        setMessage('Subscription successful!');
        toast.success('Email subscription sent successfully', {
          position:"top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setLoading(false);
      } else {
        setMessage('Subscription failed. Please try again.');
        setLoading(false);

      }
    } catch (error) {
      console.error('There was an error subscribing to the newsletter!', error);
      setMessage('Subscription failed. Please try again.');
      setLoading(false);

    }
  };
    useEffect(() => {
      // Check local storage for user data
      const userData = localStorage.getItem('userData');
      if (userData) {
        // If user data is found, consider the user logged in
        setLoggedIn(true);
      }
    }, []);
    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const handleCloseMenu = () => {
      setMenuOpen(false);
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Display two items at a time
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768, // Adjust breakpoint as needed
          settings: {
            slidesToShow: 1, // Show one item at a time on smaller screens
          },
        },
      ],
      
    };
    const items = [
        // { id: 1, name: 'Government',icon:'images/img_home.svg' },
        // { id: 2, name: 'Community',icon:'images/img_settings.svg'  },
        // { id: 3, name: 'Educational' ,icon:'images/img_thumbs_up.svg' },
        // { id: 4, name: 'Healthcare',icon:'images/img_group_55.svg'  },
        // { id: 5, name: 'Legal & Immigration service' ,icon:'images/img_group_52.svg' },
        // { id: 6, name: 'Cultural & Recreational Activities',icon:'images/img_thumbs_up_white_a700.svg'  },
        // { id: 7, name: 'Finance',icon:'images/img_settings_white_a700.svg'  },
        // { id: 8, name: 'Social',icon:'images/img_group_59.svg'  },
        // { id: 9, name: 'Investment' ,icon:'images/img_group_57.svg' },
        { icon: "/images/img_home.svg", name: "Government", route: 'government' },
        { icon: "/images/img_settings.svg", name: "Community" ,route: 'community' },
        { icon: "/images/img_thumbs_up.svg", name: "Educational" ,route: 'educational'},
        { icon: "/images/img_group_55.svg", name: "Healthcare" ,route: 'healthcare'},
        { icon: "/images/img_group_52.svg", name: "Legal & Immigration service",route: 'immigration' },
        {
          icon: "/images/img_thumbs_up_white_a700.svg",
          name: "Cultural & Recreational Activities"
          ,route: 'cultural'
        },
        { icon: "/images/img_settings_white_a700.svg", name: "Finance",route: 'finance' },
        { icon: "/images/img_group_59.svg", name: "Social" ,route: 'social'},
        { icon: "/images/img_group_57.svg", name: "Local Resource" ,route: 'investment'},
      ];

      const images = [
        'images/img_slider_1.png',
        'images/img_slider_2.png',
        'images/img_slider_3.png',
        'images/img_slider_4.png',
        // 'images/img_slider_4.png',
        // 'images/img_slider_3.png',
        // 'images/img_slider_2.png',
        // 'images/img_slider_1.png',

      ];
     


    const handlecategory = () => {
      toast.warn('Sign up to get AI powered assistance in this category', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      
    };
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const servicesRef = useRef(null);
    const portfolioRef = useRef(null);
    const contactRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate__animated', 'animate__fadeInUp');
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the element is visible
      );
  
      const sections = [homeRef.current, aboutRef.current, servicesRef.current, portfolioRef.current, contactRef.current];
      sections.forEach((section) => {
        if (section) {
          observer.observe(section);
        }
      });
  
      return () => {
        sections.forEach((section) => {
          if (section) {
            observer.unobserve(section);
          }
        });
      };
    }, []);
    const [ref, inView] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refabout, inViewAbout] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refLeft, inViewLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refRight, inViewRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThing, inViewEveryThing] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refEveryThingRight, inViewEveryThingRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refDownloadBottom, inViewDownloadBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refDownloadLeft, inViewDownloadLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refDownloadRight, inViewDownloadRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refBottom, inViewBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refAboutLeft, inViewAboutLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refAboutRight, inViewAboutRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refAboutBottom, inViewAboutBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const animation = useSpring({
      opacity: 1,
      transform: 'translateY(0)',
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 1000 },
    });
    const animationLeft = useSpring({
      opacity: inViewLeft ? 1 : 0,
      transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationRight = useSpring({
      opacity: inViewRight ? 1 : 0,
      transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationEveryThing = useSpring({
      opacity: inViewEveryThing ? 1 : 0,
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 700 },
    });
    const animationEveryThingLeft = useSpring({
      opacity: inViewEveryThingLeft ? 1 : 0,
      transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationEveryThingRight = useSpring({
      opacity: inViewEveryThingRight ? 1 : 0,
      transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationEveryThingBottom = useSpring({
      opacity: inViewEveryThingBottom ? 1 : 0,
      transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationAbout = useSpring({
      opacity: 1,
      transform: 'translateY(0)',
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 700 },
    });
  
  
    const bottomAnimation = useSpring({
      opacity: inViewBottom ? 1 : 0,
      transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
      config: { duration: 700 }, // Adjust animation duration
    });
    const animationAboutLeft = useSpring({
      opacity: inViewAboutLeft ? 1 : 0,
      transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationAboutRight = useSpring({
      opacity: inViewAboutRight ? 1 : 0,
      transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const bottomAboutAnimation = useSpring({
      opacity: inViewAboutBottom ? 1 : 0,
      transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
      config: { duration: 700 }, // Adjust animation duration
    });
    const navigate = useNavigate();

  const navigateToAboutSection = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo('service', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }, 100);
  };
  const navigateToContactSection = () => {
    navigate('/contact-us');
  };
  return (
    <div className="flex flex-col items-center bg-white w-full">
         <div className="flex flex-col items-center self-stretch">
            <div className="h-screen   relative  bg-[url(/public/images/about-hero.png)] bg-cover bg-no-repeat md:bg-center w-full md:pb-5 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none">
              <div className="absolute h-full w-full  bg-black-900 opacity-30 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none"></div>
                      <div className="flex absolute h-full w-full flex-col items-center">
                      <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5 p-10 md:p-4  bg-[#191919D1] bg-opacity-20 right-3 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none" ref={navbarRef}>
        <div className="container mx-auto flex  justify-between items-center">
        <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-8 md:h-6 " />

          <div className="hidden md:block  ">
            {isMenuOpen ? (
              <button 
                onClick={handleCloseMenu}
                className="text-white-A700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button 
                onClick={handleMenuToggle}
                className="text-white-A700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            )}
          </div>
  
          {isMenuOpen && (
            <div className="hidden md:block m-auto items-center  justify-center  absolute top-14 right-0 left-0  bg-black-900 bg-opacity-80 w-full h-auto shadow p-4">
             
          
          <div className="flex flex-col gap-5 text-white-A700" >
          <NavLink to="/">
          <div>Home</div>
            </NavLink>
          <NavLink to="/about">
          <div>About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/about">
          <div>Matching</div>
            </NavLink>
              {/* {loggedIn ? (  
      <>
        <NavLink to="/dashboard/welcome">
            <div className="text-white-A700">Continue</div>
            </NavLink>

  </>
      ) : (

        <NavLink to="/auth/login">
        <div className="text-white-A700">Login</div>
        </NavLink>
      )} */}
             
  
          </div>
            </div>
          )}
  
          <div className="md:hidden  flex flex-row  items-center justify-center">
    
          <div className="flex gap-5 justify-between  md:flex-wrap">
            <div className="flex gap-5 justify-between self-start text-black md:flex-wrap md:w-full">
            <NavLink to="/">
            <div className="text-[#898989]  hover:text-white-A700">Home</div>
            </NavLink>
            <NavLink to="/about">
            <div className="font-bold text-white-A700 ">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/matching/locals">
            <div className="cursor-pointer text-[#898989] hover:text-white-A700">Matching</div>
            </NavLink>
 
            
            </div>
            
           
          </div>
          </div>
          <div className="md:hidden  flex flex-row items-center gap-2">
  
    <div className="flex flex-row gap-4 items-center justify-center ">
                                
                                     <svg width="77" height="40" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.97 38.33C47.97 38.69 47.89 39.06 47.72 39.42C47.55 39.78 47.33 40.12 47.04 40.44C46.55 40.98 46.01 41.37 45.4 41.62C44.8 41.87 44.15 42 43.45 42C42.43 42 41.34 41.76 40.19 41.27C39.04 40.78 37.89 40.12 36.75 39.29C35.6 38.45 34.51 37.52 33.47 36.49C32.44 35.45 31.51 34.36 30.68 33.22C29.86 32.08 29.2 30.94 28.72 29.81C28.24 28.67 28 27.58 28 26.54C28 25.86 28.12 25.21 28.36 24.61C28.6 24 28.98 23.44 29.51 22.94C30.15 22.31 30.85 22 31.59 22C31.87 22 32.15 22.06 32.4 22.18C32.66 22.3 32.89 22.48 33.07 22.74L35.39 26.01C35.57 26.26 35.7 26.49 35.79 26.71C35.88 26.92 35.93 27.13 35.93 27.32C35.93 27.56 35.86 27.8 35.72 28.03C35.59 28.26 35.4 28.5 35.16 28.74L34.4 29.53C34.29 29.64 34.24 29.77 34.24 29.93C34.24 30.01 34.25 30.08 34.27 30.16C34.3 30.24 34.33 30.3 34.35 30.36C34.53 30.69 34.84 31.12 35.28 31.64C35.73 32.16 36.21 32.69 36.73 33.22C37.27 33.75 37.79 34.24 38.32 34.69C38.84 35.13 39.27 35.43 39.61 35.61C39.66 35.63 39.72 35.66 39.79 35.69C39.87 35.72 39.95 35.73 40.04 35.73C40.21 35.73 40.34 35.67 40.45 35.56L41.21 34.81C41.46 34.56 41.7 34.37 41.93 34.25C42.16 34.11 42.39 34.04 42.64 34.04C42.83 34.04 43.03 34.08 43.25 34.17C43.47 34.26 43.7 34.39 43.95 34.56L47.26 36.91C47.52 37.09 47.7 37.3 47.81 37.55C47.91 37.8 47.97 38.05 47.97 38.33Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
  <rect x="1" y="1" width="75" height="61" rx="30.5" stroke="white" stroke-width="2"/>
                                  </svg>
    </div>
    {loggedIn ? (  
      <>
        <NavLink to="/auth/login">
            <div className="text-white-A700 border border-white-A700 rounded-full p-3 text-sm">Logout</div>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div className="text-white-A700 border border-white-A700 rounded-full p-3 text-sm">Login</div>

        </NavLink>
      )}
    </div>
        </div>
                       </nav>
                      
                        {/* <div className="mt-20 flex  items-center justify-between rounded-[15px] bg-gray-100_89 p-[5px] animate__animated animate__fadeInLeft">
                          <Button
                            color="blue_gray_800"
                            size="lg"
                            variant="fill"
                            className=" rounded-[10px] font-inter font-semibold  hover:-translate-y-1 hover:scale-110 duration-300 text-base md:text-sm" 
                          >
                            Tour
                          </Button>
                          <Button
                            size="lg"
                            variant="fill"
                            className=" rounded-[10px] font-inter font-semibold hover:-translate-y-1 hover:scale-110 duration-300 "
                            
                          >
                            <Heading
                              size="md"
                              as="h1"
                              className="text-center !font-inter !font-semibold !text-white-A700 text-base md:text-sm"
                            >
                              Mon-Ami
                          </Heading>
                          </Button>
                          
                        </div> */}
                        <Heading  className="mb-4 mt-20 text-7xl md:text-5xl sm:text-3xl text-center !text-white-A700 animate__animated animate__fadeInRight w-[60%] md:w-full ">
                        Embrace the Adventure. 
Embrace Canada
                        </Heading>
                        {/* <Text  as="p" className="my-10 text-center text-4xl md:text-xl sm:text-2xl !text-white-A700 md:p-5 animate__animated animate__fadeInUp">
                          Let LinkedOrigins help you figure things out
                        </Text> */}
                        {loggedIn ? (  
      <>
        <NavLink to="/dashboard/welcome">
        <Button
                            color="white_A700"
                            className="rounded-[38px] font-inter font-semibold sm:px-5 hover:-translate-y-1 hover:scale-110 duration-300 animate__animated animate__fadeInUp"
                          >
                            Continue
                          </Button>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/register">
      <Button
                            color="white_A700"
                            className="rounded-[38px] font-inter font-semibold sm:px-5 hover:-translate-y-1 hover:scale-110 duration-300 animate__animated animate__fadeInUp"
                          >
                            Sign up
                          </Button>
        </NavLink>
      )}
         
                       
                      </div>
                    </div>
                    <Link
              activeClass="active"
              to="about"
              spy={true}
              smooth={true}
              // offset={-70}
              duration={500}
            >
    <Img
                src="images/img-hero-arrow.svg"
                alt="polygonone_one"
                className="md:hidden relative z-[1] mt-[-84px] h-[148px] w-[148px] rounded-[32px] cursor-pointer"
              />            </Link>
            </div >
    
      <Element name="about" className="w-full ">
      <div className="flex flex-col px-5 md:px-2 mt-40 w-full  md:mt-10 md:w-full">
        <div className="md:pr-5 md:w-full">
          <div className="flex gap-5 md:flex-col md:gap-4 px-20 md:px-4">
            <div className="flex flex-col w-[59%] md:ml-0 md:w-full">
              <div className="flex flex-col mt-14 md:mt-10 md:w-full">
                <div className="text-5xl  font-semibold  text-neutral-700 md:w-full md:text-2xl ">
                  Your Gateway to
                  Canadian Life.
                </div>
                <div className="mt-10 text-base md:text-sm text-stone-500 md:w-full mr-5 md:mr-0">
                  LinkedOrigins was born from a simple desire to help newcomers
                  navigate the exciting yet sometimes overwhelming journey of
                  settling in Canada. We understand the whirlwind of emotions,
                  the countless questions, and the yearning to connect with a
                  welcoming community. That's why we built LinkedOrigins – your
                  personalized guide to building a fulfilling life in Canada.
                  <br />
                </div>
              </div>
            </div>
            <div className="flex flex-col  w-[41%] md:ml-0 md:w-full">
              <img
                loading="lazy"
                src="images/mask-group-31@2x.png"
                  className="grow w-full md:mt-10 md:w-full"
              />
            </div>
          </div>
        </div>
        <div className="mt-40 text-5xl md:text-2xl font-semibold text-neutral-700 md:mt-10 md:w-full  w-[40%] px-20 md:px-4">
          Why LinkedOrigins?
        </div>
        <div className="mt-9 text-base md:text-sm text-neutral-700 md:w-full w-[40%] px-20 md:px-4">
          We believe every newcomer deserves a smooth and enriching transition.
          LinkedOrigins empowers you with  tools and resources.
          <br />
        </div>
        <div className="flex flex-col justify-center items-start self-end py-20 mt-16  bg-sky-50 rounded-lg w-full md:pr-0 md:px-4  md:mt-10">
          <div className="mt-4 mb-1.5 md:w-full">
          <div className="flex flex-col mt-3 text-3xl font-semibold text-black  md:w-full gap-10">
                  
                  <div className="flex flex-row md:flex-col w-full gap-4 mx-10 md:mx-0 h-96 ">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/38b62b26ebceb1197dbc285c7bf314e828810a34c4e75872a731a92e1b854b50?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/38b62b26ebceb1197dbc285c7bf314e828810a34c4e75872a731a92e1b854b50?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/38b62b26ebceb1197dbc285c7bf314e828810a34c4e75872a731a92e1b854b50?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/38b62b26ebceb1197dbc285c7bf314e828810a34c4e75872a731a92e1b854b50?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/38b62b26ebceb1197dbc285c7bf314e828810a34c4e75872a731a92e1b854b50?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/38b62b26ebceb1197dbc285c7bf314e828810a34c4e75872a731a92e1b854b50?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/38b62b26ebceb1197dbc285c7bf314e828810a34c4e75872a731a92e1b854b50?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/38b62b26ebceb1197dbc285c7bf314e828810a34c4e75872a731a92e1b854b50?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
                    className="w-[50%]  md:w-full h-96"
                  />
                  <div className="w-[50%] md:w-full flex flex-col pr-20 md:pr-4 text-5xl md:text-4xl h-96">
                  <div className="text-5xl  font-semibold  text-neutral-700 md:w-full md:text-2xl ">
                    Unlock a Wealth of Knowledge
                  </div>
                  <div className="mt-6 font-normal text-base md:text-sm md:w-full">
                    Our platform offers specialized AI support for every
                    category available. We are constantly fine tuning our AI to
                    fetch the right articles and resources on the various
                    categories, from education and healthcare to legal and
                    financial matters. If you need personalized advice? Chat
                    directly with LinkedOrigins' AI guide, Mon-Ami, who's here
                    to answer your questions and guide you on your path.
                    <br />
                  </div>
                  </div>
                  </div>
                 
                  <div className="flex flex-row md:flex-col w-full gap-4 mx-10 md:mx-0 h-96">
                  <img
                    loading="lazy"
                    src="/images/mask-group-61@2x.png"   
                    className="w-[50%] aspect-[2.04]  md:w-full h-96"
                  />
                  <div className="flex w-[50%] md:w-full flex-col pr-20 md:pr-4 h-96">
                  <div className="text-5xl   font-semibold  text-neutral-700 md:w-full md:text-2xl ">
                    Embrace Your New Home
                  </div>
                  <div className="mt-6 font-normal text-base md:text-sm md:w-full">
                    Moving to a new country can feel isolating. LinkedOrigins
                    fosters a sense of belonging. Connect with others who share
                    your background or interests, discover hidden gems in your
                    city with our search function. We have simplified the search
                    so you can easily find what you need using our drop down
                    menus. We are constantly refining until we fully understand
                    your experiences.
                  </div>
                  </div>
                  </div>
                
                  <div className="flex flex-row md:flex-col w-full gap-4 mx-10 md:mx-0 h-96">
                  <img 
                    loading="lazy"
                    src="/images/mask-group-81@2x.png"
                    className="w-[50%] aspect-[2.04]  md:w-full h-96"
                  />
                  <div className="flex w-[50%] md:w-full flex-col pr-20 md:pr-4 h-96">
                  <div className="text-5xl  font-semibold  text-neutral-700 md:w-full md:text-2xl ">
                    Shape Your Canadian Dream
                  </div>
                  <div className="mt-6 font-normal text-base md:text-sm md:w-full ">
                    Canada is a land brimming with opportunities. LinkedOrigins
                    empowers you to take charge. Our search function also allows
                    you to explore jobs, research educational programs that
                    align with your goals, and even find volunteer opportunities
                    to integrate into your community and build a fulfilling
                    life.
                  </div>
                  </div>
                  </div>
                 
                </div>
          </div>
        </div>
      </div>
      </Element>
      <div className="flex gap-10  justify-between self-stretch mt-24 w-full md:flex-wrap md:pr-5 md:mt-10 md:w-full">
        <div className="flex flex-col justify-center items-end pl-20 md:pl-4 py-12 bg-sky-50 md:w-full w-[50%]">
          <img
            loading="lazy"
            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/640ea2790cf6c53b0fa94704be2da63b515c0c833bfffa1e413c77a0a21d2ab5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/640ea2790cf6c53b0fa94704be2da63b515c0c833bfffa1e413c77a0a21d2ab5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/640ea2790cf6c53b0fa94704be2da63b515c0c833bfffa1e413c77a0a21d2ab5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/640ea2790cf6c53b0fa94704be2da63b515c0c833bfffa1e413c77a0a21d2ab5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/640ea2790cf6c53b0fa94704be2da63b515c0c833bfffa1e413c77a0a21d2ab5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/640ea2790cf6c53b0fa94704be2da63b515c0c833bfffa1e413c77a0a21d2ab5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/640ea2790cf6c53b0fa94704be2da63b515c0c833bfffa1e413c77a0a21d2ab5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/640ea2790cf6c53b0fa94704be2da63b515c0c833bfffa1e413c77a0a21d2ab5?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&"
            className="w-full aspect-[2.04] md:w-full"
          />
        </div>
        <div className="flex flex-col my-auto md:items-center md:justify-center  text-black md:w-full w-[50%] pr-20 md:pr-0 md:px-4 ">
          <div className="text-5xl font-semibold md:text-center  md:text-4xl">
            Our Mission
          </div>
          <div className="mt-11 text-base md:text-sm md:mt-10  md:text-center">
            LinkedOrigins is more than just a platform; it's poised to be a
            vibrant community built on inclusivity and support. We are
            passionate about empowering newcomers to feel welcome, informed, and
            confident as they embark on their Canadian adventure.
          </div>
        </div>
      </div>
      <Element name="contact" className="w-full ">
            <div className="mt-20 mb-20 flex flex-col items-center">

<Heading  className="text-6xl md:text-4xl sm:text-2xl  mt-20  !text-blue_gray-900 md:ml-0">
Ready to Start?
</Heading>
<Text
  size="5xl"
  as="p"
  className="text-2xl md:text-xl sm:text-sm  my-5 md:ml-0 text-center  leading-[41px] !text-gray-800_01  md:w-full md:p-5"
>
  <>
  Powered by AI, Our goal is to create the best settlement experience for you
  </>
</Text>
<NavLink to="/auth/register">
 <Button color="gray_600_04" shape="round" className="font-inter font-semibold">
    Get Started
 </Button>' 
</NavLink>
   
</div>
<div className=" mt-10 md:mt-0 flex w-full  flex-col justify-center items-center md:px-1 md:pt-5">
  <Img
    src="images/img_header_logo.svg"
    alt="image_six"
    className="relative z-[3] md:h-32  h-48 md:w-[70%] w-[35%] md:ml-0"
  />
  <div className=" -mt-20 flex md:flex-col justify-center items-center w-[80%] h-[500px]  md:h-auto md:w-full  rounded-t-[285px] md:rounded-t-[50px] bg-red-400 px-14 pt-32 md:px-5 ">
    <div className="mb-10 flex w-[40%] flex-col items-start justify-start gap-[23px] md:w-full h-full">
   
      <p className="">
        <Heading className="text-4xl md:text-2xl text-left sm:text-2x !text-white-A700">
        Join our Mailing List
        </Heading>
      </p>
      <div className="self-stretch">
        <p  className="text-sm !text-white-A700 w-full">
          <>
            Subscribe to our newsletter for immigration 
            insights and travel news!
          </>
        </p>
       
        <form onSubmit={handleSubmit}>
<div  className="md:ml-0  my-5 font-inter font-semibold p-2 border border-gray-400  rounded-md"> <input
type="email"
id="email"
value={email}

onChange={(e) => setEmail(e.target.value)}
required
/></div>
{loading?(<button
 
    disabled
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Loading
  </button>):(<button
 type="submit"
 
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Subscribe
  </button>)}

</form>
{message && <p className="text-white-A700"> {message}</p>}
        <HomeScreenRowcloseOne className="mt-10 flex items-center justify-between gap-5 md:hidden " />

      </div>
    </div>
    <div className="mb-10 flex w-[40%] flex-row items-start justify-between px-10 md:px-4 gap-[23px] md:w-full  h-full">
    <div className="flex flex-col items-start justify-start">
      <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
       Company
        </Heading>
   <NavLink to="/about">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">About us</div>
</NavLink>
<NavLink to="/feedback">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Feedback</div>
</NavLink>
<NavLink to="/vendor">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Vendors Form</div>
</NavLink>

<NavLink to="/contact-us">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Contact us</div>
</NavLink>
<NavLink to="/matching/locals">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Matching</div>
</NavLink>
      </div>
      <div className="flex flex-col items-start justify-start">
      <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
       Legal
        </Heading>
   <NavLink to="/privacy-policy">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Privacy Policy</div>
</NavLink>
<NavLink to="/terms-service">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Terms and conditions</div>
</NavLink>

      </div>

 </div>
 <HomeScreenRowcloseOne className="my-10 md:flex items-center justify-between gap-5  hidden " />

  </div>
</div>
      </Element>
    </div>
  );
}


