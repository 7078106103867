import React from "react";
import { FunctionComponent } from "react";

export type FootersType = {
  className?: string;
};

const FootersMonami: FunctionComponent<FootersType> = ({
  className = "",
}) => {
  return (
    <p className=" w-full flex justify-center items-center mb-10 md:text-xs md:text-center text-base  bg-gradient2 bg-clip-text !text-transparent !font-kumbhsans ">
    Mon-Ami is a third party tool, results are general guidance and may vary in accuracy.
   </p>
  );
};

export default FootersMonami;
