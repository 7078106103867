import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Img, Heading, Button, Text } from "../../components";
import { NavLink, useNavigate } from "react-router-dom";
import AuthService from "services/authService";
import { Alert, Box, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import { toast, Bounce, ToastContainer } from "react-toastify";
import { CgSpinner } from "react-icons/cg";
import { scroller } from 'react-scroll';


export default function ForgertPassword() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isssent, setIsSent] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate()

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

const handleSubmit = async (e) => {
    e.preventDefault();

    // Set loading to true when submitting the form
    setLoading(true);
    setError('');
    const { success, error } = await AuthService.login(email, password);

    // Reset loading state after login attempt
    setLoading(false);

    if (success) {
      setError('');
      navigate("/dashboard/welcome");
    
    }else{
      setError(error);
     
    }
  };
  const navigateToAboutSection = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo('service', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }, 100);
  };
  const navigateToContactSection = () => {
    navigate('/contact-us');
  };
  return (
    <>
    <Helmet>
    <title>LinkedOrigin</title>
        <meta name="description" content="Simplify your settlement journey with AI and streamlined resources" />

    </Helmet>
    <div className='h-screen w-full relative bg-white-A700' >

    <Img
            src="/images/landing_bg.svg"
            alt="image_two"
            className=" bottom-0 left-0 fixed"
          />
            
  <div className="absolute flex w-full mb-10 flex-col gap-5  ">
      <div>
      <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5 p-10 md:p-4  bg-[#fff]  md:border-gray-300 md:border-b bg-opacity-20 right-3 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none" ref={navbarRef}>
        <div className="container mx-auto flex  justify-between items-center">
        <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-8 md:h-6 " />

          <div className="hidden md:block  ">
            {isMenuOpen ? (
              <button 
                onClick={handleCloseMenu}
                className="text-[#898989] focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button 
                onClick={handleMenuToggle}
                className="text-[#898989] focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            )}
          </div>
  
          {isMenuOpen && (
            <div className="hidden md:block m-auto items-center  justify-center  absolute top-14 right-0 left-0  bg-black-900 z-50 w-full h-auto shadow p-4">
             
          
          <div className="flex flex-col gap-5 text-white-A700" >
          <NavLink to="/">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">Home</div>
            </NavLink>
          <NavLink to="/about">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/matching/locals">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">Matching</div>
            </NavLink>
            {loggedIn ? (  
      <>
        <div  onClick={() => {
               AuthService.logout();
               navigate("/auth/login");
             }} >Logout</div>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div>Login</div>

        </NavLink>
      )}
             
  
          </div>
            </div>
          )}
  
          <div className="md:hidden  flex flex-row  items-center justify-center">
    
          <div className="flex gap-5 justify-between  md:flex-wrap">
            <div className="flex gap-5 justify-between self-start text-black md:flex-wrap md:w-full">
            <NavLink to="/">
            <div className="text-[#191919D1]  hover:text-orange-300">Home</div>
            </NavLink>
            <NavLink to="/about">
            <div className=" text-[#191919D1] ">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#191919D1] hover:text-orange-300">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#191919D1] hover:text-orange-300">Contact Us</div>
            <NavLink to="/matching/locals">
            <div className="cursor-pointer text-[#191919D1] hover:text-orange-300">Matching</div>
            </NavLink>
 
            
            </div>
            
           
          </div>
          </div>
          <div className="md:hidden  flex flex-row items-center gap-2">
  
    <div className="flex flex-row gap-4 items-center justify-center ">
                                
  <svg width="77" height="40" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.97 38.33C47.97 38.69 47.89 39.06 47.72 39.42C47.55 39.78 47.33 40.12 47.04 40.44C46.55 40.98 46.01 41.37 45.4 41.62C44.8 41.87 44.15 42 43.45 42C42.43 42 41.34 41.76 40.19 41.27C39.04 40.78 37.89 40.12 36.75 39.29C35.6 38.45 34.51 37.52 33.47 36.49C32.44 35.45 31.51 34.36 30.68 33.22C29.86 32.08 29.2 30.94 28.72 29.81C28.24 28.67 28 27.58 28 26.54C28 25.86 28.12 25.21 28.36 24.61C28.6 24 28.98 23.44 29.51 22.94C30.15 22.31 30.85 22 31.59 22C31.87 22 32.15 22.06 32.4 22.18C32.66 22.3 32.89 22.48 33.07 22.74L35.39 26.01C35.57 26.26 35.7 26.49 35.79 26.71C35.88 26.92 35.93 27.13 35.93 27.32C35.93 27.56 35.86 27.8 35.72 28.03C35.59 28.26 35.4 28.5 35.16 28.74L34.4 29.53C34.29 29.64 34.24 29.77 34.24 29.93C34.24 30.01 34.25 30.08 34.27 30.16C34.3 30.24 34.33 30.3 34.35 30.36C34.53 30.69 34.84 31.12 35.28 31.64C35.73 32.16 36.21 32.69 36.73 33.22C37.27 33.75 37.79 34.24 38.32 34.69C38.84 35.13 39.27 35.43 39.61 35.61C39.66 35.63 39.72 35.66 39.79 35.69C39.87 35.72 39.95 35.73 40.04 35.73C40.21 35.73 40.34 35.67 40.45 35.56L41.21 34.81C41.46 34.56 41.7 34.37 41.93 34.25C42.16 34.11 42.39 34.04 42.64 34.04C42.83 34.04 43.03 34.08 43.25 34.17C43.47 34.26 43.7 34.39 43.95 34.56L47.26 36.91C47.52 37.09 47.7 37.3 47.81 37.55C47.91 37.8 47.97 38.05 47.97 38.33Z" stroke="#191919D1" stroke-width="1.5" stroke-miterlimit="10"/>
  <rect x="1" y="1" width="75" height="61" rx="30.5" stroke="#191919D1" stroke-width="2"/>
  </svg>
    </div>
    {loggedIn ? (  
      <>
        <NavLink to="/auth/login">
            <div className="text-[#191919D1] border border-[#191919D1] rounded-full p-3 text-sm">Logout</div>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div className="text-[#191919D1] border border-[#191919D1] rounded-full p-3 text-sm">Login</div>

        </NavLink>
      )}
    </div>
        </div>
            </nav>
      </div>
      {isssent?(<form onSubmit={handleSubmit} className='w-full'>

<div className=" self-end mt-20 md:mt-10 w-full flex flex-col  items-start justify-center animate__animated animate__fadeInUp">
<div className=" ">
<Text size="7xl" as="p" className="font-bold !text-blue_gray-700 px-10 md:px-10">
Check your email 
          </Text>
</div>
    <div className="flex flex-col items-center justify-center w-full ">
 
         <div className="w-[50%] md:w-full md:p-5  ">
         <div className=" self-center mx-auto flex w-80 md:w-60 md:my-4  items-center justify-between gap-2 rounded-[15px] bg-gray-100_89 p-[5px] animate__animated animate__fadeInLeft">
                           <NavLink to="/">
                           <Button
                            size="lg"
                            variant="fill"
                            className=" rounded-[10px] font-inter font-semibold text-base md:text-sm "
                          >
                            Tour
                          </Button>
              </NavLink>
                         
                          <Button
                            color="blue_gray_800"
                            size="lg"
                            variant="fill"
                            className=" rounded-[10px] font-inter font-semibold "
                            
                          >
                            <Heading
                              size="md"
                              as="h1"
                              className="text-center !font-inter !font-semibold !text-white-A700 text-base md:text-sm"
                            >
                              Mon-Ami
                          </Heading>
                          </Button>
                          
 </div> 
<div className="flex flex-col items-center px-5 text-base font-semibold leading-9 text-black ">
      <div className="self-stretch w-full text-xl md:w-full">
        <span className="">We sent a password reset link to </span>
        <span className="font-medium text-black">ufuoma@linkedorigin.com</span>
      </div>
      <div className="justify-center px-12 py-7 mt-10 text-white bg-blue-600 rounded-[31.5px] max-md:px-5">
        Check email
      </div>
      <div className="mt-11 max-md:mt-10">
        <span className="text-lg leading-9 text-black ">
          Didn’t receive email?{" "}
        </span>
        <span className="text-lg font-medium leading-9 text-black">
          Click to resend
        </span>
      </div>
      <NavLink  to="/auth/login">
      <div className="mt-52  max-md:mt-10">
        <span className="text-base ">Back to</span>{" "}
        <span className="text-base text-blue-600">Sign in</span>
      </div>
       </NavLink>
                          
   
    </div>
          
         </div>
         
          
    </div>
</div>
</form>):(<form onSubmit={handleSubmit} className='w-full'>

<div className=" self-end mt-20 md:mt-10 w-full flex flex-col  items-start justify-center animate__animated animate__fadeInUp">
<div className=" ">
<Text size="7xl"  className="font-bold !text-blue_gray-700 px-10 md:px-4">
Forget Password
          </Text>
</div>
    <div className="flex flex-col items-center justify-center w-full ">
 
         <div className="w-[50%] md:w-full md:p-5  ">
         <div className=" self-center mx-auto flex w-80 md:w-60 md:my-4  items-center justify-between gap-2 rounded-[15px] bg-gray-100_89 p-[5px] animate__animated animate__fadeInLeft">
                           <NavLink to="/">
                           <Button
                            size="lg"
                            variant="fill"
                            className=" rounded-[10px] font-inter font-semibold text-base md:text-sm "
                          >
                            Tour
                          </Button>
              </NavLink>
                         
                          <Button
                            color="blue_gray_800"
                            size="lg"
                            variant="fill"
                            className=" rounded-[10px] font-inter font-semibold "
                            
                          >
                            <Heading
                              size="md"
                              as="h1"
                              className="text-center !font-inter !font-semibold !text-white-A700 text-base md:text-sm"
                            >
                              Mon-Ami
                          </Heading>
                          </Button>
                          
 </div> 
    <div className="flex  justify-center py-7 mt-10 text-white items-center w-full ">
      No worries, we’ll send you reset instructions.
      </div>
         <div className="mb-4 w-full" >
            <label className="mb-2.5 block font-medium  text-black ">
              Email
            </label>
            <TextField
placeholder="Email"
type="email"
className='w-full self-stretch flex'
onChange={handleEmailChange}
value={email}
sx={{
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#E0E0E0', // Set background color
    '& fieldset': {
      borderColor: '#E0E0E0', // Set border color
    },
    '&:hover fieldset': {
      borderColor: '#E0E0E0', // Set border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: '#E0E0E0', // Set border color on focus
    },
  },
 
  '& .MuiInputBase-root': {
    marginTop: 0, // Remove top padding
    marginBottom: 0, // Remove bottom padding
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent', // Remove outline by default
  },
}}
fullWidth
margin="normal"
variant="outlined"
required

/>

          </div>
          <div className="flex  justify-center py-7 mt-10 text-white items-center w-full ">
          <Button
                color="gray_500_02"
              
                className="rounded-[38px] font-inter font-medium sm:px-5"
              >
               Reset Password
              </Button>
      </div>
        
          
         </div>
         
          
    </div>
</div>
</form>)}
      

    </div>
    <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </div>

  </>
  );
}
