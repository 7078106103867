import axios from "axios";
import { Button, Heading, Img,Text } from "components";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";

import * as React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { scroller } from 'react-scroll';
import { Bounce, toast } from "react-toastify";
import AuthService from "services/authService";

interface ImageProps {
  src: string;
  alt: string;
  className: string;
}

const Image: React.FC<ImageProps> = ({ src, alt, className }) => (
  <img loading="lazy" src={src} alt={alt} className={className} />
);

const MatchingLocalsPage: React.FC = () => {
  const [isMenuOpen, setMenuOpen] = React.useState(false);
  const navbarRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [isssent, setIsSent] = React.useState(false);
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };
  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState<string>('');
  const [message, setMessage] = React.useState<string>('');
  // const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('https://linked-origin-server.vercel.app/api/v1/subscription/newsletter/subscribe', {
        email,
      });
  
      if (response.status === 200 || response.status === 201) {
        setMessage('Subscription successful!');
        toast.success('Email subscription sent successfully', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else if (response.status === 409) {
        setMessage('Email already registered');
        toast.warn('Email already registered', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        setMessage('Subscription failed. Please try again.');
        toast.error('Subscription failed. Please try again.', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setMessage('Email already registered');
        toast.warn('Email already registered', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } else {
        console.error('There was an error subscribing to the newsletter!', error);
        setMessage('Subscription failed. Please try again.');
        toast.error('There was an error subscribing to the newsletter!', {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const [showPassword, setShowPassword] = React.useState(false); 
  const navigate = useNavigate()

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };


  const navigateToAboutSection = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo('service', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }, 100);
  };
  const navigateToContactSection = () => {
    navigate('/contact-us');
  };
  return (
    <>
    <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5 p-10 md:p-4  bg-[#fff]  md:border-gray-300 md:border-b bg-opacity-20 right-3 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none" ref={navbarRef}>
        <div className="container mx-auto flex  justify-between items-center">
        <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-8 md:h-6 " />

          <div className="hidden md:block  ">
            {isMenuOpen ? (
              <button 
                onClick={handleCloseMenu}
                className="text-[#898989] focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button 
                onClick={handleMenuToggle}
                className="text-[#898989] focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            )}
          </div>
  
          {isMenuOpen && (
            <div className="hidden md:block m-auto items-center  justify-center  absolute top-14 right-0 left-0  bg-black-900 z-50 w-full h-auto shadow p-4">
             
          
          <div className="flex flex-col gap-5 text-white-A700" >
          <NavLink to="/">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">Home</div>
            </NavLink>
          <NavLink to="/about">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/matching/locals">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">Matching</div>
            </NavLink>
            {loggedIn ? (  
      <>
        <div  onClick={() => {
               AuthService.logout();
               navigate("/auth/login");
             }} >Logout</div>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div>Login</div>

        </NavLink>
      )}
             
  
          </div>
            </div>
          )}
  
          <div className="md:hidden  flex flex-row  items-center justify-center">
    
          <div className="flex gap-5 justify-between  md:flex-wrap">
            <div className="flex gap-5 justify-between self-start text-black md:flex-wrap md:w-full">
            <NavLink to="/">
            <div className="text-[#191919D1]  hover:text-orange-300">Home</div>
            </NavLink>
            <NavLink to="/about">
            <div className=" text-[#191919D1] ">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#191919D1] hover:text-orange-300">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#191919D1] hover:text-orange-300">Contact Us</div>
            <NavLink to="/matching/locals">
            <div className="cursor-pointer text-[#191919D1] hover:text-orange-300">Matching</div>
            </NavLink>
 
            
            </div>
            
           
          </div>
          </div>
          <div className="md:hidden  flex flex-row items-center gap-2">
  
    <div className="flex flex-row gap-4 items-center justify-center ">
                                
  <svg width="77" height="40" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.97 38.33C47.97 38.69 47.89 39.06 47.72 39.42C47.55 39.78 47.33 40.12 47.04 40.44C46.55 40.98 46.01 41.37 45.4 41.62C44.8 41.87 44.15 42 43.45 42C42.43 42 41.34 41.76 40.19 41.27C39.04 40.78 37.89 40.12 36.75 39.29C35.6 38.45 34.51 37.52 33.47 36.49C32.44 35.45 31.51 34.36 30.68 33.22C29.86 32.08 29.2 30.94 28.72 29.81C28.24 28.67 28 27.58 28 26.54C28 25.86 28.12 25.21 28.36 24.61C28.6 24 28.98 23.44 29.51 22.94C30.15 22.31 30.85 22 31.59 22C31.87 22 32.15 22.06 32.4 22.18C32.66 22.3 32.89 22.48 33.07 22.74L35.39 26.01C35.57 26.26 35.7 26.49 35.79 26.71C35.88 26.92 35.93 27.13 35.93 27.32C35.93 27.56 35.86 27.8 35.72 28.03C35.59 28.26 35.4 28.5 35.16 28.74L34.4 29.53C34.29 29.64 34.24 29.77 34.24 29.93C34.24 30.01 34.25 30.08 34.27 30.16C34.3 30.24 34.33 30.3 34.35 30.36C34.53 30.69 34.84 31.12 35.28 31.64C35.73 32.16 36.21 32.69 36.73 33.22C37.27 33.75 37.79 34.24 38.32 34.69C38.84 35.13 39.27 35.43 39.61 35.61C39.66 35.63 39.72 35.66 39.79 35.69C39.87 35.72 39.95 35.73 40.04 35.73C40.21 35.73 40.34 35.67 40.45 35.56L41.21 34.81C41.46 34.56 41.7 34.37 41.93 34.25C42.16 34.11 42.39 34.04 42.64 34.04C42.83 34.04 43.03 34.08 43.25 34.17C43.47 34.26 43.7 34.39 43.95 34.56L47.26 36.91C47.52 37.09 47.7 37.3 47.81 37.55C47.91 37.8 47.97 38.05 47.97 38.33Z" stroke="#191919D1" stroke-width="1.5" stroke-miterlimit="10"/>
  <rect x="1" y="1" width="75" height="61" rx="30.5" stroke="#191919D1" stroke-width="2"/>
  </svg>
    </div>
    {loggedIn ? (  
      <>
        <NavLink to="/auth/login">
            <div className="text-[#191919D1] border border-[#191919D1] rounded-full p-3 text-sm">Logout</div>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/register">
                    <div className="text-[#191919D1] border border-[#191919D1] rounded-full p-3 text-sm">Register</div>

        </NavLink>
      )}
    </div>
        </div>
            </nav>
      <main className="flex flex-col items-center bg-white md:px-8 text-center">
        <div className="flex flex-row items-center justify-center py-0 px-4  w-full ">
            <h1 className="m-0 relative text-inherit font-semibold font-inherit text-4xl md:text-2xl mt-20 md:mt-10">
              <span>{`Welcome to `}</span>
              <span className="text-transparent !bg-clip-text [background:linear-gradient(90deg,_#182a3e,_#ba3f37_29.44%,_#406ea4_74.99%)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]">
                Mon - Ami
              </span>
              <span> Matching</span>
            </h1>
          </div>       
         <h2 className="mt-10 text-xl  md:text-base text-slate-800 w-full">We connect newcomers to Canada with locals who can offer support and guidance.</h2>
         <NavLink to={`/matching/locals/register`}>
         <button className="justify-center px-10 py-2 mt-10 text-base font-medium text-black whitespace-nowrap border-2 border-solid border-neutral-400 rounded-2xl " tabIndex={0}>Sign up</button>
        </NavLink> 
        <img
                loading="lazy"
                src="/images/img-machings.svg"
                className="mt-7 w-[60%]  md:w-full"
              />
    
     </main>
     <div className="w-full flex flex-col  justify-center items-center">
     {/* <Heading  className="text-6xl md:text-4xl sm:text-2xl  mt-20  !text-blue_gray-900 md:ml-0">
Ready to Start?
</Heading>
<Text
  size="5xl"
  as="p"
  className="text-2xl md:text-xl sm:text-sm  my-5 md:ml-0 text-center  leading-[41px] !text-gray-800_01  md:w-full md:p-5"
>
  <>
  Powered by AI, Our goal is to create the best settlement experience for you
  </>
</Text>
<NavLink to="/auth/register">
 <Button color="gray_600_04" shape="round" className="font-inter font-semibold">
    Get Started
 </Button>' 
</NavLink> */}
<div className=" mt-20 md:mt-0 flex w-full  flex-col justify-center items-center md:px-1 md:pt-5">
  <Img
    src="/images/img_header_logo.svg"
    alt="image_six"
    className="relative  md:h-32  h-48 md:w-[70%] w-[35%] md:ml-0 z-0"
  />
  <div className=" -mt-20 flex md:flex-col justify-center items-center w-[80%] h-[500px]  md:h-auto md:w-full  rounded-t-[285px] md:rounded-t-[50px] bg-red-400 px-14 pt-32 md:px-5 ">
    <div className="mb-10 flex w-[40%] flex-col items-start justify-start gap-[23px] md:w-full h-full">
   
      <p className="">
        <Heading className="text-4xl md:text-2xl text-left sm:text-2x !text-white-A700">
        Join our Mailing List
        </Heading>
      </p>
      <div className="self-stretch">
        <p  className="text-sm !text-white-A700 w-full">
          <>
            Subscribe to our newsletter for immigration 
            insights and travel news!
          </>
        </p>
       
        <form onSubmit={handleSubmit}>
<div  className="md:ml-0  my-5 font-inter font-semibold p-2 border border-gray-400  rounded-md"> <input
type="email"
id="email"
value={email}

onChange={(e) => setEmail(e.target.value)}
required
/></div>
{loading?(<button
 
    disabled
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Loading
  </button>):(<button
 type="submit"
 
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Subscribe
  </button>)}

</form>
{message && <p className="text-white-A700"> {message}</p>}
        <HomeScreenRowcloseOne className="mt-10 flex items-center justify-between gap-5 md:hidden " />

      </div>
    </div>
    <div className="mb-10 flex w-[40%] flex-row items-start justify-between px-10 md:px-4 gap-[23px] md:w-full  h-full">
    <div className="flex flex-col items-start justify-start">
      <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
       Company
        </Heading>
   <NavLink to="/about">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">About us</div>
</NavLink>
<NavLink to="/feedback">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Feedback</div>
</NavLink>
<NavLink to="/vendor">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Vendors Form</div>
</NavLink>
<NavLink to="/contact-us">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Contact us</div>
</NavLink>
<NavLink to="/matching/locals">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Matching</div>
</NavLink>
      </div>
      <div className="flex flex-col items-start justify-start">
      <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
       Legal
        </Heading>
   <NavLink to="/privacy-policy">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Privacy Policy</div>
</NavLink>
<NavLink to="/terms-service">
<div className="text-white-A700 text-sm self-center mt-4 w-full flex items-center justify-center">Terms and conditions</div>
</NavLink>

      </div>

 </div>
 <HomeScreenRowcloseOne className="my-10 md:flex items-center justify-between gap-5  hidden " />

  </div>
</div>
      </div>
    </>
  );
};

export default MatchingLocalsPage;