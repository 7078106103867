import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Input, Img, Text, Heading, Button } from "../../../../components";
import { NavLink } from "react-router-dom";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import ImageSlider from "components/ImageSlider";
import Typed, { ReactTyped } from 'react-typed'; // Import react-typed
import Header from "components/Header";
import Settings from "components/Settings";
import AuthService from "services/authService";
import axios from "axios";
import { SmallYouTubeVideo } from "components/VideoPlayer";
import CategoryDropdown from "components/CategoryDropdown";

interface Page1Props {
  togglePage: () => void;
}
interface Job {
  title: string;
  description: string;
  company: string;
  location: string;
  redirectUrl: string;
  created: string;
}

interface ApiResponse {
  count: number;
  jobs: Job[];
}
interface Province {
  province: string;
  latitude: any;
  longitude: any;
}
interface YouTubeVideo {
  id: {
    videoId: string;
  };
  snippet: {
    publishedAt: string;
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      default: {
        url: string;
        width: number;
        height: number;
      };
      medium: {
        url: string;
        width: number;
        height: number;
      };
      high: {
        url: string;
        width: number;
        height: number;
      };
    };
    channelTitle: string;
  };
}

const provinces: Province[] = [
  { province: "Alberta", latitude: 53.9333, longitude: -116.5765 },
  { province: "British Columbia", latitude: 53.7267, longitude: -127.6476 },
  { province: "Manitoba", latitude: 49.8951, longitude: -97.1384 },
  { province: "New Brunswick", latitude: 46.5653, longitude: -66.4619 },
  { province: "Newfoundland and Labrador", latitude: 53.1355, longitude: -57.6604 },
  { province: "Nova Scotia", latitude: 44.6820, longitude: -63.7443 },
  { province: "Ontario", latitude: 51.2538, longitude: -85.3232 },
  { province: "Prince Edward Island", latitude: 46.5107, longitude: -63.4168 },
  { province: "Quebec", latitude: 52.9399, longitude: -73.5491 },
  { province: "Saskatchewan", latitude: 52.9399, longitude: -106.4509 }
];

const suggestions = [
  'Adult Education Programs',
  'English Language Schools',
  'Vocational Training Centers',
  'Colleges & Universities',
  'Education Grants & Scholarships',
  'K-12 School Information',
  'Special Education Services',
  'French Immersion Programs',
  'Trade Schools & Apprenticeships',
  'Career Counseling Services',
];

function Page2({ togglePage}: Page1Props){
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isSearched, setisSearched] = useState(false);
    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const handleCloseMenu = () => {
      setMenuOpen(false);
    };
    const data = [
      { image: "/images/img_istockphoto_113.png", wherecani: "Where can i find healthcare facilities?" },
      { image: "/images/img_istockphoto_171.png", wherecani: "What are government policies in healthcare?" },
      { image: "/images/img_istockphoto_143.png", wherecani: "What are government policies in healthcare?" },
      { image: "/images/img_istockphoto_148.png", wherecani: "What are government policies in healthcare?" },
    ];

    const datas = [
      { 
       title: "Front End Developer",
       comapany: "The Max Company",
       country: "Canada",
       salary: "100k -200k",
       jobType: "Remote",
       description: "Help back-end developer with codeing and troubleshooting",
       description2: "Collaborate with back-end and designers to improve usability",
       time: "2 day",

     },
     { 
      title: "Back End Developer",
      comapany: "The Max Company",
      country: "Canada",
      salary: "150k -200k",
      jobType: "Remote",
      description: "Help back-end developer with codeing and troubleshooting",
      description2: "Collaborate with back-end and designers to improve usability",
      time: "3 day",

    },
     
    ];
    const [jobs, setJobs] = useState<Job[]>([]);
 
    const [query, setQuery] = useState<string>('adult education in canada');
    const [videos, setVideos] = useState<YouTubeVideo[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [location, setLocation] = useState<string>('ontario');
    const [selectedProvince, setSelectedProvince] = useState<Province | null>(null);
 
    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
     const province = provinces.find(p => p.province === event.target.value) || null;
     setSelectedProvince(province);
     setLocation(province.province);
    };

  
    const handleSearch = (e: React.FormEvent) => {
      e.preventDefault();
      fetchJobListings();
      
    };
  

   
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [activeSuggestion, setActiveSuggestion] = useState(0);
  
    const handleAutoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setInputValue(value);
      if (value) {
        const filtered = suggestions.filter(suggestion =>
          suggestion.toLowerCase().includes(value.toLowerCase())
        );
        setFilteredSuggestions(filtered);
        setShowSuggestions(true);
      } else {
        setShowSuggestions(false);
      }
    };
  
    const handleClick = (suggestion: string) => {
      setInputValue(suggestion);
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    };
  
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        setInputValue(filteredSuggestions[activeSuggestion]);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
      } else if (e.key === 'ArrowUp') {
        if (activeSuggestion === 0) {
          return;
        }
        setActiveSuggestion(activeSuggestion - 1);
      } else if (e.key === 'ArrowDown') {
        if (activeSuggestion === filteredSuggestions.length - 1) {
          return;
        }
        setActiveSuggestion(activeSuggestion + 1);
      }
    };
  
    const fetchJobListings = async () => {
      setLoading(true);
      setError(null);
      try {
       const token = localStorage.getItem("token");
        const response = await axios.post<{ results: YouTubeVideo[] }>('https://linked-origin-server.vercel.app/api/v1/search/youtube-search', {
          searchQuery: inputValue,
         
        }, {
          
          headers: {
           Authorization: `Bearer ${token}`,
          }
        });
  
        if (response.data && response.data.results) {
          setVideos(response.data.results);
          setisSearched(true);
        } else {
          setVideos([]);
          setisSearched(true);
        }
      } catch (err) {
        setError('Failed to fetch videos listings');
        setisSearched(true);
      } finally {
        setLoading(false);
      }
    };
 

  
      return (
        <>
          <Helmet>
            <title>Government Services - Assistance with Immigration and More</title>
            <meta
              name="description"
              content="Get help with immigration, employment, healthcare, and legal matters. Access government services and quick links to facilitate your settlement process."
            />
          </Helmet>
    
          <div className=" bg-[url(/public/images/img_welcome_screen.svg)  bg-center bg-cover bg-no-repeat my-auto flex flex-col items-center">
              {/* header section */}
              <Header/>
              <div className="relative mx-auto flex w-full items-start justify-center gap-5  p-4 md:p-4    md:h-auto md:flex-col ">
              <Settings/>
              <div className="flex flex-col w-full h-full items-center">
              <div className="flex gap-5 justify-between md:flex-wrap w-full px-10 md:hidden animate__animated animate__fadeInUp">
        <CategoryDropdown
          name="Education"
          img= "/images/img_thumbs_up.svg"
         />

      <div className="flex flex-col px-5 text-center">
        <div className="flex flex-col px-1.5 pt-2 pb-1.5 text-base font-semibold rounded-2xl bg-zinc-100">
          <div onClick={togglePage}  className="text-black text-xs cursor-pointer">Ask Mon-Ami</div>
          <div className="justify-center text-xs text-white-A700 px-9 py-2 mt-2 text-white whitespace-nowrap bg-gray-700 rounded-xl md:px-5 cursor-pointer">
            Search
          </div>
        </div>
        <div className="mt-2.5 text-xs leading-4 text-stone-900">
          Toggle to switch between <br />
          Mon-Ami & search
        </div>
      </div>
               </div>
               <p className="md:block hidden  md:text-2xl text-3xl text-left self-start bg-gradient2 bg-clip-text !text-transparent !font-kumbhsans ">
             Education
              </p>
                {/* introductory section */}
                <div className="mb-7    h-full flex w-[80%] flex-col items-start gap-10 md:w-full md:gap-5 sm:gap-10">
                  {/* questions list section */}
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col">
      <div className="ml-20 md:ml-0  md:flex-col flex flex-row w-full items-end justify-end gap-2   ">
        
        <div className="pt-10 md:pt-0 flex flex-col items-end gap-[3px]  w-full ">
                        <div className="mr-7 flex w-[27%] flex-wrap justify-end gap-5 md:mr-0 md:w-full">
                          <Heading onClick={togglePage}  className="cursor-pointer !text-gray-800 text-base md:text-sm ">
                            Ask Mon-Ami
                          </Heading>
                          <Heading   className="cursor-pointer !text-gray-800 text-base md:text-sm border-b-2 border-red-500 ">
                              Search
                          </Heading>
                        </div>
                        <div className="self-stretch rounded-md  bg-white-A700 p-[9px] md:p-0 md:pt-2 border border-gray-400 md:border-none">
                        <form onSubmit={handleSearch}>
                            <div className="flex flex-col gap-[27px]">
              
                              <div className="flex items-center justify-between gap-5 h-12  border border-gray-200 p-2 rounded-md">

                                <div  className="flex items-center w-full text-base h-full md:text-sm outline-none border-none focus:outline-none border border-black-900_2d ">
                             
                                 
            
                           <div className="relative w-full">
                          <input
                            type="text"
                            // className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={inputValue}
                            onChange={handleAutoChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Type here to search Educational courses"
                          />
                          {showSuggestions && inputValue && (
                            <ul className="absolute z-10 w-full bg-white  rounded-md mt-1  overflow-auto">
                              {filteredSuggestions.length ? (
                                filteredSuggestions.map((suggestion, index) => (
                                  <li
                                    key={suggestion}
                                    className={`px-4 py-2 cursor-pointer bg-white-A700 hover:bg-red-400 hover:text-white-A700 border border-gray-300 ${
                                      index === activeSuggestion ? 'text-white' : ''
                                    }`}
                                    onMouseDown={() => handleClick(suggestion)}
                                    onMouseEnter={() => setActiveSuggestion(index)}
                                  >
                                    {suggestion}
                                  </li>
                                ))
                              ) : (
                                <li className="px-4 py-2"></li>
                              )}
                            </ul>
                          )}
                                </div>
          
                               
  
                               </div>
                               <div className= "flex flex-row h-full items-center justify-between gap-2 pl-2 w-[20%]   border-l border-blue_gray-100_01 md:hidden">
                              
                              
                                <button  type="submit" className= " justify-center self-end  w-32 text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-8  rounded-[23px]" > Search
                                </button>
                               </div>
                            
                              </div>
                           
                            </div>
                             <button className= "hidden md:block justify-center md:w-full  mt-4 w-[15%] text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-12  rounded-[23px]" > Search
                            </button>
                            </form>
                          </div>
        </div>
  
        <button className= "invisible md:hidden  justify-center md:w-full  w-[15%] text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-14  rounded-[23px]" > Post your resume
        </button>
        </div>
      </div>
   
    
                    {/* healthcare questions section */}
                    <div className=" ml-20 md:ml-0 mr-10 md:mr-4 flex flex-col my-10 md:my-5 ">

                  
                    <div>
         
                    {loading && 
            <div className="flex flex-col gap-4 mt-2 w-full overflow-hidden">  
            <div className="flex  w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
            <div className="flex  w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
            <div className="flex w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>

            </div>}
            {error && <div>{error}</div>}
            
            {!loading && !error && videos.length > 0 && (
       <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
         {videos.map((video, index) => (
            <div key={index} className=" rounded-lg  shadow gap-4 bg-gray-100 border border-gray-200 items-center my-2  animate__animated animate__fadeInUp">
              <SmallYouTubeVideo videoUrl={`https://www.youtube.com/embed/${video.id.videoId}`} />
              <h2 className="font-bold text-base md:text-sm p-4">{video.snippet.title}</h2>
            </div>
          ))}
        </div>
      )}
      {!loading && !error && jobs.length === 0 && inputValue===""&& isSearched===false&& 
      <div className="flex flex-col"> 
      
      <p className=" md:text-xl text-xl self-start !font-kumbhsans ">
      Learn new things
     </p>
      <div className="mt-2  text-gray-600  text-sm text-black w-full">
      Upgrade your skills or prepare for further studies. </div>
      <div className="mt-2 text-gray-600  text-sm text-black w-full">
      Discover higher education institutions & financial aid options.</div>
     
  
      </div>
      
      }
      {!loading && !error && jobs.length === 0 && isSearched===true&& <div className="flex flex-col"> 
      
      <div className="mt-5  text-sm text-black w-full font-bold">
      Oppss!!!, no data available
       </div></div>}
        </div>
    
                  
                    </div>
                   
                    </div>
    
            
                </div>
    

              </div>
           
              </div>
          
    
    
            </div>
    
       
        </>
      );
  }

export default Page2;
