// src/StarRating.tsx
import React, { useState } from 'react';

interface StarRatingProps {
  name: string;
  value: number;
  onChange: (name: string, value: number) => void;
}

const StarRating: React.FC<StarRatingProps> = ({ name, value, onChange }) => {
  const [rating, setRating] = useState(value);

  const handleClick = (ratingValue: number) => {
    setRating(ratingValue);
    onChange(name, ratingValue);
  };

  return (
    <div className="flex space-x-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <svg
          key={star}
          className={`w-6 h-6 cursor-pointer ${star <= rating ? 'text-yellow-500' : 'text-gray-300'}`}
          fill="currentColor"
          viewBox="0 0 20 20"
          onClick={() => handleClick(star)}
        >
          <path d="M9.049 2.927C9.424 1.646 10.576 1.646 10.951 2.927L12.232 7.516C12.384 8.04 12.815 8.373 13.357 8.373H18.146C19.484 8.373 19.988 9.932 18.86 10.515L14.742 12.76C14.302 13.014 14.115 13.596 14.287 14.096L15.568 18.685C15.943 19.966 14.631 20.834 13.503 20.251L9.385 18.006C8.945 17.752 8.055 17.752 7.615 18.006L3.497 20.251C2.369 20.834 1.057 19.966 1.432 18.685L2.713 14.096C2.885 13.596 2.698 13.014 2.258 12.76L-1.86 10.515C-2.988 9.932 -2.484 8.373 -1.146 8.373H3.643C4.185 8.373 4.616 8.04 4.768 7.516L6.049 2.927Z" />
        </svg>
      ))}
    </div>
  );
};

export default StarRating;
