import React, { useState } from 'react';
import axios from 'axios';
import { Heading } from 'components';
import { Link } from 'react-router-dom';
import Header from 'components/Header';
import { ReactTyped } from 'react-typed';
import CategoryDropdown from 'components/CategoryDropdown';
import FootersMonami from 'components/footer';

interface Page1Props {
  togglePage: () => void;
}

interface ChatEntryProps {
  entry: { type: string; text: string };
  isUser: boolean;
}

const QuickLink: React.FC<{ text: string; onClick: () => void }> = ({ text, onClick }) => (
  <div
    onClick={onClick}
    className="w-full cursor-pointer   flex flex-col justify-start p-5 text-sm rounded-xl bg-gray-200 mb-2  duration-200 hover:scale-105 animate__fadeInUp"
  >
    {text}
  </div>
);



const convertTextToLinks = (text) => {
  const urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  return text.replace(urlRegex, url => `<a href="${url}" target="_blank" rel="noopener noreferrer" style="color: blue;">${url}</a>`);
};

const ChatEntry = ({ entry, isUser }) => {
  const convertedText = convertTextToLinks(entry.text);

  return (
    <div className="mb-2 rounded-lg whitespace-pre-line">
      <div className="flex flex-row items-center gap-2 mt-5">
        <div className={`flex self-start justify-center items-center text-xs text-center font-medium leading-4 text-black whitespace-nowrap ${isUser ? 'bg-gray-400' : 'bg-yellow-300'} rounded-full h-8 w-8 md:hidden`}>
          {isUser ? 'Me' : 'M'}
        </div>
        <div className="flex flex-col">
          <h1 className={`font-bold text-base md:text-sm ${isUser ? '' : 'bg-gradient2 bg-clip-text !text-transparent'}`}>
            {isUser ? 'You' : 'Mon - Ami'}
          </h1>
          <ReactTyped 
            strings={[convertedText]} 
            typeSpeed={10} 
            loop={false} 
            showCursor={false} 
          />
        </div>
      </div>
    </div>
  );
};

const Page1: React.FC<Page1Props> = ({ togglePage }) => {
  const [message, setMessage] = useState<string>('');
  const [chat, setChat] = useState<Array<{ type: string, text: string }>>([]);
  const [loading, setLoading] = useState(false);

  const fetchResponse = async (query: string, subcategory: string) => {
    const newChat = [...chat, { type: 'user', text: query }];
    setChat(newChat);
    setMessage('');
    setLoading(true);

    try {
      const token = localStorage.getItem("token");
      const apiUrl = 'https://linked-origin-server.vercel.app/api/v1/users/mon-ami/chat';
      const requestBody = {
        searchQuery: query,
        category: 'community',
        // subCategory: 'immigrant support groups'
      };

      const response = await axios.post(apiUrl, requestBody, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      const responseText = response.data?.response || 'No response from the server.';
      setChat(prevChat => [...newChat, { type: 'bot', text: responseText }]);
    } catch (error) {
      console.error('Error fetching response:', error);
      setChat(prevChat => [...newChat, { type: 'bot', text: 'High Request Volume. Due to our system experiencing a high volume of requests, we are unable to process your request.Please check back later. Thank you for your patience!' }]);
    } finally {
      setLoading(false);
    }
  };

  const sendMessage = async () => {
    if (message.trim() === '') return;
    fetchResponse(message, 'immigrant support groups');
  };

  const handleQuickLink = async (quicklink: string, subcategory: string) => {
    fetchResponse(quicklink, subcategory);
  };

  return (
    <div className="flex flex-col item-center w-full">
      <Header />
      <div className="w-full h-full flex flex-col justify-center items-center p-4 md:p-2 bg-white">
        <div className="flex gap-5 justify-between md:flex-wrap w-full px-10 md:hidden animate__animated animate__fadeInUp">
          <CategoryDropdown
            name="Community"
            img="/images/img_settings.svg"
          />
          <div className="flex flex-col px-5 text-center ">
            <div className="flex flex-col px-1.5 pt-2 pb-1.5 text-base font-semibold rounded-2xl bg-zinc-100">
              <div className="justify-center text-xs text-white-A700 px-9 py-2 mt-2 text-white whitespace-nowrap bg-gray-700 rounded-xl max-md:px-5 cursor-pointer">
                Ask Mon-Ami
              </div>
              <div onClick={togglePage} className="text-black text-xs cursor-pointer"> Search</div>
            </div>
            <div className="mt-2.5 text-xs leading-4 text-stone-900">
              Toggle to switch between <br />
              Mon-Ami & search
            </div>
          </div>
        </div>
        <div className="flex flex-col h-full items-center w-[80%] md:w-[95%]">
        <div className="h-[80%] overflow-hidden w-full">
       
       {chat.map((entry, index) => (
        <ChatEntry key={index} entry={entry} isUser={entry.type === 'user'} />
      ))}
       {loading && 
      <div className="flex flex-col gap-4 mt-2 w-full overflow-hidden">  
      <div className="flex  w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
      <div className="flex  w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
      <div className="flex w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>

      </div>}
            {chat.length === 0 && (
              <div className="mt-20 md:mt-05 flex gap-5 md:flex-col">
                <div className="flex flex-col w-full">
                  <p className="md:text-2xl text-3xl self-start bg-gradient2 bg-clip-text !text-transparent !font-kumbhsans">
                    Mon - Ami
                  </p>
                  <div className="mt-5 w-[50%] text-sm text-black md:w-full">
                    Hey there! Mon-Ami here, your personal guide to building a life you love in Canada.
                  </div>
                  <div className="mt-16 w-full md:mt-10 md:w-full">
                    <div className="flex gap-2 md:flex-col max-md:gap-0">
                      <QuickLink
                        text="Feeling lost? Find local Immigrant Support Groups or Cultural Associations in your area"
                        onClick={() => handleQuickLink('Feeling lost? Find local Immigrant Support Groups or Cultural Associations in your area', 'Immigration and Citizenship')}
                      />
                      <QuickLink
                        text="New to Canada as a refugee? I can help you find nearby Refugee Assistance Organizations"
                        onClick={() => handleQuickLink('New to Canada as a refugee? I can help you find nearby Refugee Assistance Organizations', 'Immigration and Citizenship')}
                      />
                      <QuickLink
                        text="Search for Language Programs in English or French! Thinking about furthering your education?"
                        onClick={() => handleQuickLink('Search for Language Programs in English or French! Thinking about furthering your education?', 'Immigration and Citizenship')}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="mt-20 md:mt-10 flex flex-col items-end gap-[3px] mb-20 md:mb-5 w-full">
            <div className="mr-7 flex w-[27%] flex-wrap justify-end gap-5 md:mr-0 md:w-full">
              <Heading className="cursor-pointer !text-gray-800 text-base md:text-sm border-b-2 border-red-500">
                Ask Mon-Ami
              </Heading>
              <Heading onClick={togglePage} className="cursor-pointer !text-gray-800 text-base md:text-sm">
              Forum
              </Heading>
            </div>
            <div className="flex flex-col items-end gap-2.5 self-stretch">
              <div className="flex w-full items-start justify-between gap-5">
                <div className="w-full flex flex-col">
                  <div className="self-stretch flex flex-row items-center justify-center rounded-md border border-solid border-blue_gray-100_01 bg-white-A700 p-[9px]">
                    <input
                      type="text"
                      placeholder="Ask me anything"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyDown={(e) => e.key === 'Enter' ? sendMessage() : null}
                      disabled={loading}
                      aria-label="Ask me anything"
                    />
                    <button
                      onClick={sendMessage}
                      disabled={loading}
                      className="md:hidden justify-center self-end w-32 text-xs hover:-translate-y-1 hover:scale-110 duration-300 sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF] h-10 rounded-[23px]"
                      aria-label="Ask Mon-ami"
                    >
                      Enter
                    </button>
                  </div>
                  <button
                    onClick={sendMessage}
                    disabled={loading}
                    className="hidden md:block justify-center self-end mt-4 w-full text-xs hover:-translate-y-1 hover:scale-110 duration-300 sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF] h-10 rounded-[23px]"
                    aria-label="Ask Mon-ami"
                  >
                    Enter
                  </button>
                </div>
              </div>
              <FootersMonami/>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default Page1;
