import { Img, Button, Heading } from "components";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Page1 from "./component/Page1";
import Page2 from "./component/Page2";
import Cookies from "js-cookie";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export default function HomeScreenPage() {
  const [currentPage, setCurrentPage] = useState<string>('page1');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isDialogShown = Cookies.get('dialogShown');
    if (!isDialogShown) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    Cookies.set('dialogShown', 'true', { expires: 7 }); // Show the dialog only once every 7 days
  };
  const togglePage = () => {
    setCurrentPage(currentPage === 'page1' ? 'page2' : 'page1');
  };
  return (
    <div>
       <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="cookie-dialog-title"
        aria-describedby="cookie-dialog-description"
      >
        <DialogTitle id="cookie-dialog-title">Cookies Policy</DialogTitle>
        <DialogContent>
          <DialogContentText id="cookie-dialog-description">
            We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept", you consent to our use of cookies. You can learn more about how we use cookies in our privacy policy.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Accept
          </Button>
        </DialogActions>
      </Dialog>
    </div>
      {currentPage === 'page1' ? (
        <Page1 togglePage={togglePage} />
      ) : (
        <Page2 togglePage={togglePage} />
      )}
    </div>
  );
}
