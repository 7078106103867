// src/axiosSetup.ts
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://linked-origin-server.vercel.app/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setupAxiosInterceptors = (navigate: (path: string) => void) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        // Token has expired or is invalid
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        // Redirect to login page
        navigate('/auth/login');
      }
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
