import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Button, Heading, Input, Text, CheckBox, Img } from "../../../components";
import { Link, NavLink, useNavigate } from "react-router-dom";

import AuthService from "services/authService";
import { Alert, Box, CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 
import { toast, Bounce, ToastContainer } from "react-toastify";
import { CgSpinner } from "react-icons/cg";
import DatePicker from "react-datepicker";
import { scroller } from 'react-scroll';

interface TableRow {
  id: number;
  title: string;
  option1: string;
  option2: string;
  option3: string;
  option4: string;
  option5: string;
}

export default function LocalRegisterPage() {

  const [tableRows, setTableRows] = useState<TableRow[]>([
    { id: 1, title: 'Listening', option1: '', option2: '', option3: '', option4: '', option5: '' },
    { id: 2,title: 'Reading', option1: '', option2: '', option3: '', option4: '', option5: '' },
    { id: 3,title: 'Speaking', option1: '', option2: '', option3: '', option4: '', option5: '' },
    { id: 4,title: 'Writing', option1: '', option2: '', option3: '', option4: '', option5: '' },
]);

const handleOptionChange = (rowId: number, optionName: string, value: string) => {
    setTableRows(prevRows =>
        prevRows.map(row =>
            row.id === rowId ? { ...row, [optionName]: value } : row
        )
    );
};








  const [firstname, setFirstNmae] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [dateOfBirth, setdateOfBirth] = useState('');
  const [ gender, setgender] = useState('');
  const [ phone, setphone] = useState('');
  const [ countryOfOrigin, setcountryOfOrigin] = useState('');
  const [ currentImmigrationStatus, setcurrentImmigrationStatus] = useState('');
  const [ dateOfImmigration,setdateOfImmigration] = useState('');
  const [ visaType,setvisaType] = useState('');
  const [ typeOfStatus,settypeOfStatus]  = useState('');
  const [ nativeLanguage,setnativeLanguage]  = useState('');
  const [ highestLevelOfEducation,sethighestLevelOfEducation]  = useState('');
  const [ previousWorkExperience,setpreviousWorkExperience]  = useState('');
  const [ aspirations,setaspirations]  = useState('');
  const [ currentHousingSituation,setacurrentHousingSituation]  = useState('');
  const [ housingPreference,setahousingPreference]  = useState('');
  const [ numOfFamilyMembers,setnumOfFamilyMembers]  = useState('');
  const [ relationship, setrelationship]  = useState('');
  const [ interestsAndHobbies, setinterestsAndHobbies]  = useState('');
  const [ preferredSocialActivities, setpreferredSocialActivities]  = useState('');
  const [ ethos, setethos] = useState('');
  
  const [error, setError] = useState<string | null>(null);
 const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const navigate = useNavigate()
  const [sector, setSector] = useState('');
  const [subSector, setSubSector] = useState('');
  const [category, setCategory] = useState('');
  const [role, setRole] = useState('');
  const [startDate, setStartDate] = useState(new Date());


  // Function to handle changes in dropdowns

  const handlefirsNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstNmae(e.target.value);
  };
  const handlelastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const [selectedDate, setSelectedDate] = useState<Date | null>(null); 

  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setdateOfBirth(e.target.value); // Update selected date state
  };
  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };
  const handleConfirmChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };
  const handleGenderChange = (event) => {
    setgender(event.target.value);
  };

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    setphone(e.target.value);
  };
  // stet2
  const handleCountryofOriginChange = (event) => {
    setcountryOfOrigin(event.target.value);
  };
  const handlelCurrentImigratStatusChange = (event) => {
    setcurrentImmigrationStatus(event.target.value);
  };

  const handleImigrationDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setdateOfImmigration(e.target.value);
  };
  
  const handleVisaTypeChange = (event) => {
    setvisaType(event.target.value);
  };

  // stet3
  const handleNativeLanguageChange = (event) => {
    setnativeLanguage(event.target.value);
  };

    // stet4
  const handlleHighetEducation = (event) => {
    sethighestLevelOfEducation(event.target.value);
  };

  const handlePreviousWorkExperienceChange = (e: ChangeEvent<HTMLInputElement>) => {
    setpreviousWorkExperience(e.target.value);
  };

  const handleGoalAndAspirationhange = (e: ChangeEvent<HTMLInputElement>) => {
    setaspirations(e.target.value);
  };

  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loggedIn, setLoggedIn] = useState(false);
  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };
  useEffect(() => {
    // Check local storage for user data
    const userData = localStorage.getItem('userData');
    if (userData) {
      // If user data is found, consider the user logged in
      setLoggedIn(true);
    }
  }, []);
  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };
  
 
 


  const [currentStep, setCurrentStep] = useState<number>(1);

  const handleNext = (): void => {

  
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
  });
    setError('');
    setCurrentStep((prevStep) => prevStep + 1);

  };

  const handlePrev = (): void => {
   
  
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scrolling animation
  });

    setCurrentStep((prevStep) => prevStep - 1);
  };

  const userData = {
  firstName: firstname,
  lastName:  lastname,
  email: email,
  phone:phone,
  preferredLanguages:["English"],
  timeSpentInCanada:"2 month",
  areasOfExpertise:["health", "social support"],
  location:"Quebec",
  motivations:["","",""],
  backgrounds:["","",""],
  selfDescription:"",
  hobbies:["skiing","hiking"],
  specificAreasOfNeed:"",


  



 }



  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  
    // Set loading to true when submitting the form
    setLoading(true);
    setError('');
  
    const { success, error } = await AuthService.matchlocal(userData);
  
    // Reset loading state after login attempt
    setLoading(false);
  
    if (success) {
      setError('');
      navigate(`/success`);
    } else {
      if (error !== undefined) {
        setError(error);
      }
    }
  };

  const navigateToAboutSection = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo('service', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }, 100);
  };
  const navigateToContactSection = () => {
    navigate('/contact-us');
  };
  return (
    <>
      <Helmet>
      <title>LinkedOrigin</title>
        <meta name="description" content="Simplify your settlement journey with AI and streamlined resources" />

      </Helmet>
      <div className="flex flex-col pb-20 bg-white">
      <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5 p-10 md:p-4  bg-[#fff]  md:border-gray-300 md:border-b bg-opacity-20 right-3 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none" ref={navbarRef}>
        <div className="container mx-auto flex  justify-between items-center">
        <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-8 md:h-6 " />

          <div className="hidden md:block  ">
            {isMenuOpen ? (
              <button 
                onClick={handleCloseMenu}
                className="text-[#898989] focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button 
                onClick={handleMenuToggle}
                className="text-[#898989] focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            )}
          </div>
  
          {isMenuOpen && (
            <div className="hidden md:block m-auto items-center  justify-center  absolute top-14 right-0 left-0  bg-black-900 z-50 w-full h-auto shadow p-4">
             
          
          <div className="flex flex-col gap-5 text-white-A700" >
          <NavLink to="/">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">Home</div>
            </NavLink>
          <NavLink to="/about">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/matching/locals">
          <div className="cursor-pointer text-[#898989] hover:text-white-A700">Matching</div>
            </NavLink>
            {loggedIn ? (  
      <>
        <div  onClick={() => {
               AuthService.logout();
               navigate("/auth/login");
             }} >Logout</div>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div>Login</div>

        </NavLink>
      )}
             
  
          </div>
            </div>
          )}
  
          <div className="md:hidden  flex flex-row  items-center justify-center">
    
          <div className="flex gap-5 justify-between  md:flex-wrap">
            <div className="flex gap-5 justify-between self-start text-black md:flex-wrap md:w-full">
            <NavLink to="/">
            <div className="text-[#191919D1]  hover:text-orange-300">Home</div>
            </NavLink>
            <NavLink to="/about">
            <div className=" text-[#191919D1] ">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#191919D1] hover:text-orange-300">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#191919D1] hover:text-orange-300">Contact Us</div>
            <NavLink to="/matching/locals">
            <div className="cursor-pointer text-[#191919D1] hover:text-orange-300">Matching</div>
            </NavLink>
 
            
            </div>
            
           
          </div>
          </div>
          <div className="md:hidden  flex flex-row items-center gap-2">
  
    <div className="flex flex-row gap-4 items-center justify-center ">
                                
  <svg width="77" height="40" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.97 38.33C47.97 38.69 47.89 39.06 47.72 39.42C47.55 39.78 47.33 40.12 47.04 40.44C46.55 40.98 46.01 41.37 45.4 41.62C44.8 41.87 44.15 42 43.45 42C42.43 42 41.34 41.76 40.19 41.27C39.04 40.78 37.89 40.12 36.75 39.29C35.6 38.45 34.51 37.52 33.47 36.49C32.44 35.45 31.51 34.36 30.68 33.22C29.86 32.08 29.2 30.94 28.72 29.81C28.24 28.67 28 27.58 28 26.54C28 25.86 28.12 25.21 28.36 24.61C28.6 24 28.98 23.44 29.51 22.94C30.15 22.31 30.85 22 31.59 22C31.87 22 32.15 22.06 32.4 22.18C32.66 22.3 32.89 22.48 33.07 22.74L35.39 26.01C35.57 26.26 35.7 26.49 35.79 26.71C35.88 26.92 35.93 27.13 35.93 27.32C35.93 27.56 35.86 27.8 35.72 28.03C35.59 28.26 35.4 28.5 35.16 28.74L34.4 29.53C34.29 29.64 34.24 29.77 34.24 29.93C34.24 30.01 34.25 30.08 34.27 30.16C34.3 30.24 34.33 30.3 34.35 30.36C34.53 30.69 34.84 31.12 35.28 31.64C35.73 32.16 36.21 32.69 36.73 33.22C37.27 33.75 37.79 34.24 38.32 34.69C38.84 35.13 39.27 35.43 39.61 35.61C39.66 35.63 39.72 35.66 39.79 35.69C39.87 35.72 39.95 35.73 40.04 35.73C40.21 35.73 40.34 35.67 40.45 35.56L41.21 34.81C41.46 34.56 41.7 34.37 41.93 34.25C42.16 34.11 42.39 34.04 42.64 34.04C42.83 34.04 43.03 34.08 43.25 34.17C43.47 34.26 43.7 34.39 43.95 34.56L47.26 36.91C47.52 37.09 47.7 37.3 47.81 37.55C47.91 37.8 47.97 38.05 47.97 38.33Z" stroke="#191919D1" stroke-width="1.5" stroke-miterlimit="10"/>
  <rect x="1" y="1" width="75" height="61" rx="30.5" stroke="#191919D1" stroke-width="2"/>
  </svg>
    </div>
    {loggedIn ? (  
      <>
        <NavLink to="/auth/login">
            <div className="text-[#191919D1] border border-[#191919D1] rounded-full p-3 text-sm">Logout</div>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/register">
                    <div className="text-[#191919D1] border border-[#191919D1] rounded-full p-3 text-sm">Register</div>

        </NavLink>
      )}
    </div>
        </div>
      </nav>

    <div className="w-full  flex flex-col items-center justify-center md:px-5">
   
    
   <form onSubmit={handleSubmit} className='w-full flex flex-col justify-center items-center'>

   <div className=" w-full mt-32 md:mt-14 flex items-center justify-center ">
   <>
  
         {currentStep === 1 ? (
            <div className=" w-[50%] md:w-full self-end mt-10  flex items-center justify-center">
               <div className="flex flex-col w-full md:ml-0 md:w-full">
               <div className="flex w-[58%] my-5 items-center gap-[11px]  md:w-full">
                           <Text size="6xl" as="p" className=" !font-kumbhsans !text-blue_gray-700_01">
                             <>
                              
                             Select your category
                             </>
                           </Text>
                          
                </div>
               <div  className="w-full flex flex-row md:flex-col  gap-4 justify-between">
               <div onClick={(e) => { e.preventDefault(); handleNext();}} className=" px-3 py-8 gap-4 flex flex-col w-[45%] md:w-full border border-gray-800 rounded-md cursor-pointer" >
               <Text size="2xl" as="p" className=" !font-kumbhsans !text-blue_gray-700_01">
                             <>
                              
                             NewComer
                             </>
                </Text>

                <label className="  font-normal  text-black text-sm ">
                Select this if you are new to Canada and seeking assistance
                 </label>
               </div>
               <div onClick={(e) => { e.preventDefault(); handleNext();}} className=" px-3 py-8 gap-4 flex flex-col w-[45%] md:w-full border border-gray-800 rounded-md  cursor-pointer" >
               <Text size="2xl" as="p" className=" !font-kumbhsans !text-blue_gray-700_01">
                             <>
                              
                            Local
                             </>
                </Text>

                <label className="  font-normal  text-black text-sm ">
                Select this if you are a resident of Canada and want to help newcomers
                 </label>
               </div>
               </div>
              
               </div>
           </div>
         ) : currentStep === 2 ? (
           <div className=" w-[50%] md:w-full self-end mt-10 flex items-center justify-center">
            <div className="self-end mt-10 w-full flex items-center justify-center">
                  <div className="flex flex-col w-full md:ml-0 md:w-full">
                  <div className="flex my-5 items-center gap-[11px] md:w-full">
                              <Text size="3xl" as="p" className=" !font-kumbhsans !text-blue_gray-700_01">
                                <>   
                                Please fill out the following information to create your profile and find your perfect match
                                </>
                              </Text>
                             
                            </div>
                  <div className="flex sm:flex-col self-stretch gap-[9px]">
              
                          <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                          <div className="mb-4 w-full" >
                          <label className="mb-2.5 block font-medium  text-black ">
                            First Name
                          </label>
                          <TextField
      placeholder="First Name"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlefirsNameChange}
      value={firstname}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#FFFFFF', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                          </div>
                         
                          <div className="flex flex-col items-start w-full sm:w-full gap-1.5">
                          <div className="mb-4 w-full" >
                          <label className="mb-2.5 block font-medium  text-black ">
                            Last Name
                          </label>
                          <TextField
      placeholder="Last Name"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlelastNameChange}
      value={lastname}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#FFFFFF', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                          </div>
                        </div>
                     
                        
                <div className="mb-4 w-full" >
                  <label className="mb-2.5 block font-medium  text-black ">
                    Email
                  </label>
                  <TextField
      placeholder="Email"
      type="email"
      className='w-full self-stretch flex'
      onChange={handleEmailChange}
      value={email}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#FFFFFF', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />

                </div>
              
                        <div className="mb-6 w-full">
                          <label className="mb-2.5 block font-medium  text-black ">
                            Phone 
                          </label>
                          <TextField
      placeholder="Phone number"
      type="text"
      className='w-full self-stretch flex'
      onChange={handlePhoneChange}
      value={phone}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: '#FFFFFF', // Set background color
          '& fieldset': {
            borderColor: '#E0E0E0', // Set border color
          },
          '&:hover fieldset': {
            borderColor: '#E0E0E0', // Set border color on hover
          },
          '&.Mui-focused fieldset': {
            borderColor: '#E0E0E0', // Set border color on focus
          },
        },
       
        '& .MuiInputBase-root': {
          marginTop: 0, // Remove top padding
          marginBottom: 0, // Remove bottom padding
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent', // Remove outline by default
        },
      }}
      fullWidth
      margin="normal"
      variant="outlined"
      required

    />
                        </div>
                        <div className="mb-4 w-full" >
                 <label className="mb-2.5 block font-medium  text-black ">
                Preferd language
                 </label>
                 <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#FFFFFF] mb-5 gap-2  rounded-md w-full">
     <select  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
       <option value="">Select</option>
       <option value="railway">English</option>
       <option value="road">French</option>
     
     </select>
   </div>
               </div>
               <button  onClick={(e) => { e.preventDefault(); handleNext();}}  className="justify-center w-full bg-[#3864FF] self-end px-11 py-5 mt-10 text-base font-semibold text-white-A700 whitespace-nowrap rounded-[31.5px] md:px-5">
                Next
              </button>
                  </div>
              </div>
         
       </div>
         ) : currentStep === 3 ? (
          <div className=" w-[50%] md:w-full self-end mt-10 flex items-center justify-center">
          <div className="self-end mt-10 w-full flex items-center justify-center">
                <div className="flex flex-col w-full md:ml-0 md:w-full">
                <div className="flex my-5 items-center gap-[11px] md:w-full">
                            <Text size="3xl" as="p" className=" !font-kumbhsans !text-blue_gray-700_01">
                              <>   
                              Matching Prefrence
                              </>
                            </Text>
                           
                          </div>
             
                   
                      
              <div className="mb-4 w-full" >
                <label className="mb-2.5 block font-medium  text-black ">
                In which city/province are you located?
                </label>
                <TextField
    placeholder=""
    type="text"
    className='w-full self-stretch flex'
   
    sx={{
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#FFFFFF', // Set background color
        '& fieldset': {
          borderColor: '#E0E0E0', // Set border color
        },
        '&:hover fieldset': {
          borderColor: '#E0E0E0', // Set border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: '#E0E0E0', // Set border color on focus
        },
      },
     
      '& .MuiInputBase-root': {
        marginTop: 0, // Remove top padding
        marginBottom: 0, // Remove bottom padding
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent', // Remove outline by default
      },
    }}
    fullWidth
    margin="normal"
    variant="outlined"
    required

  />

              </div>
            
              <div className="mb-4 w-full" >
                 <label className="mb-2.5 block font-medium  text-black ">
                 How long have u live in Canada
                 </label>
                 <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#FFFFFF] mb-5 gap-2  rounded-md w-full">
     <select  className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
       <option value="">Select</option>
       <option value="railway">1</option>
       <option value="road">2</option>
       <option value="aviation">3</option>
       <option value="marine">4</option>
 
       <option value="road">5</option>
       <option value="aviation">6</option>
       <option value="marine">7</option>
 
       <option value="aviation">8</option>
       <option value="marine">9</option>
       <option value="marine">10</option>
     </select>
   </div>
               </div>
                <div className="mb-4 w-full" >
                 <label className="mb-2.5 block font-medium  text-black ">
                 What are your areas of expertise or experience that could be helpful to newcomers?
                 </label>
                 <div className="flex flex-wrap mt-2 p-3 border border-[#E0E0E0] bg-[#FFFFFF] mb-5 gap-2  rounded-md w-full">
       <select    className="h-8   text-gray-600 text-base font-hankengrotesk w-full  ">
         <option value="">Select Previous Work industry</option>
         <option value="Agriculture">Agriculture, Forestry, Fishing & Hunting</option>
         <option value="Mining">Mining, Quarrying, and Oil & Gas Extraction</option>
         <option value="Utilities">Utilities (Electricity, Gas, Water)</option>
         <option value="construction">Construction</option>

         <option value="Manufacturing">Manufacturing</option>
         <option value="Wholesale Trade">Wholesale Trade</option>
         <option value="Retail Trade">Retail Trade</option>
         <option value="Transportation & Warehousing">Transportation & Warehousing</option>

         <option value="ICT">Information & Communication Technology (ICT)</option>
         <option value="Finance & Insurance">Finance & Insurance</option>
         <option value="Real Estate & Rental & Leasing">Real Estate & Rental & Leasing</option>
         <option value="Professional, Scientific & Technical Services">Professional, Scientific & Technical Services</option>

         <option value="Administrative">Administrative & Support Services, Waste Management & Remediation Service</option>
         <option value="Educational Services">Educational Services</option>
         <option value="Healthcare & Social Assistance">Healthcare & Social Assistance</option>
         <option value="Arts, Entertainment & Recreation">Arts, Entertainment & Recreation</option>

         <option value="Accommodation & Food Service">Accommodation & Food Service</option>
         <option value="Other Services">Other Services (e.g., Personal & Laundry Services, Repair & Maintenance)</option>
       </select>
     </div>
               </div>
               <div className="mb-4 w-full" >
                <label className="mb-2.5 block font-medium  text-black ">
                What motivates you to connect with newcomers?
                </label>
                <TextField
    placeholder=""
    type="text"
    className='w-full self-stretch flex'
   
    sx={{
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#FFFFFF', // Set background color
        '& fieldset': {
          borderColor: '#E0E0E0', // Set border color
        },
        '&:hover fieldset': {
          borderColor: '#E0E0E0', // Set border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: '#E0E0E0', // Set border color on focus
        },
      },
     
      '& .MuiInputBase-root': {
        marginTop: 0, // Remove top padding
        marginBottom: 0, // Remove bottom padding
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent', // Remove outline by default
      },
    }}
    fullWidth
    margin="normal"
    variant="outlined"
    required

  />

              </div>
               <div className="mb-4 w-full" >
                <label className="mb-2.5 block font-medium  text-black ">
                Any specific cultural backgrounds or experiences you'd prefer to connect with?
                </label>
                <TextField
    placeholder=""
    type="text"
    className='w-full self-stretch flex'
   
    sx={{
      '& .MuiOutlinedInput-root': {
        backgroundColor: '#FFFFFF', // Set background color
        '& fieldset': {
          borderColor: '#E0E0E0', // Set border color
        },
        '&:hover fieldset': {
          borderColor: '#E0E0E0', // Set border color on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: '#E0E0E0', // Set border color on focus
        },
      },
     
      '& .MuiInputBase-root': {
        marginTop: 0, // Remove top padding
        marginBottom: 0, // Remove bottom padding
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent', // Remove outline by default
      },
    }}
    fullWidth
    margin="normal"
    variant="outlined"
    required

  />

               </div>
             <button  onClick={(e) => { e.preventDefault(); handleNext();}}  className="justify-center w-full bg-[#3864FF] self-end px-11 py-5 mt-10 text-base font-semibold text-white-A700 whitespace-nowrap rounded-[31.5px] md:px-5">
              Next
            </button>
                </div>
            </div>
       
     </div>
           
        ): currentStep === 4 ? (
          <div className=" w-[50%] md:w-full self-end mt-10 flex items-center justify-center">
          <div className="self-end mt-10 w-full flex items-center justify-center">
                <div className="flex flex-col w-full md:ml-0 md:w-full">
                <div className="flex my-5 items-center gap-[11px] md:w-full">
                            <Text size="3xl" as="p" className=" !font-kumbhsans !text-blue_gray-700_01">
                              <>   
                             Aditional Information
                              </>
                            </Text>
                           
                          </div>
             
                          <div className="mb-4 w-full" >
                 <label className="mb-2.5 block font-medium  text-black ">
                 Briefly describe yourself and your interests
                 </label>
                 <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#FFFFFF] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                 <textarea
                               placeholder="Type here..."
                               className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#FFFFFF] font-hanken focus:outline-none text-[#858585] text-start"
                             />
                 </div>
               </div>
                      
                         
             <button  onClick={(e) => { e.preventDefault(); handleNext();}}  className="justify-center w-full bg-[#3864FF] self-end px-11 py-5 mt-10 text-base font-semibold text-white-A700 whitespace-nowrap rounded-[31.5px] md:px-5">
              Next
            </button>
                </div>
            </div>
       
     </div>
        ):currentStep === 5 ? (
          <div className=" w-[50%] md:w-full self-end mt-10 flex items-center justify-center">
          <div className="self-end mt-10 w-full flex items-center justify-center">
                <div className="flex flex-col w-full md:ml-0 md:w-full">
                <div className="flex my-5 items-center gap-[11px] md:w-full">
                            <Text size="3xl" as="p" className=" !font-kumbhsans !text-blue_gray-700_01">
                              <>   
                             Hobbies/Activity
                              </>
                            </Text>
                           
                          </div>
             
                          <div className="mb-4 w-full" >
                 <label className="mb-2.5 block font-medium  text-black ">
                 Do you have any hobbies or activities you'd like to share with your match? 
                 </label>
                 <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#FFFFFF] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                 <textarea
                               placeholder="Type here..."
                               className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#FFFFFF] font-hanken focus:outline-none text-[#858585] text-start"
                             />
                 </div>
               </div>
                      
                         
             <button  onClick={(e) => { e.preventDefault(); handleNext();}}  className="justify-center w-full bg-[#3864FF] self-end px-11 py-5 mt-10 text-base font-semibold text-white-A700 whitespace-nowrap rounded-[31.5px] md:px-5">
              Next
            </button>
                </div>
            </div>
       
     </div>
        ):currentStep === 6 ? (
          <div className=" w-[50%] md:w-full self-end mt-10 flex items-center justify-center">
          <div className="self-end mt-10 w-full flex items-center justify-center">
                <div className="flex flex-col w-full md:ml-0 md:w-full">
                <div className="flex my-5 items-center gap-[11px] md:w-full">
                            <Text size="3xl" as="p" className=" !font-kumbhsans !text-blue_gray-700_01">
                              <>   
                           Your Specialty
                              </>
                            </Text>
                           
                          </div>
             
                          <div className="mb-4 w-full" >
                 <label className="mb-2.5 block font-medium  text-black ">
                 Any specific areas you have experience in that you'd like to guide the newcomer in? 
                 </label>
                 <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#FFFFFF] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                 <textarea
                               placeholder="Type here..."
                               className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#FFFFFF] font-hanken focus:outline-none text-[#858585] text-start"
                             />
                 </div>
               </div>
                      
               <>
        {loading ? (
          <button   className="justify-center self-end px-11 py-5 mt-10 text-base font-semibold text-black whitespace-nowrap border-2 border-solid border-neutral-500 rounded-[31.5px] md:px-5" disabled={loading}>
         <CgSpinner className=" animate-spin text-lg items-center " />
          </button>
         ) : (
           
             <button type="submit" className="justify-center w-full bg-[#3864FF] self-end px-11 py-5 mt-10 text-base font-semibold text-white-A700 whitespace-nowrap rounded-[31.5px] md:px-5">
               Next
              </button>
         )}
     
       </>           
             
                </div>
            </div>
       
     </div>
        ):currentStep === 7 ? (
         <div className="self-end  mt-10 w-full flex items-center justify-center">
         <div className="flex flex-col w-full md:ml-0 md:w-full">
         <div className="flex w-[58%] my-5 items-center gap-[11px] md:w-full">
                     <Text size="6xl" as="p" className="w-full !font-kumbhsans !text-blue_gray-700_01">
                       <>
                       Social needs
                       </>
                     </Text>
                    
                   </div>
       
            
                   <div className="mb-4 w-full" >
                 <label className="mb-2.5 block font-medium  text-black ">
                 Specific challenges or concerns related to immigration and settlement
                 </label>
                 <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#FFFFFF] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                 <textarea
                               placeholder="Type here..."
                               className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#FFFFFF] font-hanken focus:outline-none text-[#858585] text-start"
                             />
                 </div>
               </div>
               <div className="mb-4 w-full" >
                 <label className="mb-2.5 block font-medium  text-black ">
                 Do you need any support services or resources?
                 </label>
                 <div className="w-full rounded-lg border border-[#E0E0E0] bg-[#FFFFFF] py-4 pl-6 pr-5 text-black outline-none focus:border-[#E0E0E0] focus-visible:shadow-none   ">
                 <textarea
                               placeholder="Type here..."
                               className="w-full h-auto bg-transparent text-[15px] border-[#E0E0E0] bg-[#FFFFFF] font-hanken focus:outline-none text-[#858585] text-start"
                             />
                 </div>
               </div>
         </div>
     </div>
        ) : (
             <></>
         )}
     </>
   </div>

   </form>


  </div>
<ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </div>
   
    </>
  );
}


