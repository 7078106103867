import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Input, Img, Text, Heading, Button } from "../../../../components";
import { NavLink } from "react-router-dom";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import ImageSlider from "components/ImageSlider";
import Typed, { ReactTyped } from 'react-typed'; // Import react-typed
import Header from "components/Header";
import Settings from "components/Settings";
import AuthService from "services/authService";
import axios from "axios";
import CategoryDropdown from "components/CategoryDropdown";

interface Page1Props {
  togglePage: () => void;
}
interface GoogleSearchResult {
  kind: string;
  title: string;
  htmlTitle: string;
  link: string;
  displayLink: string;
  snippet: string;
  htmlSnippet: string;
  formattedUrl: string;
  htmlFormattedUrl: string;
  pagemap: {
    cse_thumbnail?: { src: string; width: string; height: string }[];
    metatags?: Record<string, string>[];
    cse_image?: { src: string }[];
  };
}
const suggestions = [
  'Cultural Festivals & Events',
  'Community Events & Workshops',
  'Sports Clubs & Recreation Centers',
  'Arts & Performance Groups',
  'Museums & Art Galleries',
  'Parks & Outdoor Recreation',
  'Libraries & Archives',
  'Performing Arts Venues',
  'Nightlife & Entertainment Venues',
  'Cultural Sites & Historical Landmarks'
];
function Page2({ togglePage }: Page1Props) {
  const [query, setQuery] = useState<string>('Cultural Festivals and Celebrations in Canada');
  const [results, setResults] = useState<GoogleSearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isSearched, setisSearched] = useState(false);



  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    fetchGoogleSearchResults();
  };
  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);

  const handleAutoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      const filtered = suggestions.filter(suggestion =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleClick = (suggestion: string) => {
    setInputValue(suggestion);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setInputValue(filteredSuggestions[activeSuggestion]);
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    } else if (e.key === 'ArrowUp') {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.key === 'ArrowDown') {
      if (activeSuggestion === filteredSuggestions.length - 1) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
    }
  };

  const fetchGoogleSearchResults = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post<{ results: GoogleSearchResult[] }>(
        'https://linked-origin-server.vercel.app/api/v1/search/google-search',
        { "searchQuery": { "searchQuery": inputValue } },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data && response.data.results) {
        setResults(response.data.results);
        setisSearched(true);
      } else {
        setResults([]);
        setisSearched(true);
      }
    } catch (err) {
      setError('Failed to fetch Google search results');
      setisSearched(true);
    } finally {
      setLoading(false);
      setisSearched(true);
    }
  };
  const handleClickHere = async () => {
  
  };

  return (
    <>
      <Helmet>
        <title>Government Services - Assistance with Immigration and More</title>
        <meta
          name="description"
          content="Get help with immigration, employment, healthcare, and legal matters. Access government services and quick links to facilitate your settlement process."
        />
      </Helmet>

      <div className="  bg-center bg-cover bg-no-repeat my-auto flex flex-col items-center">
        {/* header section */}
        <Header />
        <div className="relative mx-auto flex w-full items-start justify-center gap-5   md:h-auto md:flex-col p-4 md:p-4">
          <Settings />
          <div className="flex flex-col w-full h-full items-center">
            <div className="flex gap-5 justify-between md:flex-wrap w-full px-10 md:hidden animate__animated animate__fadeInUp">
              <CategoryDropdown
                name="Community"
                img="/images/img_settings.svg"
              />
              <div className="flex flex-col px-5 text-center">
                <div className="flex flex-col px-1.5 pt-2 pb-1.5 text-base font-semibold rounded-2xl bg-zinc-100">
                  <div onClick={togglePage} className="text-black text-xs cursor-pointer">Ask Mon-Ami</div>
                  <div className="justify-center text-xs text-white-A700 px-9 py-2 mt-2 text-white whitespace-nowrap bg-gray-700 rounded-xl md:px-5 cursor-pointer">
                    Forum
                  </div>
                </div>
                <div className="mt-2.5 text-xs leading-4 text-stone-900">
                  Toggle to switch between <br />
                  Mon-Ami & search
                </div>
              </div>
            </div>
            
            <div className="mt-8 md:mt-4 w-full text-base sm:text-sm text-black md:w-full  px-10 md:px-0 pb-10 ">
            <div className="flex gap-3 self-start text-3xl font-semibold">
            <p className=" md:text-2xl text-3xl self-start bg-gradient2 bg-clip-text !text-transparent !font-kumbhsans ">
            Community
     </p>

<div className=" text-neutral-400 md:text-2xl text-3xl">(coming soon)</div>

</div>

<img

loading="lazy"

srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1b869a924f52879646e143514aeeced0d4f6411a815a84bc9de1d08fd76562c0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b869a924f52879646e143514aeeced0d4f6411a815a84bc9de1d08fd76562c0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b869a924f52879646e143514aeeced0d4f6411a815a84bc9de1d08fd76562c0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b869a924f52879646e143514aeeced0d4f6411a815a84bc9de1d08fd76562c0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b869a924f52879646e143514aeeced0d4f6411a815a84bc9de1d08fd76562c0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b869a924f52879646e143514aeeced0d4f6411a815a84bc9de1d08fd76562c0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b869a924f52879646e143514aeeced0d4f6411a815a84bc9de1d08fd76562c0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1b869a924f52879646e143514aeeced0d4f6411a815a84bc9de1d08fd76562c0?apiKey=40621ed5d4fd4492af4d4dffefbaaa2d&&apiKey=40621ed5d4fd4492af4d4dffefbaaa2d"

className="self-center my-5 w-full aspect-[2.86]  md:w-full"

/>

              <span className="font-semibold">Hey</span>, thanks for coming here, our

              forum feature is still in development and will be available on our next

              update. <br />

              <br />

              Once available, you will be able to <br />

              •⁠ ⁠Start a topic <br />

              •⁠ ⁠Contribute to important topics and <br />

              •⁠ ⁠Generally interact with other immigrants and locals like yourself.{" "}

              <br />

              <br />

              In the meantime, you can still chat with our AI (Mon Ami) or click below

              to go to our &quot;matching&quot; page. We are currently measuring

              interests for our &quot;matching&quot; feature. If you would like to be

              matched with a local to improve your Canadian life experience.{" "}

              <span onClick={handleClickHere} className="text-blue-600 underline">Click here</span> 
              
              {" "} to express

              your interest. <br />

              <br />

              Thanks

              <br />

              The Linked Origins team

            </div>


          </div>

        </div>



      </div>


    </>
  );
}

export default Page2;
