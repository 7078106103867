import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Input, Img, Text, Heading, Button } from "../../../../components";
import { NavLink, useNavigate } from "react-router-dom";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import ImageSlider from "components/ImageSlider";
import Typed, { ReactTyped } from 'react-typed'; // Import react-typed
import Header from "components/Header";
import Settings from "components/Settings";
import AuthService from "services/authService";
import axios from "axios";
import { SmallYouTubeVideo } from "components/VideoPlayer";
import CategoryDropdown from "components/CategoryDropdown";
import axiosInstance from "axiosSetup";
interface Page1Props {
  togglePage: () => void;
}
interface Job {
  title: string;
  description: string;
  company: string;
  location: string;
  redirectUrl: string;
  created: string;
}

interface Province {
  province: string;
  latitude: any;
  longitude: any;
}
interface Location {
  lat: number;
  lng: number;
}

interface Viewport {
  northeast: Location;
  southwest: Location;
}

interface Geometry {
  location: Location;
  viewport: Viewport;
}

interface Photo {
  height: number;
  html_attributions: string[];
  photo_reference: string;
  width: number;
}

interface Result {
  business_status: string;
  formatted_address: string;
  geometry: Geometry;
  icon: string;
  name: string;
  // opening_hours: { open_now: boolean };
  photos: Photo[];
  place_id: string;
  plus_code: { compound_code: string; global_code: string };
  rating: number;
  reference: string;
  types: string[];
  user_ratings_total: number;
}

interface ApiResponse {
  results: Result[];
  length: number;
}



const provinces: Province[] = [
  { province: "Alberta", latitude: 53.9333, longitude: -116.5765 },
  { province: "British Columbia", latitude: 53.7267, longitude: -127.6476 },
  { province: "Manitoba", latitude: 49.8951, longitude: -97.1384 },
  { province: "New Brunswick", latitude: 46.5653, longitude: -66.4619 },
  { province: "Newfoundland and Labrador", latitude: 53.1355, longitude: -57.6604 },
  { province: "Nova Scotia", latitude: 44.6820, longitude: -63.7443 },
  { province: "Ontario", latitude: 51.2538, longitude: -85.3232 },
  { province: "Prince Edward Island", latitude: 46.5107, longitude: -63.4168 },
  { province: "Quebec", latitude: 52.9399, longitude: -73.5491 },
  { province: "Saskatchewan", latitude: 52.9399, longitude: -106.4509 }
];
const servicesSuggestions = [
  'Housing & Rentals',
  'Real Estate Agents',
  'Employment Services',
  'Grooming Services',
  'Professional Services (Lawyers, Accountants, Insurance)',
  'Financial Services (Banks, Money Transfer Services)',
  'Healthcare Providers (Doctors, Dentists, Pharmacies)',
  'Education & Training (Language Schools, Vocational Schools)',
  'Grocery Stores & Restaurants (Catering to Diverse Cuisines)',
  'Transportation Services (Public Transit, Car Rentals)',
  'Telecommunication Services (Mobile Phone Plans, Internet Providers)',
  'Religious Organizations (Consider if relevant based on location)'
];
function Page2({ togglePage}: Page1Props){
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isSearched, setisSearched] = useState(false);
    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const handleCloseMenu = () => {
      setMenuOpen(false);
    };
    const data = [
      { image: "/images/img_istockphoto_113.png", wherecani: "Where can i find healthcare facilities?" },
      { image: "/images/img_istockphoto_171.png", wherecani: "What are government policies in healthcare?" },
      { image: "/images/img_istockphoto_143.png", wherecani: "What are government policies in healthcare?" },
      { image: "/images/img_istockphoto_148.png", wherecani: "What are government policies in healthcare?" },
    ];

    const datas = [
      { 
       title: "Front End Developer",
       comapany: "The Max Company",
       country: "Canada",
       salary: "100k -200k",
       jobType: "Remote",
       description: "Help back-end developer with codeing and troubleshooting",
       description2: "Collaborate with back-end and designers to improve usability",
       time: "2 day",

     },
     { 
      title: "Back End Developer",
      comapany: "The Max Company",
      country: "Canada",
      salary: "150k -200k",
      jobType: "Remote",
      description: "Help back-end developer with codeing and troubleshooting",
      description2: "Collaborate with back-end and designers to improve usability",
      time: "3 day",

    },
     
    ];
 
    const [query, setQuery] = useState<string>('Business near');
    const [jobs, setJobs] = useState<Job[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [location, setLocation] = useState<string>('ontario');
    const [selectedProvince, setSelectedProvince] = useState<Province | null>(null);
    const [lat, setLat] = useState<string>('');
    const [long, setLong] = useState<string>('');
    const [clinics, setClinics] = useState<Result[]>([]);



    const handleSearch = (e: React.FormEvent) => {
      e.preventDefault();
      fetchJobListings();
      
    };


const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
const province = provinces.find(p => p.province === event.target.value) || null;
setSelectedProvince(province);
setLat(province.latitude);
setLong(province.longitude);
console.log('lat',province.latitude)
console.log('lng',province.longitude)

};
const [inputValue, setInputValue] = useState('');
const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
const [showSuggestions, setShowSuggestions] = useState(false);
const [activeSuggestion, setActiveSuggestion] = useState(0);

const handleAutoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  setInputValue(value);
  if (value) {
    const filtered = servicesSuggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredSuggestions(filtered);
    setShowSuggestions(true);
  } else {
    setShowSuggestions(false);
  }
};

const handleClick = (suggestion: string) => {
  setInputValue(suggestion);
  setFilteredSuggestions([]);
  setShowSuggestions(false);
};

const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  if (e.key === 'Enter') {
    setInputValue(filteredSuggestions[activeSuggestion]);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  } else if (e.key === 'ArrowUp') {
    if (activeSuggestion === 0) {
      return;
    }
    setActiveSuggestion(activeSuggestion - 1);
  } else if (e.key === 'ArrowDown') {
    if (activeSuggestion === filteredSuggestions.length - 1) {
      return;
    }
    setActiveSuggestion(activeSuggestion + 1);
  }
};

const fetchJobListings = async () => {
  setLoading(true);
  setError(null);

  console.log('lat',lat)
  console.log('lng',long)
   try {
       const token = localStorage.getItem("token");
       const response = await axios.post<ApiResponse>('https://linked-origin-server.vercel.app/api/v1/search/location-search/', {
        "query":inputValue,
        "lat": lat,
        "lng": long
      }, 
      {
         headers: {
           Authorization: `Bearer ${token}`,
       },
       });
       setisSearched(true);
       setClinics(response.data.results);
       console.log(response.data.results);
     } catch (err) {
      setisSearched(true);
       setError('Failed to fetch data');
     } finally {
       setisSearched(true);
       setLoading(false);
     }
}; 
const navigate = useNavigate()

   
const handleclickVendor = () => {
  navigate('/vendor');
};


 

  
      return (
        <>
          <Helmet>
            <title>Government Services - Assistance with Immigration and More</title>
            <meta
              name="description"
              content="Get help with immigration, employment, healthcare, and legal matters. Access government services and quick links to facilitate your settlement process."
            />
          </Helmet>
    
          <div className="  bg-center bg-cover bg-no-repeat my-auto flex flex-col items-center">
              {/* header section */}
              <Header/>
              <div className="relative mx-auto flex w-full items-start justify-center gap-5   md:h-auto md:flex-col md:p-4">
              <Settings/>
              <div className="flex flex-col w-full h-full items-center">
              <div className="flex gap-5 justify-between md:flex-wrap w-full px-10 md:hidden animate__animated animate__fadeInUp">
        <CategoryDropdown
          name="Local Resources"
          img= "/images/img_group_57.svg"
         />

      <div className="flex flex-col px-5 text-center">
        <div className="flex flex-col px-1.5 pt-2 pb-1.5 text-base font-semibold rounded-2xl bg-zinc-100">
          <div onClick={togglePage}  className="text-black text-xs cursor-pointer">Ask Mon-Ami</div>
          <div className="justify-center text-xs text-white-A700 px-9 py-2 mt-2 text-white whitespace-nowrap bg-gray-700 rounded-xl md:px-5 cursor-pointer">
            Search
          </div>
        </div>
        <div className="mt-2.5 text-xs leading-4 text-stone-900">
          Toggle to switch between <br />
          Mon-Ami & search
        </div>
      </div>
               </div>
               <p className="md:block hidden  md:text-2xl text-3xl text-left self-start bg-gradient2 bg-clip-text !text-transparent !font-kumbhsans ">
         Local Resources
              </p>
                {/* introductory section */}
                <div className="mb-7   md:pt-0 h-full flex w-[80%] flex-col items-start gap-10 md:w-full md:gap-5 sm:gap-10e">
                  {/* questions list section */}
                  <div className="flex flex-col w-full">
                    <div className="flex flex-col   md:my-0">
      <div className="ml-20 md:ml-0  md:flex-col flex flex-row w-full items-end justify-end gap-2   ">
        
        <div className="pt-10 md:pt-0 flex flex-col items-end gap-[3px]  w-full">
                        <div className="mr-7 flex w-[27%] flex-wrap justify-end gap-5 md:mr-0 md:w-full">
                          <Heading onClick={togglePage}  className="cursor-pointer !text-gray-800 text-base md:text-sm ">
                            Ask Mon-Ami
                          </Heading>
                          <Heading   className="cursor-pointer !text-gray-800 text-base md:text-sm border-b-2 border-red-500 ">
                              Search
                          </Heading>
                        </div>
                        <div className="self-stretch rounded-md  bg-white-A700 p-[9px] md:p-0 md:pt-2 border border-gray-400 md:border-none">
                        <form onSubmit={handleSearch}>
                            <div className="flex flex-col gap-[27px]">
                            <select
        onChange={handleChange}
        defaultValue=""
        className="p-2 border border-gray-300 text-base md:text-sm rounded w-full"
      >
        <option value="" disabled>Select a province</option>
        {provinces.map((province, index) => (
          <option key={index} value={province.province}>{province.province}</option>
        ))}
   
      </select>
                              <div className="flex items-center justify-between gap-5 h-12  border border-gray-200 p-2 rounded-md">

                              <div className="relative w-full">
                          <input
                            type="text"
                            value={inputValue}
                            onChange={handleAutoChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Type here to search Local Resource"
                            />
                          {showSuggestions && inputValue && (
                            <ul className="absolute z-10 w-full bg-white  rounded-md mt-1  overflow-auto">
                              {filteredSuggestions.length ? (
                                filteredSuggestions.map((suggestion, index) => (
                                  <li
                                    key={suggestion}
                                    className={`px-4 py-2 cursor-pointer bg-white-A700 hover:bg-red-400 hover:text-white-A700 border border-gray-300 ${
                                      index === activeSuggestion ? 'text-white' : ''
                                    }`}
                                    onMouseDown={() => handleClick(suggestion)}
                                    onMouseEnter={() => setActiveSuggestion(index)}
                                  >
                                    {suggestion}
                                  </li>
                                ))
                              ) : (
                                <li className="px-4 py-2"></li>
                              )}
                            </ul>
                          )}
                                </div>
                               <div className= "flex flex-row h-full items-center justify-between gap-2 pl-2 w-[20%]   border-l border-blue_gray-100_01 md:hidden">
                              
                              
                                <button  type="submit" className= " justify-center self-end  w-32 text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-8  rounded-[23px]" > search
                                </button>
                               </div>
                            
                              </div>
                           
                            </div>
                             <button className= "hidden md:block justify-center md:w-full  mt-4 w-[15%] text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-12  rounded-[23px]" > Search
                            </button>
                            </form>
                          </div>
        </div>
  
        <button className= "invisible md:hidden  justify-center md:w-full  w-[15%] text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-14  rounded-[23px]" > Post your resume
        </button>
        </div>
      </div>
   
    
                    {/* healthcare questions section */}
                    <div className=" ml-20 md:ml-0 mr-10 md:mr-0 flex flex-col my-10 md:my-5 ">

                  
                    <div>
         
            {loading && 
             <div className="flex flex-col gap-4 mt-2 w-full overflow-hidden">  
                    <div className="flex  w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
                    <div className="flex  w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
                    <div className="flex w-[100%] h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>

                    </div>}
            {error && <div>{error}</div>}
            
            {!loading && !error && clinics.length  > 0 && (
         <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
           {clinics.map((clinic) => (
            <>
            
            <div key={clinic.place_id} className="flex flex-col  bg-gray-100 border border-gray-200 p-5 items-center my-2 gap-2 animate__animated animate__fadeInUp">
                          <p  className="text-base font-bold self-start">
                          {clinic.name}
                          </p>
                          <p  className="text-sm font-normal self-start">
                          {clinic.formatted_address}
                            </p>
                          <p className="text-sm font-normal self-start">Status: {clinic.business_status}</p>
                          <p className="text-xs font-normal self-start text-red-400_01">Rating: {clinic.rating} ({clinic.user_ratings_total} reviews)</p>
                          <div className="flex flex-row md:flex-col w-full gap-5 md:gap-2 items-start my-2 ">
                          <a
                          href={`https://maps.google.com/?q=${clinic.geometry.location.lat},${clinic.geometry.location.lng}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 text-xs self-start"
                        >
                          View on Google Maps
                        </a>
                         
                          </div>
                       
                        </div>
            </>
          ))}
        </div>
      )}
   {!loading && !error && clinics.length === 0 && inputValue===""&& isSearched===false&& 
      <div className="flex flex-col"> 
      
      <p className=" md:text-xl text-xl self-start !font-kumbhsans ">
      Explore your neighborhood
     </p>
      <div className="mt-2 text-sm  text-gray-600 w-full">
      Search for essential services nearby.</div>
      <div className="mt-2 text-sm  text-gray-600 w-full">
      Easily find what you need, from barbers and salons to rentals and transportation options.</div>
      <div className="mt-2 text-sm  text-gray-600 w-full">
      Let us help you locate essential services close to your neighborhood.</div>
      <div className="mt-4 text-sm  text-black-900 font-normal w-full">
      If u are a small business or vendor and will like to join our free listing, <span onClick={handleclickVendor} className="text-blue-600 cursor-pointer"> click here </span>to register and fill our vendor form. Terms and conditions apply.
      </div>
  
      </div>
      
      }
      {!loading && !error && clinics.length === 0 && isSearched===true&& <div className="flex flex-col"> 
      
      <div className="mt-5  text-sm text-black w-full font-bold">
      Oppss!!!, no data available
       </div></div>}  </div>
    
                  
                    </div>
                   
                    </div>
    
            
                </div>
    

              </div>
           
              </div>
          
    
    
            </div>
    
       
        </>
      );
  }

export default Page2;
