import React from "react";
import { Img } from "./..";

interface Props {
  className?: string;
}

export default function HomeScreenRowcloseOne({ ...props }: Props) {
  return (
    <div {...props}>
       <a href="https://web.facebook.com/profile.php?id=61562557055064" target="_blank"> <Img src="/images/fbook.svg" alt="close_one" className="h-[35px] w-[35px]" /></a>
       <a href="https://x.com/linked%20origins" target="_blank"> <Img src="/images/img_airplane.svg" alt="airplane_one" className="h-[29px]" /></a>

       <a href="https://instagram.com/linked.origins" target="_blank">  <Img src="/images/img_close_black_900.svg" alt="close_three" className="h-[35px] w-[35px]" /></a>

       <a href="https://threads.net/linked.origins" target="_blank"> <Img src="/images/img_settings_white_a700_35x30.svg" alt="settings_three" className="h-[35px]" /></a>
      
      {/* <Img src="https://images/img_link.svg" alt="link_one" className="h-[35px] w-[35px]" /> */}
    </div>
  );
}
