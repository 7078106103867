import React, { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Input, Img, Text, Heading, Button } from "../../../../components";
import { NavLink } from "react-router-dom";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import ImageSlider from "components/ImageSlider";
import Typed, { ReactTyped } from 'react-typed'; // Import react-typed
import Header from "components/Header";
import Settings from "components/Settings";
import AuthService from "services/authService";
import axios from "axios";
import CategoryDropdown from "components/CategoryDropdown";

interface Page1Props {
  togglePage: () => void;
}
interface GoogleSearchResult {
  kind: string;
  title: string;
  htmlTitle: string;
  link: string;
  displayLink: string;
  snippet: string;
  htmlSnippet: string;
  formattedUrl: string;
  htmlFormattedUrl: string;
  pagemap: {
    cse_thumbnail?: { src: string; width: string; height: string }[];
    metatags?: Record<string, string>[];
    cse_image?: { src: string }[];
  };
}
const suggestions = [
  'Banks & Credit Unions',
  'Money Transfer Services',
  'Financial Literacy Programs',
  'Financial Literacy Class',
  'Microfinance Institutions',
  'Investment & Retirement Planning',
  'Credit Counseling Services',
  'Credit Counselor',
  'Mortgage & Homeownership Services',
  'Tax Preparation Services',
  'Tax Preparer'
];
function Page2({ togglePage}: Page1Props){
  const [query, setQuery] = useState<string>('what investment options are best');
  const [results, setResults] = useState<GoogleSearchResult[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isSearched, setisSearched] = useState(false);


  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    fetchGoogleSearchResults();
  };

  const [inputValue, setInputValue] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState(0);

  const handleAutoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    if (value) {
      const filtered = suggestions.filter(suggestion =>
        suggestion.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSuggestions(filtered);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }
  };

  const handleClick = (suggestion: string) => {
    setInputValue(suggestion);
    setFilteredSuggestions([]);
    setShowSuggestions(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setInputValue(filteredSuggestions[activeSuggestion]);
      setFilteredSuggestions([]);
      setShowSuggestions(false);
    } else if (e.key === 'ArrowUp') {
      if (activeSuggestion === 0) {
        return;
      }
      setActiveSuggestion(activeSuggestion - 1);
    } else if (e.key === 'ArrowDown') {
      if (activeSuggestion === filteredSuggestions.length - 1) {
        return;
      }
      setActiveSuggestion(activeSuggestion + 1);
     }
    };

   const fetchGoogleSearchResults = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem("token");

      const response = await axios.post<{ results: GoogleSearchResult[] }>(
        'https://linked-origin-server.vercel.app/api/v1/search/google-search',
        {"searchQuery": {"searchQuery":inputValue}},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data && response.data.results) {
        setResults(response.data.results);
        setisSearched(true);
      } else {
        setResults([]);
        setisSearched(true);
      }
    } catch (err) {
      setError('Failed to fetch Google search results');
      setisSearched(true);
    } finally {
      setLoading(false);
      setisSearched(true);
    }
    };
      return (
        <>
          <Helmet>
            <title>Government Services - Assistance with Immigration and More</title>
            <meta
              name="description"
              content="Get help with immigration, employment, healthcare, and legal matters. Access government services and quick links to facilitate your settlement process."
            />
          </Helmet>
    
          <div className="  bg-center bg-cover bg-no-repeat my-auto flex flex-col items-center">
              {/* header section */}
              <Header/>
              <div className="relative mx-auto flex w-full items-start justify-center gap-5   md:h-auto md:flex-col p-4 md:p-4">
              <Settings/>
              <div className="flex flex-col w-full h-full items-center">
              <div className="flex gap-5 justify-between md:flex-wrap w-full px-10 md:hidden animate__animated animate__fadeInUp">
             <CategoryDropdown
              name="Finance"
              img= "/images/img_settings_white_a700.svg"
             />
             <div className="flex flex-col px-5 text-center">
              <div className="flex flex-col px-1.5 pt-2 pb-1.5 text-base font-semibold rounded-2xl bg-zinc-100">
                <div onClick={togglePage}  className="text-black text-xs cursor-pointer">Ask Mon-Ami</div>
                <div className="justify-center text-xs text-white-A700 px-9 py-2 mt-2 text-white whitespace-nowrap bg-gray-700 rounded-xl md:px-5 cursor-pointer">
                  Search
                </div>
              </div>
              <div className="mt-2.5 text-xs leading-4 text-stone-900">
                Toggle to switch between <br />
                Mon-Ami & search
              </div>
             </div>
               </div>
               <p className="md:block hidden  md:text-2xl text-3xl text-left self-start bg-gradient2 bg-clip-text !text-transparent !font-kumbhsans ">
               Finance
              </p>
                {/* introductory section */}
                <div className="mb-7   md:pt-0 h-full flex w-full flex-col items-center justify-center gap-10 md:w-full md:gap-5 sm:gap-10e">
                  {/* questions list section */}
                  <div className="flex flex-col w-[70%] md:w-full">
                    <div className="flex flex-col   md:my-0">
      <div className=" md:ml-0  md:flex-col flex flex-row  w-full items-end justify-end gap-2   ">
        
        <div className="pt-10 md:pt-0 flex flex-col items-end gap-[3px]  w-full">
                        <div className="mr-7 flex w-[27%] flex-wrap justify-end gap-5 md:mr-0 md:w-full">
                          <Heading onClick={togglePage}  className="cursor-pointer !text-gray-800 text-base md:text-sm ">
                            Ask Mon-Ami
                          </Heading>
                          <Heading   className="cursor-pointer !text-gray-800 text-base md:text-sm border-b-2 border-red-500 ">
                              Search
                          </Heading>
                        </div>
                        <div className="self-stretch rounded-md  bg-white-A700 p-[9px] md:p-0 md:pt-2 border border-gray-400 md:border-none">
                        <form onSubmit={handleSearch}>
                            <div className="flex flex-col gap-[27px]">
                            
                              <div className="flex items-center justify-between gap-5 h-12  border border-gray-200 p-2 rounded-md">

                                <div  className="flex items-center w-full text-base h-full md:text-sm outline-none border-none focus:outline-none border border-black-900_2d ">
                             
                                <div className="relative w-full">
                          <input
                            type="text"
                            // className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={inputValue}
                            onChange={handleAutoChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Type here to financial services"
                          />
                          {showSuggestions && inputValue && (
                            <ul className="absolute z-10 w-full bg-white  rounded-md mt-1  overflow-auto">
                              {filteredSuggestions.length ? (
                                filteredSuggestions.map((suggestion, index) => (
                                  <li
                                    key={suggestion}
                                    className={`px-4 py-2 cursor-pointer bg-white-A700 hover:bg-red-400 hover:text-white-A700 border border-gray-300 ${
                                      index === activeSuggestion ? 'text-white' : ''
                                    }`}
                                    onMouseDown={() => handleClick(suggestion)}
                                    onMouseEnter={() => setActiveSuggestion(index)}
                                  >
                                    {suggestion}
                                  </li>
                                ))
                              ) : (
                                <li className="px-4 py-2"></li>
                              )}
                            </ul>
                          )}
                                </div>
            
              
          
                               
  
                               </div>
                               <div className= "flex flex-row h-full items-center justify-between gap-2 pl-2 w-[20%]   border-l border-blue_gray-100_01 md:hidden">
                              
                              
                                <button  type="submit" className= " justify-center self-end  w-32 text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-8  rounded-[23px]" > Search
                                </button>
                               </div>
                            
                              </div>
                           
                            </div>
                             <button className= "hidden md:block justify-center md:w-full  mt-4 w-[15%] text-xs   hover:-translate-y-1 hover:scale-110 duration-300   sm:px-5 font-hankengrotesk text-white-A700 whitespace-nowrap bg-[#3864FF]  h-12  rounded-[23px]" > Search
                            </button>
                            </form>
                          </div>
        </div>
   
        </div>
    
      </div>
   
    
                    {/* healthcare questions section */}
                    <div className=" ml-20 md:ml-0 mr-10 md:mr-0 flex flex-col my-10 md:my-5 ">


                  
                    <div>
         
            {loading && 
             <div className="flex flex-col gap-4 mt-2">  
                    <div className="flex  w-full h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
                    <div className="flex  w-full h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>
                    <div className="flex  w-full h-10 flex-col items-center gap-4 rounded-sm bg-red-400_01 bg-opacity-20 animate-pulse "/>

                    </div>}
            {error && <div>{error}</div>}
            {!loading && !error && results.length > 0 && (
        <div className="grid grid-cols-1  gap-4">
          {results.map((result, index) => (
            <div key={index} className="bg-white border border-gray-200 rounded-lg p-4 shadow animate__animated animate__fadeInUp">
              <h2 className="text-base font-bold" dangerouslySetInnerHTML={{ __html: result.htmlTitle }}></h2>
              <p className="text-gray-600 text-sm" dangerouslySetInnerHTML={{ __html: result.htmlSnippet }}></p>
              <a
                href={result.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline mt-2 block text-sm"
              >
                Visit Link
              </a>
              {result.pagemap.cse_image && result.pagemap.cse_image[0] && (
                <img
                  src={result.pagemap.cse_image[0].src}
                  alt={result.title}
                  className="w-full h-auto mt-2"
                />
              )}
            </div>
          ))}
        </div>
      )}
         {!loading && !error && results.length === 0 && inputValue===""&& isSearched===false&& 
      <div className="flex flex-col"> 
      
      <p className=" md:text-xl text-xl self-start !font-kumbhsans ">
      Be enriched in money matters
     </p>
      <div className="mt-2 text-sm  text-gray-600 w-full">
      Find the nearest financial institution where you can open a bank account, send/receive money internationally.</div>
      <div className="mt-2 text-sm  text-gray-600 w-full">
      Gain financial knowledge & plan for your future.</div>
     
  
      </div>
      
      }
      {!loading && !error && results.length === 0 && isSearched===true&& <div className="flex flex-col"> 
      
      <div className="mt-5  text-sm text-black w-full font-bold">
      Oppss!!!, no data available
       </div></div>}
        </div>
    
                  
                    </div>
                   
                    </div>
    
            
                </div>
    

              </div>
           
              </div>
          
    
    
            </div>
    
       
        </>
      );
  }

export default Page2;
