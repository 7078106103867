import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { toast, Bounce, ToastContainer } from "react-toastify";
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';
import { NavLink, useNavigate } from "react-router-dom";
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import { Img, Heading, Button,Text } from "components";
import HomeScreenRowcloseOne from "components/HomeScreenRowcloseOne";
import { scroller } from 'react-scroll';


export default function TermsService() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const navbarRef = useRef(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [loggedIn, setLoggedIn] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string>('');
   const [message, setMessage] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    try {

      const response = await axios.post('https://linked-origin-server.vercel.app/api/v1/subscription/newsletter/subscribe', {
        email,
      });
      if (response.status === 201||response.status === 200) {
        setMessage('Subscription successful!');
        toast.success('Email subscription sent successfully', {
          position:"top-right",
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setLoading(false);
      } else {
        setMessage('Subscription failed. Please try again.');
        setLoading(false);

      }
    } catch (error) {
      console.error('There was an error subscribing to the newsletter!', error);
      setMessage('Subscription failed. Please try again.');
      setLoading(false);

    }
  };
    useEffect(() => {
      // Check local storage for user data
      const userData = localStorage.getItem('userData');
      if (userData) {
        // If user data is found, consider the user logged in
        setLoggedIn(true);
      }
    }, []);
    const handleMenuToggle = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    const handleCloseMenu = () => {
      setMenuOpen(false);
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1, // Display two items at a time
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768, // Adjust breakpoint as needed
          settings: {
            slidesToShow: 1, // Show one item at a time on smaller screens
          },
        },
      ],
      
    };
    const items = [
        // { id: 1, name: 'Government',icon:'images/img_home.svg' },
        // { id: 2, name: 'Community',icon:'images/img_settings.svg'  },
        // { id: 3, name: 'Educational' ,icon:'images/img_thumbs_up.svg' },
        // { id: 4, name: 'Healthcare',icon:'images/img_group_55.svg'  },
        // { id: 5, name: 'Legal & Immigration service' ,icon:'images/img_group_52.svg' },
        // { id: 6, name: 'Cultural & Recreational Activities',icon:'images/img_thumbs_up_white_a700.svg'  },
        // { id: 7, name: 'Finance',icon:'images/img_settings_white_a700.svg'  },
        // { id: 8, name: 'Social',icon:'images/img_group_59.svg'  },
        // { id: 9, name: 'Investment' ,icon:'images/img_group_57.svg' },
        { icon: "/images/img_home.svg", name: "Government", route: 'government' },
        { icon: "/images/img_settings.svg", name: "Community" ,route: 'community' },
        { icon: "/images/img_thumbs_up.svg", name: "Educational" ,route: 'educational'},
        { icon: "/images/img_group_55.svg", name: "Healthcare" ,route: 'healthcare'},
        { icon: "/images/img_group_52.svg", name: "Legal & Immigration service",route: 'immigration' },
        {
          icon: "/images/img_thumbs_up_white_a700.svg",
          name: "Cultural & Recreational Activities"
          ,route: 'cultural'
        },
        { icon: "/images/img_settings_white_a700.svg", name: "Finance",route: 'finance' },
        { icon: "/images/img_group_59.svg", name: "Social" ,route: 'social'},
        { icon: "/images/img_group_57.svg", name: "Local Resource" ,route: 'investment'},
      ];

      const images = [
        'images/img_slider_1.png',
        'images/img_slider_2.png',
        'images/img_slider_3.png',
        'images/img_slider_4.png',
        // 'images/img_slider_4.png',
        // 'images/img_slider_3.png',
        // 'images/img_slider_2.png',
        // 'images/img_slider_1.png',

      ];
     


    const handlecategory = () => {
      toast.warn('Sign up to get AI powered assistance in this category', {
        position:"top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      
    };
    const homeRef = useRef(null);
    const aboutRef = useRef(null);
    const servicesRef = useRef(null);
    const portfolioRef = useRef(null);
    const contactRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('animate__animated', 'animate__fadeInUp');
            }
          });
        },
        { threshold: 0.1 } // Trigger when 10% of the element is visible
      );
  
      const sections = [homeRef.current, aboutRef.current, servicesRef.current, portfolioRef.current, contactRef.current];
      sections.forEach((section) => {
        if (section) {
          observer.observe(section);
        }
      });
  
      return () => {
        sections.forEach((section) => {
          if (section) {
            observer.unobserve(section);
          }
        });
      };
    }, []);
    const [ref, inView] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refabout, inViewAbout] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refLeft, inViewLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refRight, inViewRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThing, inViewEveryThing] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThingBottom, inViewEveryThingBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refEveryThingLeft, inViewEveryThingLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refEveryThingRight, inViewEveryThingRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refDownloadBottom, inViewDownloadBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refDownloadLeft, inViewDownloadLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refDownloadRight, inViewDownloadRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refBottom, inViewBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
    const [refAboutLeft, inViewAboutLeft] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refAboutRight, inViewAboutRight] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const [refAboutBottom, inViewAboutBottom] = useInView({
      triggerOnce: true, // Only trigger once
      threshold: 0.5, // Trigger when 50% of the element is visible
    });
  
    const animation = useSpring({
      opacity: 1,
      transform: 'translateY(0)',
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 1000 },
    });
    const animationLeft = useSpring({
      opacity: inViewLeft ? 1 : 0,
      transform: inViewLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationRight = useSpring({
      opacity: inViewRight ? 1 : 0,
      transform: inViewRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationEveryThing = useSpring({
      opacity: inViewEveryThing ? 1 : 0,
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 700 },
    });
    const animationEveryThingLeft = useSpring({
      opacity: inViewEveryThingLeft ? 1 : 0,
      transform: inViewEveryThingLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationEveryThingRight = useSpring({
      opacity: inViewEveryThingRight ? 1 : 0,
      transform: inViewEveryThingRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationEveryThingBottom = useSpring({
      opacity: inViewEveryThingBottom ? 1 : 0,
      transform: inViewEveryThingBottom ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const animationAbout = useSpring({
      opacity: 1,
      transform: 'translateY(0)',
      from: { opacity: 0, transform: 'translateY(50px)' },
      config: { duration: 700 },
    });
  
  
    const bottomAnimation = useSpring({
      opacity: inViewBottom ? 1 : 0,
      transform: inViewBottom ? 'translateY(0)' : 'translateY(50px)',
      config: { duration: 700 }, // Adjust animation duration
    });
    const animationAboutLeft = useSpring({
      opacity: inViewAboutLeft ? 1 : 0,
      transform: inViewAboutLeft ? 'translateX(0)' : 'translateX(-50px)', // Translate from left
      config: { duration: 700 },
    });
  
    const animationAboutRight = useSpring({
      opacity: inViewAboutRight ? 1 : 0,
      transform: inViewAboutRight ? 'translateX(0)' : 'translateX(50px)', // Translate from right
      config: { duration: 700 },
    });
    const bottomAboutAnimation = useSpring({
      opacity: inViewAboutBottom ? 1 : 0,
      transform: inViewAboutBottom ? 'translateY(0)' : 'translateY(50px)',
      config: { duration: 700 }, // Adjust animation duration
    });
    const navigate = useNavigate();

  const navigateToAboutSection = () => {
    navigate('/');
    setTimeout(() => {
      scroller.scrollTo('service', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }, 100);
  };
  const navigateToContactSection = () => {
    navigate('/contact-us');
  };

  return (
    <div className="flex flex-col items-center bg-white">
             <nav className="flex self-stretch justify-between items-center  w-full z-50 gap-5 p-10 md:p-4  bg-[#191919D1] bg-opacity-20 right-3 rounded-bl-[50px] md:rounded-bl-none rounded-br-[50px] md:rounded-br-none" ref={navbarRef}>
        <div className="container mx-auto flex  justify-between items-center">
        <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-8 md:h-6 " />

          <div className="hidden md:block  ">
            {isMenuOpen ? (
              <button 
                onClick={handleCloseMenu}
                className="text-white-A700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            ) : (
              <button 
                onClick={handleMenuToggle}
                className="text-white-A700 focus:outline-none"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </button>
            )}
          </div>
  
          {isMenuOpen && (
            <div className="hidden md:block m-auto items-center  justify-center  absolute top-14 right-0 left-0  bg-black-900 bg-opacity-80 w-full h-auto shadow p-4">
             
          
          <div className="flex flex-col gap-5 text-white-A700" >
          <NavLink to="/">
          <div>Home</div>
            </NavLink>
          <NavLink to="/about">
          <div>About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/about">
          <div>Matching</div>
            </NavLink>
              {/* {loggedIn ? (  
      <>
        <NavLink to="/dashboard/welcome">
            <div className="text-white-A700">Continue</div>
            </NavLink>

  </>
      ) : (

        <NavLink to="/auth/login">
        <div className="text-white-A700">Login</div>
        </NavLink>
      )} */}
             
  
          </div>
            </div>
          )}
  
          <div className="md:hidden  flex flex-row  items-center justify-center">
    
          <div className="flex gap-5 justify-between  md:flex-wrap">
            <div className="flex gap-5 justify-between self-start text-black md:flex-wrap md:w-full">
            <NavLink to="/">
            <div className="font-bold text-white-A700">Home</div>
            </NavLink>
            <NavLink to="/about">
            <div className="text-[#898989] hover:text-white-A700">About Us</div>
            </NavLink>
            <div onClick={navigateToAboutSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Service</div>
            <div onClick={navigateToContactSection} className="cursor-pointer text-[#898989] hover:text-white-A700">Contact Us</div>
            <NavLink to="/matching/locals">
            <div className="cursor-pointer text-[#898989] hover:text-white-A700">Matching</div>
            </NavLink>
 
            
            </div>
            
           
          </div>
          </div>
          <div className="md:hidden  flex flex-row items-center gap-2">
  
    <div className="flex flex-row gap-4 items-center justify-center ">
                                
                                     <svg width="77" height="40" viewBox="0 0 77 63" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M47.97 38.33C47.97 38.69 47.89 39.06 47.72 39.42C47.55 39.78 47.33 40.12 47.04 40.44C46.55 40.98 46.01 41.37 45.4 41.62C44.8 41.87 44.15 42 43.45 42C42.43 42 41.34 41.76 40.19 41.27C39.04 40.78 37.89 40.12 36.75 39.29C35.6 38.45 34.51 37.52 33.47 36.49C32.44 35.45 31.51 34.36 30.68 33.22C29.86 32.08 29.2 30.94 28.72 29.81C28.24 28.67 28 27.58 28 26.54C28 25.86 28.12 25.21 28.36 24.61C28.6 24 28.98 23.44 29.51 22.94C30.15 22.31 30.85 22 31.59 22C31.87 22 32.15 22.06 32.4 22.18C32.66 22.3 32.89 22.48 33.07 22.74L35.39 26.01C35.57 26.26 35.7 26.49 35.79 26.71C35.88 26.92 35.93 27.13 35.93 27.32C35.93 27.56 35.86 27.8 35.72 28.03C35.59 28.26 35.4 28.5 35.16 28.74L34.4 29.53C34.29 29.64 34.24 29.77 34.24 29.93C34.24 30.01 34.25 30.08 34.27 30.16C34.3 30.24 34.33 30.3 34.35 30.36C34.53 30.69 34.84 31.12 35.28 31.64C35.73 32.16 36.21 32.69 36.73 33.22C37.27 33.75 37.79 34.24 38.32 34.69C38.84 35.13 39.27 35.43 39.61 35.61C39.66 35.63 39.72 35.66 39.79 35.69C39.87 35.72 39.95 35.73 40.04 35.73C40.21 35.73 40.34 35.67 40.45 35.56L41.21 34.81C41.46 34.56 41.7 34.37 41.93 34.25C42.16 34.11 42.39 34.04 42.64 34.04C42.83 34.04 43.03 34.08 43.25 34.17C43.47 34.26 43.7 34.39 43.95 34.56L47.26 36.91C47.52 37.09 47.7 37.3 47.81 37.55C47.91 37.8 47.97 38.05 47.97 38.33Z" stroke="white" stroke-width="1.5" stroke-miterlimit="10"/>
  <rect x="1" y="1" width="75" height="61" rx="30.5" stroke="white" stroke-width="2"/>
                                  </svg>
    </div>
    {loggedIn ? (  
      <>
        <NavLink to="/auth/login">
            <div className="text-white-A700 border border-white-A700 rounded-full p-3 text-sm">Logout</div>
            </NavLink>
  </>
      ) : (

        <NavLink to="/auth/login">
                    <div className="text-white-A700 border border-white-A700 rounded-full p-3 text-sm">Login</div>

        </NavLink>
      )}
    </div>
        </div>
                       </nav>

      <div className="flex gap-5 mt-24 w-full  md:flex-wrap md:mt-10 md:w-full px-20 md:px-4 items-center justify-between">
        <div className="flex-auto text-5xl font-semibold text-black md:text-4xl">
          Privacy Policy
        </div>
        <div className="flex-auto self-end  text-base font-medium text-zinc-500">
          Effective Date: January 2024
        </div>
      </div>
      <div className="mt-11 text-base md:text-sm text-black md:mt-10 md:w-full  px-20 md:px-4">
        LinkedOrigins (&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;)
        respects the privacy of our users (&quot;you&quot; or &quot;your&quot;)
        in accordance with the Canadian privacy laws, including the Personal
        Information Protection and Electronic Documents Act (PIPEDA). This
        Privacy Policy describes how we collect, use, and disclose your
        information when you use our platform, LinkedOrigins (the
        &quot;Platform&quot;).
        <br />
        <br />
        <span className="font-semibold">Information We Collect </span> <br />
        We collect several types of information when you use the Platform:
        <br />
        <br />
        Personal Information: When you register for an account, you may provide
        us with certain personal information, such as your name, email address,
        phone number (optional), language preferences, and demographic
        information.
        <br />
        <br />
        Usage Data: We collect information about how you use the Platform, such
        as the pages you visit, the features you use, and the searches you
        conduct.
        <br />
        <br />
        Location Data: We may collect information about your general location
        (e.g., city, province) to provide you with personalized content and
        resources. We will not collect precise geolocation data without your
        consent.
        <br />
        <br />
        Device Information: We collect information about the device you use to
        access the Platform, such as your device type, operating system, browser
        type, and unique device identifiers.
        <br />
        <br />
        Information from Social Media: If you choose to connect your social
        media account to your LinkedOrigins account, we may collect certain
        information from your social media provider, such as your name, profile
        picture, and friend list, subject to their privacy policies.
        <br />
        <br />
        <span className="font-semibold">How We Use Your Information</span>
        <br />
   
        We use the information we collect to:
        <br />

        Provide and operate the Platform;
        <br />
        Create and maintain your account;
        <br />
        Send you important information about the Platform, such as changes to
        our terms or policies;
        <br />
        Respond to your inquiries and requests;
        <br />
        Connect you with relevant resources and support services in Canada;
        <br />
        Personalize your experience on the Platform based on your location and
        preferences;
        <br />
        Improve the Platform; and
        <br />
        Comply with legal and regulatory requirements under Canadian law.
        <br />
        <br />
        <span className="font-semibold">Sharing Your Information</span>
        <br />
        We may share your information with third-party service providers located
        in Canada or elsewhere who help us operate the Platform, such as data
        storage providers (located in compliance with Canadian data residency
        requirements), email marketing providers, and customer support
        providers. These third-party service providers are obligated to use your
        information only to provide the services we have contracted for and are
        prohibited from using your information for their own marketing purposes.
        We will ensure these service providers comply with applicable Canadian
        privacy laws.
        <br />
        <br />
        We may also share your information with government or law enforcement
        officials if required by law or if we believe such disclosure is
        necessary to protect the rights, safety, or property of ourselves or
        others. We will do so in accordance with a court order or other lawful
        process.
        <br />
        <br />
        <span className="font-semibold">Data Retention</span>
        <br />
        We will retain your information for as long as your account is active or
        as needed to provide you with the services you request. We will also
        retain and use your information to the extent necessary to comply with
        our legal obligations under Canadian law, resolve disputes, and enforce
        our agreements.
        <br />
        <br />
        <span className="font-semibold">Your Choices</span>
        <br />
        You can access, update, or delete your personal information by logging
        into your account and visiting your profile settings. You can also
        choose to unsubscribe from our marketing communications by clicking the
        &quot;unsubscribe&quot; link in any email we send you.
        <br />
        <br />
        <span className="font-semibold">Access to Information</span>
        <br />
        Under PIPEDA, you have the right to access your personal information
        that we hold and to request corrections if you believe it is inaccurate.
        You can make such requests by contacting us at info@linkedorigins.ca
        <br />
        <br />
        <span className="font-semibold">Children's Privacy</span>
        <br />
        Our Platform is not directed to children under the age of 13. We do not
        knowingly collect personal information from children under 13. If you
        are a parent or guardian and you believe your child has provided us with
        personal information, please contact us. If we learn that we have
        collected personal information from a child under 13, we will delete
        that information from our servers.
        <br />
        <br />
        <span className="font-semibold">Your Consent </span>
        <br />
        By using the LinkedOrigins platform, you consent to the collection, use,
        and disclosure of your information as described in this 
        <br />
        Privacy Policy.
        <br />
        <br />
        <span className="font-semibold">Changes to this Privacy Policy</span>
        <br />
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on the Platform. You
        are advised to review this Privacy Policy periodically for any changes.
        <br />
        <br />
        <span className="font-semibold">Contact Us</span>
        <br />
        If you have any questions about this Privacy Policy, please contact us
        at info@linkedorigins.ca
        <br />
        <br />
        *Special Clause for Beta Testers*
        <br />
        In addition to the terms outlined in the above Privacy Policy, users
        participating in the LinkedOrigins beta testing program agree to the
        following:
        <br />
        <br />
        Non-Disclosure: You agree to keep all information about the
        LinkedOrigins platform confidential, including its features,
        functionalities, and any pre-release materials. You will not share this
        information with any third party without prior written consent from
        LinkedOrigins.
        <br />
        <br />
        Non-Circumvent: You agree not to attempt to bypass any security measures
        or access the platform in a way that is not intended. This includes, but
        is not limited to, reverse engineering, decompiling, or modifying the
        platform's code.
        <br />
        <br />
        Feedback: You agree to provide constructive feedback on your experience
        with the LinkedOrigins beta platform. This feedback will be used to
        improve the platform before its public launch.
        <br />
        <br />
        Bug Reporting: You agree to report any bugs or errors you encounter
        while using the platform. This will help us identify and fix issues
        before the platform is released to the public.
        <br />
        <br />
        Confidentiality of User Data: You understand that you may encounter
        personal information of other beta testers while using the platform. You
        agree to keep this information confidential and not share it with any
        third party.
        <br />
        <br />
        Termination of Beta Access - We reserve the right to terminate your
        access to the LinkedOrigins beta program at any time and for any reason,
        with or without notice.
        <br />
        <br />
        By participating in the LinkedOrigins beta testing program, you
        acknowledge that you have read and understood this Special Clause and
        agree to be bound by its terms.
      </div>
      <div className="mt-20 mb-20 flex flex-col items-center">

<Heading  className="text-6xl md:text-4xl sm:text-2xl  mt-20  !text-blue_gray-900 md:ml-0">
Ready to Start?
</Heading>
<Text
  size="5xl"
  as="p"
  className="text-2xl md:text-xl sm:text-sm  my-5 md:ml-0 text-center  leading-[41px] !text-gray-800_01  md:w-full md:p-5"
>
  <>
  Powered by AI, Our goal is to create the best settlement experience for you
  </>
</Text>
<NavLink to="/auth/register">
 <Button color="gray_600_04" shape="round" className="font-inter font-semibold">
    Get Started
 </Button>' 
</NavLink>
   
</div>
<div className=" mt-10 md:mt-0 flex w-full  flex-col justify-center items-center md:px-1 md:pt-5">
  <Img
    src="images/img_header_logo.svg"
    alt="image_six"
    className="relative z-[3] md:h-32  h-48 md:w-[70%] w-[35%] md:ml-0"
  />
  <div className=" -mt-20 flex justify-center items-center w-[80%] md:w-full  rounded-t-[285px] md:rounded-t-[50px] bg-red-400 px-14 pt-32 md:px-5 ">
  <div className="mb-10 flex w-[41%] flex-col items-start gap-[23px] md:w-full">
   
      <p className="ml-3.5 md:ml-0">
        <Heading className="text-4xl md:text-2xl sm:text-2x !text-white-A700">
        Join our Email List
        </Heading>
      </p>
      <div className="self-stretch">
        <p  className="text-sm md:text-base sm:text-sm !text-white-A700 w-full">
          <>
            Subscribe to our newsletter for immigration 
            insights and travel news!
          </>
        </p>
       
        <form onSubmit={handleSubmit}>
<div  className="md:ml-0  my-5 font-inter font-semibold p-2 border border-gray-400  rounded-md"> <input
type="email"
id="email"
value={email}

onChange={(e) => setEmail(e.target.value)}
required
/></div>
{loading?(<button
 
    disabled
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Loading
  </button>):(<button
 type="submit"
 
    className="self-start md:ml-0  my-5 font-inter font-semibold sm:px-5 bg-white-A700 rounded-3xl p-5"
  >
   Subscribe
  </button>)}

</form>
{message && <p>{message}</p>}
        <HomeScreenRowcloseOne className="mt-[60px] flex items-center justify-between gap-5" />
        <NavLink to="/privacy-policy">
<div className="text-white-A700 text-base md:text-sm self-center mt-4 w-full flex items-center justify-center invisible">Privacy Policy</div>
</NavLink>
      </div>
    </div>
  </div>
</div>
    </div>
  );
}


