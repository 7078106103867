import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Img, Heading, Button, Text } from "../../components";
import { ToastContainer } from "react-toastify";
import { NavLink, useNavigate } from "react-router-dom";
import AuthService, { UserData } from "services/authService";
import WelcomeSection from "./components/WelcomeSection";
import Resources from "./components/Resources";
import Typewriter from 'react-typewriter-effect';

export default function Dashboard() {
  const [userData, setUserData] = useState<UserData | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const userDataFromStorage = AuthService.getUserDataFromLocalStorage();
    console.log('userData:', userDataFromStorage); // Add this line for debugging
    setUserData(userDataFromStorage);
  }, []);
  const [showResources, setShowResources] = useState(false);

  useEffect(() => {
    // Set a timeout to show the Resources component after typing animation completes
    const timeout = setTimeout(() => setShowResources(true), 9000); // Adjust time to match the typing duration
    return () => clearTimeout(timeout);
  }, []);
  return (
    <>
      <Helmet>
        <title>LinkedOrigin</title>
        <meta name="description" content="Simplify your settlement journey with AI and streamlined resources" />

      </Helmet>
      
      <div className=" flex  relative flex-col items-center px-5 pb-20 w-full ">
       <div className="h-20 z-50 fixed px-4 flex w-full items-center justify-between gap-5 bg-white-A700 " >
            <div className="flex flex-row gap-2 items-center justify-between h-full  ">
            <div className=" h-10 w-10 flex-col gap-1 mt-auto hidden">
              <div className="h-[2px] w-[25px] bg-black-900_2d" />
              <div className="h-[2px] w-[25px] bg-black-900_2d" />
              <div className="h-[2px] w-[25px] bg-black-900_2d" />
            </div>
              <Img src="/images/img_header_logo.svg" alt="headerlogo_one" className="h-6 " />
             </div>  
            <div className="">
            {userData && userData.firstName && (
        <div className="flex justify-center items-center h-10 w-10  left-0 bottom-0 right-0 top-0  m-auto  bg-red-400_01 border-red-400_01 border-2 border-solid  rounded-full">
        <div  className="!text-white-A700 !font-hankengrotesk !font-normal">
        {userData.firstName ? userData.firstName.charAt(0) : ''}
        </div>
      </div>
      )} 
              </div>  
       
         </div>
         <section className="w-[90%] md:w-full mx-20 md:mx-10 mt-24 flex flex-col items-start justify-start gap-[100px]  text-left text-12xl font-kumbh-sans  sm:gap-[25px]">
         <div
      className={`w-full flex flex-row items-start justify-start pt-0 px-2.5 pb-[11px] box-border w- text-left text-56xl text-dimgray-300 font-kumbh-sans `}
    >
      <div className="flex-1 flex flex-col items-start justify-start gap-[88px] w-full sm:gap-[44px] ">
        <div className="self-stretch flex flex-row items-start justify-center py-0 pr-5 pl-[26px] md:pl-0 lg:box-border">
          <h1 className="m-0 relative text-24xl sm:text-2xl font-semibold font-inherit text-transparent !bg-clip-text [background:linear-gradient(90deg,_#3c4556,_#f44848)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent]  ">
            Welcome to Linked Origins
          </h1>
        </div>
        <div className="self-stretch h-80 md:h-auto flex flex-row items-start justify-between w-full gap-[20px] text-11xl lg:flex-wrap lg:justify-center">
          <div className="w-[60%] rounded-11xl bg-whitesmoke-200 flex flex-row items-start justify-start  md:w-full ">
            <div className="self-stretch relative rounded-11xl bg-whitesmoke-200 hidden w-full" />
            <img
              className=" flex-1 h-80 relative w-full overflow-hidden object-cover z-[1] lg:flex-1"
              loading="lazy"
              alt=""
              src="/images/mask-group1@2x.png"
            />
          </div>
          <div className="w-[40%] flex flex-col items-center justify-start gap-[14px] text-4xl sm:text-base md:w-full ">
           
        <div  className="self-stretch  h-80 md:h-auto flex items-center justify-center  leading-[36px] text-xl md:text-sm">
        <Typewriter
          text="Your all in one settlement assistant. Because Technology is moving quickly and faster, the days of bulky searches just to find information is slowly fading away. With advances in AI Tech where users can chat to get pretty much any information they need, We have streamlined your information and resource
          needs into two key categories"
          cursorColor="black"
          typeSpeed={50}
          delaySpeed={1000}
        />
      </div>
        
          </div>
        </div>
      </div>
          </div>
          {showResources && (
        <div className="animate-slide-up">
          <Resources />
        </div>
      )}
         
        </section>
      

       
      </div>
<ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    </>
  );
}




